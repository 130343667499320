import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "react-feather";

const TreffasCopyToClipboard = ({ text }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => enqueueSnackbar("The text has been copied successfully.")}
    >
      <IconButton size={"small"}>
        <Copy size={15} color="#000" />
      </IconButton>
    </CopyToClipboard>
  );
};

export default TreffasCopyToClipboard;
