import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Box, LinearProgress } from "@mui/material";

function LoadingScreen() {
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: "calc(80vh - 0px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box width={400} px={5}>
          <LinearProgress />
        </Box>
      </Box>
    </div>
  );
}

export default LoadingScreen;
