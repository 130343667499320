import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Constant from "src/utils/Constant";
import Helper from "src/utils/Helper";

const Header = ({ coachDetails }) => {
  const isMobile = Helper.IsTablet();
  const { t } = useTranslation();

  const settings = coachDetails?.settings
    ? JSON.parse(coachDetails?.settings)
    : null;

  const welcome =
    settings?.welcome_message ??
    ` ${t("individual.booking.label.welcome")} \n ${t(
      "individual.booking.label.welcome.continue"
    )}`;

  if (isMobile) {
    return <MobileHeader coachDetails={coachDetails} welcome={welcome} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "32px",
      }}
    >
      <Box>
        <Avatar
          alt={coachDetails?.name.charAt().toUpperCase()}
          sx={{
            height: "80px",
            width: "80px",
            ...(!coachDetails?.avatar && {
              backgroundColor: "#7B1FA2",
              color: "#FFF",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontFamily: "var(--base-font-neuehaasmedium) !important",
              lineHeight: "normal",
              letterSpacing: "-0.4px",
            }),
          }}
          src={Constant.imageUrl("coach", coachDetails?.avatar)}
        />
      </Box>

      <Box sx={{ maxWidth: "420px" }}>
        <Typography
          className="heading-4"
          sx={{
            letterSpacing: "-0.01em",
            textTransform: "capitalize",
          }}
        >
          {coachDetails?.name}
        </Typography>

        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "normal",
            color: "#6F6F6F",
            mt: "8px",
            whiteSpace: "pre-line",
          }}
        >
          {welcome}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;

const MobileHeader = ({ coachDetails, welcome }) => {
  return (
    <Box
      sx={{
        p: "60px 32px 32px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "37px",
        }}
      >
        <Box>
          <Avatar
            alt={coachDetails?.name.charAt().toUpperCase()}
            sx={{
              height: "52px",
              width: "52px",
              ...(!coachDetails?.avatar && {
                backgroundColor: "#7B1FA2",
                color: "#FFF",
                textAlign: "center",
                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }),
            }}
            src={Constant.imageUrl("coach", coachDetails?.avatar)}
          />
        </Box>
        <Box>
          <Typography
            className="heading-4"
            sx={{
              letterSpacing: "-0.01em",
              textTransform: "capitalize",
            }}
          >
            {coachDetails?.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "12px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: "#6F6F6F",
            lineHeight: "normal",
            mt: "8px",
          }}
        >
          {welcome}
        </Typography>
      </Box>
    </Box>
  );
};
