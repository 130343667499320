import { Box, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import IndividualBookingReschedule from "./stepper";
import IndividualBookingRescheduleMobile from "./stepper-mobile";
import { Users } from "src/context";

const RescheduleIndividualBooking = () => {
  const isMobile = useMediaQuery((themes) => themes.breakpoints.down("md"));
  const { mixpanel } = useContext(Users);

  React.useEffect(() => {
    mixpanel.track("Rescheduling Page", {
      location: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {isMobile ? (
        <IndividualBookingRescheduleMobile />
      ) : (
        <IndividualBookingReschedule />
      )}
    </Box>
  );
};

export default RescheduleIndividualBooking;
