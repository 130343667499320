import { useRef, useEffect } from "react";

export default function IsMounted() {
  const _mounted = useRef(true);

  useEffect(
    () => () => {
      _mounted.current = false;
    },
    []
  );

  return _mounted;
}
