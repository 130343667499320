import React from "react";
import { Box } from "@mui/material";
import PowerdBy from "../PowerdBy";
import CreateNextBooking from "./schedule";
import Page from "src/components/Page";
import { useTranslation } from "react-i18next";

const NextBooking = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("next.booking.page")}>
      <Box
        sx={{
          minHeight: "calc(100vh - 70px)", // 70px is the height og poweredby component
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CreateNextBooking />
      </Box>

      <Box>
        <PowerdBy colored={true} />
      </Box>
    </Page>
  );
};

export default NextBooking;
