import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PaypalFialedPayments from "./failed-payments/Paypal";
import StripeFailedPayments from "./failed-payments/Stripe";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PaymentCancel(props) {
  const navigate = useNavigate();
  const searchParams = useQuery();
  const methods = searchParams.get("methods");
  const session_slug = searchParams.get("session_slug");
  const { t } = useTranslation();

  if (methods === "stripe") {
    return <StripeFailedPayments session_slug={session_slug} />;
  }

  if (methods === "paypal") {
    return <PaypalFialedPayments session_slug={session_slug} />;
  }

  return (
    <div
      style={{
        height: "calc(100vh - 120px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Container maxWidth={"lg"}>
        <div>
          <Box mb={3}>
            <Typography variant={"h5"} type>
              {t("booking.payment.cancel")}
            </Typography>

            <Typography variant={"h6"}>
              {t("booking.payment.cancel.description")}
            </Typography>
          </Box>

          <Link onClick={() => navigate(`/`)} sx={{ cursor: "pointer" }}>
            {t("booking.button.home")}
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default PaymentCancel;
