import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import TreffasStyle from "src/utils/TreffasStyle";
import http from "src/utils/http";
import { useTranslation } from "react-i18next";

const BookingWarningDialog = ({
  formValues,
  setFormValues,
  setHasPending,
  setActiveStep,
}) => {
  const [isProcess, setIsProcess] = useState(false);
  const { t } = useTranslation();

  const createBookingWithPendingSession = () => {
    setIsProcess(true);
    http
      .post("/api/general/individual/create-booking", {
        ...formValues,
        booking_status: "ignore_pending",
      })
      .then((response) => {
        if (response.data.message === "success") {
          if (response.data.payment_url) {
            window.location.href = response.data.payment_url;
          } else {
            setActiveStep(4);
            setFormValues({});
            setHasPending(false);
          }
        }
      })
      .catch((err) => console.log("error indi create booking:", err))
      .finally(() => setIsProcess(false));
  };

  return (
    <Dialog
      open={true}
      onClose={() => console.log(`dialog unclosable.`)}
      BackdropProps={{
        sx: {
          backgroundColor: "#333970",
          opacity: "0.8 !important",
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          width: {
            xs: "100%",
            md: "450px",
          },
        },
      }}
    >
      <DialogTitle>{t("booking.warning_dialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
          }}
        >
          {t("booking.warning_dialog.title.description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={(themes) => ({
            [themes.breakpoints.up("md")]: {
              borderRadius: "12px",
              fontSize: "14px",
            },
            ...TreffasStyle.buttonHover,
          })}
          disabled={isProcess}
          startIcon={
            isProcess && <CircularProgress size={10} color={"inherit"} />
          }
          onClick={() => createBookingWithPendingSession()}
        >
          {t("booking.button.yes")}
        </Button>

        <Button
          variant="outlined"
          sx={(themes) => ({
            [themes.breakpoints.up("md")]: {
              borderRadius: "12px",
              fontSize: "14px",
            },
            ...TreffasStyle.buttonHover,
          })}
          onClick={() => setHasPending(false)}
        >
          {t("booking.button.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingWarningDialog;
