import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const TreffasTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#DFE2E6",
    },
    "&:hover fieldset": {
      borderColor: "#353A3F",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#353A3F",
    },
  },
});

export default TreffasTextField;
