import { Box } from "@mui/material";
import React from "react";
import Icon from "src/assets/images/thumbnails/general.png";

const General = ({ isCustomize = false, customsize = null }) => {
  return (
    <Box
      sx={{
        height: isCustomize ? customsize?.height : 110,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={Icon}
        alt={"thumbnail"}
        style={{
          width: "49px",
          height: "61px",
        }}
      />
    </Box>
  );
};

export default General;
