import { Box } from "@mui/material";
import React, { useState } from "react";
import OffersList from "./steps/OffersList";
import { useLocation } from "react-router-dom";
import Constant from "src/utils/Constant";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Booking = () => {
  const [selectedOffer, setSelectedOffer] = useState(null);
  const searchParams = useQuery();
  const display = searchParams.get("display");
  const header = searchParams.get("header");
  const footer = searchParams.get("footer");

  const hideHeader = header
    ? Constant.decrypto(decodeURIComponent(header)) === "YES"
      ? true
      : false
    : false;

  const hideFooter = footer
    ? Constant.decrypto(decodeURIComponent(footer)) === "YES"
      ? true
      : false
    : false;

  const isIframe = window.self !== window.top;

  return (
    <Box
      sx={{
        minHeight:
          display === "embeded" || isIframe ? "inherit" : "calc(90vh - 220px)", // 70px is the height og poweredby component
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1px solid transparent", // transparent hide scrollbar in embed
      }}
    >
      <OffersList
        selectedOffer={selectedOffer}
        setSelectedOffer={setSelectedOffer}
        hideHeader={hideHeader}
        hideFooter={hideFooter}
      />
    </Box>
  );
};

export default Booking;
