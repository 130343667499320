import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import IconClock from "../svg-icons/IconClock";
import { Minus } from "react-feather";
import TreffasStyle from "src/utils/TreffasStyle";
import UpgradePlan from "../UpgradePlan";
import { useTranslation } from "react-i18next";

const Large = ({ setViewType, days }) => {
  const [dialog, setDialog] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        width: "364px",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        backgroundColor: "#212529",
        position: "fixed",
        bottom: "18px",
        left: "18px",
        borderRadius: "12px",
        zIndex: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Box>
          <IconClock color={"#F8F9FA"} />
        </Box>

        <Box>
          <IconButton
            sx={{
              backgroundColor: "#4A5056",
              ...TreffasStyle.buttonHover,
              minHeight: "24px",
              minWidth: "24px",
            }}
            onClick={() => {
              localStorage.setItem("viewType", "small");
              setViewType("small");
            }}
          >
            <Minus color={"#FFF"} />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            color: "#F8F9FA",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          {t("free_trial_ends")} {days > 0 ? days : 0}{" "}
          {days === 1 ? t("free_trial_ends_day") : t("free_trial_ends_days")}
        </Typography>

        <Typography
          sx={{
            color: "#A0A6AB",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {t("free_trial_ends_description")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            borderRadius: "10px",
            minHeight: "44px",
            ...TreffasStyle.buttonHover,
          }}
          onClick={() => setDialog(true)}
        >
          {t("billing.button.upgrade")}
        </Button>

        {/* upgrade plan dialog */}
        {dialog && (
          <UpgradePlan
            title={"Advance Feature"}
            onClose={() => setDialog(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default Large;
