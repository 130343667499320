import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import TreffasTextField from "src/components/TreffasTextField";
import { useSnackbarContext } from "src/context/SnackbarContext";
import TreffasStyle from "src/utils/TreffasStyle";
import http from "src/utils/http";

const AddCouponDialog = ({ dialog, setDialog, packageId }) => {
  const { showSnackbar } = useSnackbarContext();

  const [isProcess, setIsProcess] = useState(false);

  const [values, setValues] = useState({
    coupon_code: "",
  });

  const [errors, setErrors] = useState({});

  const close = () =>
    setDialog((prev) => {
      return {
        ...prev,
        open: false,
      };
    });

  const handleOnAdd = () => {
    let error = [];
    if (values.coupon_code?.trim() === "" || values.coupon_code.length === 0) {
      error = "code-required";
      setErrors((prev) => {
        return {
          ...prev,
          coupon_code: {
            message: "Code is a required field.",
            required: true,
          },
        };
      });
    }

    if (error.length > 0) {
      console.log("error form has an error.", error);
      return;
    }

    setIsProcess(true);
    http
      .get("/api/general/individual/offer/check-coupon-code", {
        params: {
          coupon_code: values.coupon_code,
          package_id: packageId,
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          setDialog((prev) => {
            return {
              ...prev,
              data: data.coupon,
            };
          });

          showSnackbar("Coupon successfully added.", "success");
          close();
        }
        if (data.message === "code-invalid") {
          showSnackbar("Coupon code in incorrect.", "error");
          close();
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  return (
    <Dialog
      open={dialog.open}
      onClose={close}
      PaperProps={{
        sx: (themes) => ({
          [themes.breakpoints.up("md")]: {
            borderRadius: "12px",
            width: "450px",
            mniHeight: "252px",
          },
        }),
      }}
    >
      <DialogTitle> Add Coupon </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mb: "20px",
          }}
        >
          <Typography sx={TreffasStyle.formLabel}> Coupon Code </Typography>
          <TreffasTextField
            value={values.coupon_code}
            sx={TreffasStyle.formInputLarge("input")}
            onChange={(e) =>
              setValues((prev) => {
                delete errors?.coupon_code;

                return {
                  ...prev,
                  coupon_code: e.target.value,
                };
              })
            }
            fullWidth
            error={Boolean(errors?.coupon_code)}
            helperText={errors?.coupon_code?.message}
            placeholder="Enter coupon code here..."
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Button
            variant={"outlined"}
            color="primary"
            onClick={() => close()}
            sx={{
              borderRadius: "8px",
              minHeight: "44px",
              ...TreffasStyle.buttonHover,
            }}
          >
            Close
          </Button>

          <Button
            variant={"contained"}
            color="primary"
            sx={{
              borderRadius: "8px",
              minHeight: "44px",
              ...TreffasStyle.buttonHover,
            }}
            onClick={() => handleOnAdd()}
            disabled={isProcess}
            startIcon={
              isProcess && <CircularProgress size={18} color="inherit" />
            }
          >
            Add
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddCouponDialog;
