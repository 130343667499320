import React, { useEffect, useState } from "react";
import momentTimezone from "moment-timezone";
import Helper from "src/utils/Helper";

const RelativeTime = ({ date, timezone, type = "long" }) => {
  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    // Parse the provided date string
    const providedTime = Helper.convertTimeToClientTimezone(date, timezone);
    const currentTime = momentTimezone().tz(timezone);

    // Calculate the time difference in milliseconds
    const timeDifference = currentTime.diff(providedTime);

    // Convert milliseconds to minutes, hours, days, and weeks
    const minutes = Math.floor(timeDifference / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    // Determine the relative time
    let relativeTimeString = "";

    if (type === "long") {
      if (weeks > 0) {
        relativeTimeString = weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        relativeTimeString = days === 1 ? "a day ago" : `${days} days ago`;
      } else if (hours > 0) {
        relativeTimeString = hours === 1 ? "an hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        relativeTimeString =
          minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
      } else {
        relativeTimeString = "now";
      }
    } else {
      if (days > 0) {
        relativeTimeString = `${days}d ago`;
      } else if (hours > 0) {
        relativeTimeString = `${hours}h ago`;
      } else if (minutes > 0) {
        relativeTimeString = `${minutes}m ago`;
      } else {
        relativeTimeString = "now";
      }
    }

    setRelativeTime(relativeTimeString);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return <span>{relativeTime}</span>;
};

export default RelativeTime;
