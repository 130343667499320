import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IsMounted from "src/components/IsMounted";
import PageNotFound from "src/components/PageNotFound";
import http from "src/utils/http";
import DateAndTime from "./DateAndTime";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SessionDetails from "./SessionDetails";
import TreffasStyle from "src/utils/TreffasStyle";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import momentTimezone from "moment-timezone";
import Timezone from "src/utils/Timezone";
import Helper from "src/utils/Helper";
import BookingHeader from "../../individual-booking/schedule/booking-header";
import Booking from "src/utils/Booking";
import { useSnackbarContext } from "src/context/SnackbarContext";

const Schema = Yup.object({}).required();

const CreateNextBooking = () => {
  const { sessionSlug } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [sessionDetails, setSessionDetails] = useState(null);
  const mounted = IsMounted();

  const [selectedOffer, setSelectedOffer] = React.useState(null);
  const { showSnackbar } = useSnackbarContext();
  /** container of selected date match in block date **/
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSessionTime, setSessionSelectedTime] = useState({});
  const [blockTimeOnSelectedDate, setBlockTimeOnSelectedDate] = useState({});
  const [sessionList, setSessionList] = useState([]);
  const [allUpcommingAppt, setAllUpcommingAppt] = useState([]);
  const [selectedPaymentMethod] = useState(null);
  const [isProcess, setIsProcess] = useState(false);
  let zone = momentTimezone.tz.guess();
  const [selectedTimezone, setSelectedTimezone] = useState(zone);

  const eventColor = "#ff4f00";
  const { t } = useTranslation();

  const [availableTime, setAvailableTime] = useState(null);

  const [appForThisOffer] = useState([]);

  const {
    handleSubmit,
    // control,
    setError,
    // getValues,
    // setValue,
    // trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      organization_id: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      notes: "",
      topic: "",
      address: "",
      other_infos: "",
      price: 0,
    },
  });

  const getSessionDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/next/booking/session/details", {
        params: {
          session_slug: sessionSlug,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          if (data?.session_details?.session_status === "completed") {
            showSnackbar(t("next.booking.alert.session_completed"), "error");
          }

          setSessionDetails(data?.session_details);
          setSelectedOffer(data?.session_details?.offer);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionSlug, mounted]);

  const onSubmit = async (values) => {
    let bookingTimeUTCFormat = moment
      .tz(selectedSessionTime.start, "YYYY/MM/DD HH:mm", selectedTimezone)
      .tz(Helper.zeroTimezone);

    let data = {
      ...(Booking.isIntegrationActive(selectedOffer, "active compaign") &&
        Boolean(selectedOffer?.custom_options?.active_compaign_config) && {
          active_compaign_config: JSON.stringify(
            selectedOffer?.custom_options?.active_compaign_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "convert kit") &&
        Boolean(selectedOffer?.custom_options?.convertkit_config) && {
          convertkit_config: JSON.stringify(
            selectedOffer?.custom_options?.convertkit_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "aweber") &&
        Boolean(selectedOffer?.custom_options?.aweber_config) && {
          aweber_config: JSON.stringify(
            selectedOffer?.custom_options?.aweber_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "getresponse") &&
        Boolean(selectedOffer?.custom_options?.getresponse_config) && {
          getresponse_config: JSON.stringify(
            selectedOffer?.custom_options?.getresponse_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "mailchimp") &&
        Boolean(selectedOffer?.custom_options?.mailchimp_config) && {
          mailchimp_config: JSON.stringify(
            selectedOffer?.custom_options?.mailchimp_config
          ),
        }),

      ...(Booking.isIntegrationActive(selectedOffer, "mailerlite") &&
        Boolean(selectedOffer?.custom_options?.mailerlite_config) && {
          mailerlite_config: JSON.stringify(
            selectedOffer?.custom_options?.mailerlite_config
          ),
        }),
      paymentMethod: JSON.stringify(selectedPaymentMethod),
      base_url: window.location.origin,
      duration: selectedOffer?.duration,
      date: moment(new Date(selectedSessionTime.start), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time: moment(new Date(selectedSessionTime.start), "hh:mm A").format(
        "hh:mm A"
      ),
      date_end: moment(new Date(selectedSessionTime.end), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time_end: moment(new Date(selectedSessionTime.end), "hh:mm A").format(
        "hh:mm A"
      ),
      sessions: JSON.stringify(selectedOffer?.custom_options?.sessions),
      description: selectedOffer?.description,
      color: eventColor,
      offer_fields: JSON.stringify(selectedOffer?.fields),
      user_id: selectedOffer?.user_id,
      organization_id: selectedOffer?.organization_id,
      offer_id: selectedOffer?.id,
      timezone: Timezone.getLabel(selectedTimezone),
      timezone_utc: selectedTimezone,
      utc_bookingdate: bookingTimeUTCFormat.format("YYYY/MM/DD HH:mm"),
      coach_bookingdate: bookingTimeUTCFormat
        .clone()
        .tz(sessionDetails?.coach?.timezone_utc)
        .format("YYYY-MM-DD HH:mm"),
      coach_utc_timezone: sessionDetails?.coach?.timezone_utc,
      ...(selectedOffer?.survey && {
        survey_id: selectedOffer?.survey?.id,
        survey_slug: selectedOffer?.survey?.slug,
      }),

      offer_amount: selectedOffer?.price,
      offer_name: selectedOffer?.name,
      topic: selectedOffer?.name,
      number_of_sessions:
        selectedOffer?.custom_options?.number_of_sessions ?? 1,
      session_slug: sessionDetails?.session_slug,
      client_id: sessionDetails?.client?.id,
      // user_id: sessionDetails?.user_id,
      firstname: sessionDetails?.client?.firstname,
      lastname: sessionDetails?.client?.lastname,
      phone: sessionDetails?.client?.phone,
      email: sessionDetails?.client?.email,
      session_id: sessionDetails?.id,
      session_remaining:
        parseInt(sessionDetails?.sessions_totalcount) -
        parseInt(sessionDetails?.session_records_count),
      meeting_type:
        selectedOffer?.custom_options?.meeting_type ?? "virtual-meeting",
      currency: selectedOffer?.custom_options?.currency ?? "USD",
    };

    let error = [];

    if (selectedDate === null) {
      error = "time-required";
      setError("time", {
        message: t("next.booking.error.time_required"),
        required: true,
      });
    }

    if (Object.keys(selectedSessionTime).length === 0) {
      error = "date and time is required";
      setError("time", {
        required: true,
        message: t("next.booking.error.select_time"),
      });
    }

    if (error.length > 0) {
      console.log(`error:`, error);
      return;
    }

    setIsProcess(true);

    http
      .post("/api/general/individual/next/booking/create-newbooking", data)
      .then((response) => {
        if (response.data.message === "success") {
          navigate(
            `/next/booking/scheduled/successfuly?user_id=${selectedOffer?.user_id}&organization_id=${selectedOffer?.organization_id}`
          );
        }
      })
      .catch((err) => console.log("error indi create booking:", err))
      .finally(() => setIsProcess(false));
  };

  useEffect(() => {
    getSessionDetails();
  }, [getSessionDetails]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (sessionDetails === null) {
    return <PageNotFound />;
  }

  const coach = sessionDetails?.coach;
  const user_settings = coach?.settings ? JSON.parse(coach?.settings) : null;
  const langauge_settings = user_settings?.langauge_settings;
  const appointment_settings = user_settings?.appointment_settings;

  const userDateFormat = langauge_settings?.date_format ?? "MM/dd/yyyy";
  const userTimeFormat = langauge_settings?.time_format ?? "hh:mm A";

  const userAdvanceBookingNotice =
    appointment_settings?.advance_booking_notice ?? 3; // months

  const userMinimumBookingNotice =
    appointment_settings?.minimum_booking_notice ?? 0; // hours

  const calendarMaxDate = moment().add(userAdvanceBookingNotice, "M");

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#FAFAFA",
          minHeight: "calc(100vh - 70px)",
          pb: "30px",
        }}
      >
        <BookingHeader
          selectedOffer={selectedOffer}
          selectedSessionTime={selectedSessionTime}
          selectedDate={selectedDate}
          handleBack={() => console.log("back button disabled")}
          showBackButton={false}
          coachDetails={coach}
        />

        <Container
          sx={(themes) => ({
            padding: "0px !important",

            [themes.breakpoints.up("sm")]: {
              mt: "59px",
            },
            [themes.breakpoints.down("md")]: {
              mt: "10px",
              px: "10px !important",
            },
          })}
          maxWidth={"ntc"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box my={"24px"}>
              <SessionDetails sessionDetails={sessionDetails} />
            </Box>

            <Box>
              <DateAndTime
                errors={errors}
                setSelectedTimezone={setSelectedTimezone}
                selectedTimezone={selectedTimezone}
                sessionList={sessionList}
                selectedSessionTime={selectedSessionTime}
                setSessionSelectedTime={setSessionSelectedTime}
                availableTime={availableTime}
                setAvailableTime={setAvailableTime}
                selectedOffer={selectedOffer}
                setSessionList={setSessionList}
                setBlockTimeOnSelectedDate={setBlockTimeOnSelectedDate}
                appForThisOffer={appForThisOffer}
                blockTimeOnSelectedDate={blockTimeOnSelectedDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setError={setError}
                clearErrors={clearErrors}
                calendarMaxDate={calendarMaxDate}
                userDateFormat={userDateFormat}
                userTimeFormat={userTimeFormat}
                userMinimumBookingNotice={userMinimumBookingNotice}
                coachDetails={coach}
                allUpcommingAppt={allUpcommingAppt}
                setAllUpcommingAppt={setAllUpcommingAppt}
              />
            </Box>

            <Box my={"24px"}>
              <Divider />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                my: "24px",
              }}
            >
              <Box>
                <Button
                  variant={"outlined"}
                  sx={(themes) => ({
                    mr: 2,
                    fontSize: "14px",
                    borderRadius: "12px",
                    height: "48px",
                    minWidth: "121px",
                    ...TreffasStyle.buttonHover,
                  })}
                >
                  {t("next.booking.button.back")}
                </Button>
              </Box>

              <Box>
                <Button
                  variant={"contained"}
                  sx={(themes) => ({
                    borderRadius: "12px",
                    minWidth: "121px",
                    fontSize: "14px",
                    height: "48px",
                    ...TreffasStyle.buttonHover,
                  })}
                  type="submit"
                  disabled={
                    isProcess ||
                    sessionDetails?.session_status === "completed" ||
                    selectedDate === null
                  }
                  startIcon={
                    isProcess && <CircularProgress size={15} color="inherit" />
                  }
                >
                  {t(
                    "individual.booking.schedule.choose_schedule.button.schedule_event"
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
};

export default CreateNextBooking;
