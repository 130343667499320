import DefaultImg from "src/assets/images/survies/themes/default.png";
import MidnightGreenImg from "src/assets/images/survies/themes/midnight_green.png";
import MustardImg from "src/assets/images/survies/themes/mustard.png";
import WoodImg from "src/assets/images/survies/themes/wood.png";
import FolyeoOne from "src/assets/images/survies/themes/folyeoone.png";
import FolyeoTwo from "src/assets/images/survies/themes/folyeotwo.png";
import FolyeoThree from "src/assets/images/survies/themes/folyeothree.png";
import FolyeoFour from "src/assets/images/survies/themes/folyeofour.png";

const Survies = {
  themes: [
    {
      name: "Default",
      translated: "survies.label.settings.themes.default",
      value: "default",
      hidden: true,
      thumbnail: DefaultImg,
      configuration: {
        color: "#0173FE",
        border: "#F4F5F6",
        answer_bg_color: "transparent",
        answer_border: "#EAECEF",
        radio_border: "#A0A6AB",
        nps_border: "#EAECEF",

        background: "#FFF",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#EAECEF",
        },
      },
    },

    {
      name: "Mustard",
      translated: "survies.label.settings.themes.mustard",
      value: "mustard",
      hidden: true,
      thumbnail: MustardImg,
      configuration: {
        color: "#E4D449",
        border: "#F7F3D4",
        answer_bg_color: "transparent",
        answer_border: "#EDE3B6",
        radio_border: "#DBCD51",
        nps_border: "#F1EBB1",

        background: "#FFFDEB",
        text: {
          selected: "#212529",
        },
        hover: {
          color: "#F1EBB1",
        },
      },
    },

    {
      name: "MidnightGreen",
      translated: "survies.label.settings.themes.midnightgreen",
      hidden: true,
      value: "midnight_green",
      thumbnail: MidnightGreenImg,
      configuration: {
        color: "#226169",
        border: "#C8F3F9",

        answer_bg_color: "#DBFAFD",
        answer_border: "#9BE8F3",
        radio_border: "#087C87",
        nps_border: "#9BE8F3",

        background: "#E7FCFE",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#A5EAF3",
        },
        nps_background: "#DBFAFD",
      },
    },

    {
      name: "Wood",
      translated: "survies.label.settings.themes.wood",
      hidden: true,
      value: "wood",
      thumbnail: WoodImg,
      configuration: {
        color: "#906A3E",
        border: "#F2EFE3",
        answer_bg_color: "transparent",
        answer_border: "#EEE9D8",
        radio_border: "#CEBA8D",
        nps_border: "#EEE9D8",
        background: "#FCFBF7",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#EDE7D4",
        },
      },
    },

    //  folyeo colors
    {
      name: "Folyeo One",
      translated: "Theme 1",
      hidden: false,
      value: "foyleo-one22",
      thumbnail: FolyeoOne,
      configuration: {
        color: "#D97054",
        border: "#D97054",
        answer_bg_color: "transparent",
        answer_border: "#D97054",
        radio_border: "#D97054",
        nps_border: "#D97054",
        background: "#f9c1b2",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#b8887b",
        },
      },
    },

    {
      name: "Folyeo Two",
      translated: "Theme 2",
      hidden: false,
      value: "foyleo-two",
      thumbnail: FolyeoTwo,
      configuration: {
        color: "#292626",
        border: "#292626",
        answer_bg_color: "transparent",
        answer_border: "#292626",
        radio_border: "#292626",
        nps_border: "#292626",
        background: "#7a7373",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#554f4f",
        },
      },
    },

    {
      name: "Folyeo Three",
      translated: "Theme 3",
      hidden: false,
      value: "foyleo-three",
      thumbnail: FolyeoThree,
      configuration: {
        color: "#87BCBF",
        border: "#87BCBF",
        answer_bg_color: "transparent",
        answer_border: "#87BCBF",
        radio_border: "#87BCBF",
        nps_border: "#87BCBF",
        background: "#cafcff",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#a5d0d3",
        },
      },
    },

    {
      name: "Folyeo Four",
      translated: "Theme 4",
      hidden: false,
      value: "foyleo-one",
      thumbnail: FolyeoFour,
      configuration: {
        color: "#244D66",
        border: "#244D66",
        answer_bg_color: "transparent",
        answer_border: "#244D66",
        radio_border: "#244D66",
        nps_border: "#244D66",
        background: "#3f84ae",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#346d90",
        },
      },
    },
  ],

  moreColors: [
    "#B9ADA0",
    "#6E8CA0",
    "#FFEEDD",
    "#8EECF5",
    "#FFCAD4",
    "#99C1DE",
    "#BCD4E6",
    "#D6E2E9",
    "#FCD5CE",
    "#FAE1DD",
    "#F8EDEB",
    "#E8E8E4",
    "#D8E2DC",
    "#ECE4DB",
    "#FFE5D9",
    "#FFD7BA",
    "#D9D9D9",
    "#EAE4E9",
    "#FFF1E6",
    "#FFD991",
    "#FAD2E1",
    "#E2ECE9",
    "#BEE1E6",
    "#F0EFEB",
    "#DFE7FD",
    "#CDDAFD",
    "#F8EDEB",
    "#EDF2FB",
    "#F9E5D8",
    "#E2EAFC",
    "#F9DCC4",
    "#D7E3FC",
    "#EEEAE6",
    "#FFD7BA",
    "#ECE4DB",
    "#B7FFD8",
    "#B8E0D2",
    "#DEC0F1",
    "#FFE69E",
    "#FCD5CE",
    "#C2E2EA",
    "#E2ECF2",
    "#E9FFFB",
    "#FFFEDE",
    "#FCFBF7",
    "#E7FCFF",
    "#FCFBF7",
  ],

  getTheme: (theme) => {
    return Survies.themes.find((x) => x.value === theme);
  },
};

export default Survies;
