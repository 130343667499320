import React from "react";
import { X } from "react-feather";

import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import IconClock from "../svg-icons/IconClock";
import TreffasStyle from "src/utils/TreffasStyle";
import UpgradePlan from "../UpgradePlan";
import { useTranslation } from "react-i18next";

const MobileTrialEndedDialog = ({ open, close, days }) => {
  const [dialog, setDialog] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.82)",
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          p: "24px",
        },
      }}
    >
      <DialogContent sx={{ p: 0, m: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Box>
              <IconClock color={"#000"} />
            </Box>

            <Box>
              <IconButton
                sx={{
                  ...TreffasStyle.buttonHover,
                  minHeight: "24px",
                  minWidth: "24px",
                  backgroundColor: "#EAECEF",
                }}
                onClick={() => close()}
              >
                <X />
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {t("free_trial_ends")} {days > 0 ? days : 0}{" "}
              {days === 1
                ? t("free_trial_ends_day")
                : t("free_trial_ends_days")}
            </Typography>

            <Typography
              sx={{
                color: "#A0A6AB",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("free_trial_ends_description")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                borderRadius: "10px",
                minHeight: "44px",
                ...TreffasStyle.buttonHover,
              }}
              onClick={() => setDialog(true)}
            >
              {t("billing.button.upgrade")}
            </Button>

            {/* upgrade plan dialog */}
            {dialog && (
              <UpgradePlan
                title={"Advance Feature"}
                onClose={() => setDialog(false)}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MobileTrialEndedDialog;
