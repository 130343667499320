import React from "react";

const IconClockSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#F8F9FA"
        fillRule="evenodd"
        d="M10 4.792a6.042 6.042 0 100 12.083 6.042 6.042 0 000-12.083zm-7.292 6.041a7.292 7.292 0 1114.584 0 7.292 7.292 0 01-14.584 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F8F9FA"
        fillRule="evenodd"
        d="M10 6.042c.345 0 .625.28.625.625v3.82l2.206 1.379a.625.625 0 01-.662 1.06l-2.5-1.563a.625.625 0 01-.294-.53V6.667c0-.345.28-.625.625-.625zM5.442 2.891a.625.625 0 010 .884L3.359 5.86a.625.625 0 11-.884-.884L4.558 2.89a.625.625 0 01.884 0zM14.558 2.891a.625.625 0 000 .884l2.084 2.084a.625.625 0 00.883-.884L15.442 2.89a.625.625 0 00-.884 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconClockSmall;
