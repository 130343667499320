import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
import SplashScreen from "src/components/SplashScreen";
import { PlanSubscription, Users } from "src/context";
import http from "src/utils/http";
import Pendo from "src/utils/Pendo";

const Auth = ({ children }) => {
  const { updateUser, setUserIntegrationList } = useContext(Users);
  const { setPlanSubscription } = useContext(PlanSubscription);
  const organization_slug = localStorage.getItem("organization");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const checkForPendingOnboardingProcess = (data) => {
    let currentPage = window.location.pathname;
    let hasOnboarding =
      currentPage.includes("/app/onboarding/calendar/connect-google") ||
      currentPage.includes("/app/onboarding/calendar/connect-msoutlook");

    if (hasOnboarding) {
      console.log(`page will redirect to onboarding to continue the process.`);
    } else {
      if (
        !data.user.onboarding?.data_center_location ||
        data.user.onboarding?.data_center_location === null
      ) {
        navigate(`/app/onboarding?page=data-center`);
        return;
      }

      if (
        !data.user.onboarding?.type_of_coaching ||
        data.user.onboarding?.type_of_coaching === null
      ) {
        navigate(`/app/onboarding?page=welcome`);
        return;
      }

      if (
        !data.user.onboarding?.is_onboarding_set ||
        data.user.onboarding?.is_onboarding_set === null
      ) {
        navigate(`/app/onboarding?page=calendar`);
        return;
      }
    }
  };

  const silentLogin = () => {
    http
      .post("/api/auth/me", {
        organization_slug: organization_slug,
      })
      .then((response) => {
        let data = response.data;

        if (data.message === "success") {
          updateUser(data.user);
          setPlanSubscription(data.plan);
          setUserIntegrationList(data.all_integrations);
          // initialize pendo //
          Pendo.initialize(data.user);

          checkForPendingOnboardingProcess(data);
        }

        if (data.message === "organization-not-found") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("organization");
          updateUser(null);
        }
      })
      .catch(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("organization");
        updateUser(null);
      })
      .finally(() => setLoading(true));
  };

  useEffect(() => {
    silentLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    return <SplashScreen />;
  }

  return children;
};

export default Auth;
