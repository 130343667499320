import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const SessionDetails = ({ sessionDetails }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Typography
          className="heading-6"
          sx={{
            alignItems: "center",
            color: "#060A2D",
          }}
        >
          {t("next.booking.label.client_details")}
        </Typography>
      </Box>

      <Box sx={{ px: "60px", mt: "10px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
            }}
          >
            {t("next.booking.label.name")} : {sessionDetails?.client?.firstname}{" "}
            {sessionDetails?.client?.lastname}
          </Typography>
        </Box>

        <Box mb={"10px"}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
            }}
          >
            {t("next.booking.label.email")} : {sessionDetails?.client?.email}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography
          className="heading-6"
          sx={{
            alignItems: "center",
            color: "#060A2D",
          }}
        >
          {t("next.booking.label.session_details")}
        </Typography>
      </Box>
      <Box sx={{ px: "60px", mt: "10px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
            }}
          >
            {t("next.booking.label.total_session")} :{" "}
            {sessionDetails?.sessions_totalcount}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
            }}
          >
            {t("next.booking.label.total_session_used")} :{" "}
            {sessionDetails?.session_records_count}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
            }}
          >
            {t("next.booking.label.total_session_remaining")} :{" "}
            {parseInt(sessionDetails?.sessions_totalcount) -
              parseInt(sessionDetails?.session_records_count)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionDetails;
