import React from "react";
import { Box, CircularProgress } from "@mui/material";
// import Logo from "src/assets/images/sample.png";
// import TL from "./../assets/images/treffas_logo.png";

function SplashScreen() {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        left: 0,
        padding: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
        height: "calc(100vh - 0px)",
      }}
    >
      {/*<Box display="flex" justifyContent="center" mb={2}>
         <Typography variant={"h4"}>
          <span className="f-text-logo">TREFFAS </span>
        </Typography>
        <img
          src={TL}
          alt={"treffas logo"}
          style={{ height: "30px", width: "178px" }}
        />
      </Box> */}

      <Box sx={{ p: "10px" }}>
        <CircularProgress size={20} />
      </Box>
    </Box>
  );
}

export default SplashScreen;
