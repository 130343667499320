import { Box, Typography, Divider } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GoogleRegistration from "../google";
import LinkedInRegistration from "../linkedin";
import BasicInfo from "./BasicInfo";
import http from "src/utils/http";
import Verification from "./Verification";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { useNavigate } from "react-router-dom";

const TreffasRegistration = () => {
  const [isProcess, setIsProcess] = useState();
  const submitBtnRef = useRef();
  const { showSnackbar } = useSnackbarContext();
  const [terms, setTerms] = useState(false);
  const [category, setCategory] = useState("basic-info"); // basic-info, verification
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mixpanel } = useContext(Users);

  const Schema = Yup.object({
    firstname: Yup.string().required(
      t("registration.form.validation.required.firstname")
    ),
    lastname: Yup.string().required(
      t("registration.form.validation.required.lastname")
    ),
    password: Yup.string()
      .required(t("registration.form.validation.required.password"))
      .min(6, t("registration.form.validation.invalid.password-short")),
    email: Yup.string()
      .email(t("registration.form.validation.invalid.email"))
      .required(t("registration.form.validation.required.email")),
  }).required();

  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      firstname: "",
      lastname: "",
      password: "",
      currency: "USD",
      email: "",
    },
  });

  const onSubmit = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/registration", {
        ...values,
      })
      .then(({ data }) => {
        if (data.message === "email-exist") {
          showSnackbar(
            t("registration.form.validation.invalid.email-not-available"),
            "error"
          );
        }

        if (data.message === "success") {
          setCategory("verification");
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Registration Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {category === "basic-info" && (
        <Box
          sx={(themes) => ({
            display: "flex",
            justifyContent: "center",
            [themes.breakpoints.up("md")]: {
              alignItems: "center",
            },
          })}
        >
          <Box
            sx={(themes) => ({
              width: "720px",
              [themes.breakpoints.down("md")]: {
                px: "24px",
                width: "100%",
              },
            })}
          >
            <Box>
              <Typography
                className="heading-2"
                sx={{
                  textAlign: "center",
                }}
              >
                {t("registration.create-account")}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#838383",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.description.first")}
              </Typography>
            </Box>

            {/* button */}
            <Box
              sx={{
                mt: "36px",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
                gap: "20px",
              }}
            >
              <GoogleRegistration />

              {/* folyeo no linkedin */}
              <LinkedInRegistration />
            </Box>

            {/* divider */}
            <Box
              sx={(themes) => ({
                [themes.breakpoints.up("md")]: {
                  mt: "34px",
                  mb: "20px",
                },
                [themes.breakpoints.down("md")]: {
                  mt: "40px",
                  mb: "40px",
                },
              })}
            >
              <Box
                sx={{
                  maxWidth: "440px",
                  margin: "auto",
                }}
              >
                <Divider>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#838383",
                      fontFamily: "var(--font-work-sans)",
                    }}
                  >
                    {t("registration.signup-with-email")}
                  </Typography>
                </Divider>
              </Box>
            </Box>

            <Box
              sx={(themes) => ({
                [themes.breakpoints.up("md")]: {
                  width: "440px",
                  maxWidth: "440px",
                },
                [themes.breakpoints.down("md")]: {
                  width: "100%",
                },
                mx: "auto",
              })}
            >
              <BasicInfo
                errors={errors}
                control={control}
                isProcess={isProcess}
                setCategory={setCategory}
                trigger={trigger}
                terms={terms}
                setTerms={setTerms}
                submitBtnRef={submitBtnRef}
              />
            </Box>
          </Box>
        </Box>
      )}

      {category === "verification" && (
        <>
          <Verification
            email={getValues("email")}
            name={`${getValues("firstname")} ${getValues("lastname")}`}
          />
        </>
      )}

      <button ref={submitBtnRef} type="submit" hidden>
        Submit
      </button>
    </form>
  );
};

export default TreffasRegistration;
