import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Clients } from "src/context";

function ClientPortalGuard({ children }) {
  const { client } = React.useContext(Clients);

  if (!client) {
    return <Navigate to="/login/client-portal" />;
  }

  return children;
}

ClientPortalGuard.propTypes = {
  children: PropTypes.any,
};

export default ClientPortalGuard;
