import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Users } from "src/context";

function AuthGuard({ children }) {
  const { user } = React.useContext(Users);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
