import {
  // Button,
  // Dialog,
  // DialogContent,
  // DialogActions,
  // CircularProgress,
  Box,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Users } from "src/context";
import http from "src/utils/http";
import Loading from "./Loading";

const Logout = () => {
  const navigate = useNavigate();
  const { updateUser } = useContext(Users);

  const handleLogout = async () => {
    let response = await http.post("/api/auth/logout");
    if (response.status === 200) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("organization");
      updateUser(null);
      navigate("/login");

      /* global google */
      google.accounts.id.disableAutoSelect();
    }
  };

  React.useEffect(() => {
    handleLogout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "calc(100vh - 120px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </Box>
  );
};

export default Logout;
