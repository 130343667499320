import React, { useEffect, useCallback, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import IsMounted from "src/components/IsMounted";
import http from "src/utils/http";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import TreffasStyle from "src/utils/TreffasStyle";
import PageNotFound from "src/components/PageNotFound";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import DescTooltip from "../schedule/booking-header/DescTooltip";
import Success from "./Success";
import { Users } from "src/context";
import Helper from "src/utils/Helper";
import { useSnackbarContext } from "src/context/SnackbarContext";

function BookingCancel() {
  const mounted = IsMounted();
  const { organization_id, user_id, offer_id, appointment_id, client_id } =
    useParams();
  const [details, setDetails] = useState([]);
  const [appDetails, setAppDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isProcess, setIsProcess] = useState(false);

  const { showSnackbar } = useSnackbarContext();

  const [remarks, setRemarks] = useState("");
  const [isCancelable, setIsCancelable] = useState(true);
  const { t } = useTranslation();
  const [coachDetails, setCoachDetails] = useState(null);
  const [step, setStep] = useState("cancel-form"); // cancel-form,, success
  const { mixpanel } = useContext(Users);

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id,
          organization_id: organization_id,
        },
      })
      .then(({ data }) => {
        setCoachDetails(data);
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [user_id, organization_id]);

  /*** get offer details in db **/
  const getOffer = useCallback(async () => {
    let { data } = await http.get(`/api/general/individual/offer/details`, {
      params: {
        offer_id: offer_id,
        organization_id: organization_id,
        user_id: user_id,
      },
    });
    if (mounted.current) {
      setDetails(data.data);
    }
  }, [organization_id, user_id, offer_id, mounted]);

  /*** get appointment details by client id and appointment id **/
  const getAppointmentDetails = useCallback(() => {
    http
      .get(`/api/general/individual/appointment/details`, {
        params: {
          appointment_id,
          client_id,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setAppDetails(data.data);
          if (data.data?.status === "canceled") {
            showSnackbar(t("booking.cancel.alert.current_cancel"), "error");
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_id, mounted, client_id]);

  const handleCheckCancelable = () => {
    const user_settings = coachDetails?.settings
      ? JSON.parse(coachDetails?.settings)
      : null;

    const appointment_settings = user_settings?.appointment_settings;
    const userCancellationNotice = appointment_settings?.cancellation_notice
      ? appointment_settings?.cancellation_notice * 60
      : 120; // 24hours

    const userEnableCancel = appointment_settings?.allow_cancellation ?? "yes";

    let error = [];
    let minutesToCancel = userCancellationNotice; // get this to database base on setting set by coach - minutes

    const booking = JSON.parse(appDetails?.booking_info);

    let booking_date = appDetails.date;

    let now = moment().format("YYYY-MM-DD HH:mm");
    const datetime = moment.tz(now, "YYYY-MM-DD HH:mm", booking.timezone_utc);
    const _current_date = datetime
      .clone()
      .tz(Helper.zeroTimezone)
      .format("YYYY-MM-DD HH:mm");
    const current_date = new Date(_current_date);

    // check remaining minutes if allowed to cancel
    let duration = moment.duration(
      moment(booking_date).diff(moment(current_date))
    );

    let minutes_remaining = Math.round(duration.asMinutes());

    if (userEnableCancel === "no") {
      error = "coach-disabled-cancel";
      showSnackbar(
        t("booking.cancel.alert.cancellation_notice_disabled"),
        "error"
      );
      return;
    }

    if (remarks === "") {
      error = "remarks-is-required";
      showSnackbar(t("booking.error.required.reason"), "error");
      return;
    }

    if (
      parseInt(current_date.getTime()) >
      parseInt(new Date(booking_date).getTime())
    ) {
      showSnackbar(
        t("booking.error.invalid.unable_to_cancel_pastdates"),
        "error"
      );
      error = "date-passed";
      return;
    }

    if (parseInt(minutesToCancel) > parseInt(minutes_remaining)) {
      showSnackbar(t("booking.error.invalid.cancel_time_limit"), "error");
      error = "cancel-time-limit";
      return;
    }

    if (error.length > 0) {
      console.log("cancellation has error:", error);
      setIsCancelable(false);
    } else {
      handleBookingCancel();
    }
  };

  /** process cancellation of booking **/
  const handleBookingCancel = () => {
    let params = new URLSearchParams();
    params.append(`appointment_id`, appointment_id);
    params.append(`organization_id`, organization_id);
    params.append(`offer_id`, offer_id);
    params.append(`client_id`, client_id);
    params.append("user_id", details?.user_id);
    params.append(`process_by`, "client");
    params.append(
      `remarks`,
      JSON.stringify({
        cancelled_by: "client",
        remarks: remarks,
        datetime: moment(new Date()).format("YYYY-MM-DD hh:mm"),
      })
    );
    params.append(`user_id`, details?.user_id);
    params.append(`event_id`, appdetails?.google_calendar_config?.event_id);

    setIsProcess(true);
    http
      .post(`/api/general/individual/cancel-booking`, params)
      .then((response) => {
        if (response.status === 201) {
          setStep("success");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  useEffect(() => {
    mixpanel.track("Cancel Booking Page", {
      location: window.location.href,
    });

    getOffer();
    getAppointmentDetails();
    getCoachDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOffer, getAppointmentDetails, getCoachDetails]);

  let appdetails = appDetails?.booking_info
    ? JSON.parse(appDetails?.booking_info)
    : null;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (Object.keys(appDetails).length === 0) {
    return <PageNotFound />;
  }

  // const user_settings = coachDetails?.settings
  //   ? JSON.parse(coachDetails?.settings)
  //   : null;

  // const appointment_settings = user_settings?.appointment_settings;
  const booking = JSON.parse(appDetails?.booking_info);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 30px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {step === "cancel-form" && (
        <Box
          sx={{
            width: { xs: "100%", sm: "508px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "40px",
                lineHeight: "42px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                color: "#19181B",
              }}
            >
              {t("individual.booking.cancel.label.cance_appointment")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "21px",
                textAlign: "center",
                color: "#6E757C",
              }}
            >
              {t(
                "individual.booking.cancel.label.cance_appointment.description"
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              width: { xs: "100%" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "12px 24px",
              gap: "20px",
              border: "1px solid #DFDFE1",
              borderRadius: "8px",
              backgroundColor: "#F7F7F7",
              mt: "32px",
            }}
          >
            <Box sx={{ width: "72px" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#6F6D77",
                }}
              >
                {t("individual.booking.cancel.label.package")}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#222128",
                  textTransform: "capitalize",
                }}
              >
                {details?.name}
              </Typography>

              <DescTooltip
                title={details?.name}
                description={details?.description}
              />
            </Box>
          </Box>

          <Box
            sx={{
              border: "1px solid #DFDFE1",
              borderRadius: "8px",
              backgroundColor: "#F7F7F7",
              mt: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
                gap: "16px",
                borderBottom: 1,
                borderColor: "#DFDFE1",
              }}
            >
              <Box sx={{ width: "72px" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#6F6D77",
                  }}
                >
                  {t("individual.booking.cancel.label.date")}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#222128",
                  }}
                >
                  {moment(booking?.date).format("dddd, DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
                gap: "16px",
              }}
            >
              <Box sx={{ width: "72px" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#6F6D77",
                  }}
                >
                  {t("individual.booking.cancel.label.time")}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "20px",
                    color: "#222128",
                  }}
                >
                  {moment(`${booking?.date} ${booking?.time}`).format(
                    "hh:mm A"
                  )}{" "}
                  -{" "}
                  {moment(`${booking?.date} ${booking?.time}`)
                    .add(details?.duration, "minutes")
                    .format("hh:mm A")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={"40px"} mb={"16px"}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "31px",
                color:
                  appDetails?.status === "canceled" ? "#A3ABBF" : "#4A5266",
              }}
            >
              {t("booking.cancel.label.reason")}
            </Typography>

            <TextField
              placeholder={t("booking.cancel.placeholder.reason")}
              name="cancel_reason"
              fullWidth
              multiline
              minRows={4}
              sx={{ ...TreffasStyle.formInputLarge("textarea") }}
              value={remarks}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
              disabled={appDetails?.status === "canceled"}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color={"primary"}
                sx={{
                  minWidth: "213px",
                  height: "44px",
                  fontSize: "15px",
                  borderRadius: "12px",
                  ...TreffasStyle.buttonHover,
                }}
                disabled={
                  isProcess ||
                  !isCancelable ||
                  appDetails?.status === "canceled"
                }
                startIcon={
                  isProcess && <CircularProgress color="inherit" size={15} />
                }
                onClick={() => handleCheckCancelable()}
              >
                {t("booking.button.cancel")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {step === "success" && (
        <Success
          selectedOffer={details}
          coachDetails={coachDetails}
          appointmentDetails={appDetails}
        />
      )}
    </Box>
  );
}

export default BookingCancel;
