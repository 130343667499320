import React from "react";

const IconRoundedBell = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="var(--base-color-sand)"></circle>
      <path
        fill="var(--color-black)"
        fillRule="evenodd"
        d="M15.104 16.9c0-1.987 1.235-3.732 3.037-4.53a1 1 0 00.625.03 6.028 6.028 0 012.348-.163h.004c.27.032.538.088.806.162a.999.999 0 00.62-.027c1.802.8 3.042 2.548 3.042 4.528v2.765c0 .386.083.828.196 1.224.114.398.276.811.47 1.134l.004.006.004.006 1.147 1.827c.386.616.096 1.448-.669 1.69l-.002.001a21.002 21.002 0 01-12.79 0c-.465-.15-.72-.424-.823-.692-.102-.264-.093-.613.149-.998l1.148-1.828.002-.003c.202-.326.368-.742.484-1.14.115-.399.198-.84.198-1.227V16.9zm11.982 0c0-2.653-1.661-4.92-4.006-5.93-.467-1.043-1.532-1.74-2.735-1.74-1.202 0-2.266.695-2.734 1.737-2.35 1.007-4.007 3.271-4.007 5.933v2.765c0 .198-.047.492-.139.81-.091.315-.21.592-.316.764l-1.147 1.826c-.467.743-.568 1.586-.278 2.336.288.746.926 1.31 1.76 1.58h.002c.998.32 2.014.568 3.039.744a3.83 3.83 0 00.978 1.534 4.097 4.097 0 002.823 1.123c1.769 0 3.279-1.117 3.8-2.652a22.422 22.422 0 003.066-.748c1.651-.524 2.42-2.427 1.487-3.916v-.001l-1.145-1.821a3.452 3.452 0 01-.31-.768 3.21 3.21 0 01-.138-.811V16.9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconRoundedBell;
