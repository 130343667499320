import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Constant from "src/utils/Constant";
import jwtDecode from "jwt-decode";
import http from "src/utils/http";
import { useNavigate } from "react-router-dom";
import Pendo from "src/utils/Pendo";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const GoogleLogin = () => {
  const [isProcess, setIsProcess] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation();

  const handleLogin = (response) => {
    let googleData = jwtDecode(response.credential);
    setIsProcess(true);
    http
      .post("/api/oauth/social/login", {
        email: googleData.email,
        organization: googleData.name?.trim()?.toLowerCase(),
      })
      .then(({ data }) => {
        if (data.message === "email-not-found") {
          showSnackbar(t("login.form.validation.email_not_found"), "error");
        }

        if (data.message === "organization-not-found") {
          showSnackbar(
            t("login.form.validation.organization_not_found"),
            "error"
          ); // "Organization is not found.");
        }

        if (data.message === "success") {
          // initialize pendo //
          Pendo.initialize(data.data);

          if (data.two_factor_enable) {
            navigate(
              `/two-factor/verification?token=${data.access_token}&id=${data?.data?.id}&organization=${data.data.organization.id}&mask=${data.mask}&type=${data.two_factor_type}`
            );
          } else {
            localStorage.setItem(`accessToken`, data.access_token);
            localStorage.setItem(`__loginType`, "coach");
            window.location.href = `/app/v2/calendar`;
          }
        }
      })
      .catch((err) => {
        console.log("error:", err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: Constant.googleLoginClientId,
      callback: handleLogin,
    });

    google.accounts.id.prompt();

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
      text: "signin_with",
      width: 179,
      shape: "rectangular",
      logo_alignment: "center ",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box id="signInDiv" />

      <Backdrop
        sx={{
          backgroundColor: "#333970",
          opacity: "0.8 !important",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isProcess}
      >
        <CircularProgress color="inherit" size={25} />
      </Backdrop>
    </>
  );
};

export default GoogleLogin;
