import {
  Box,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IsMounted from "src/components/IsMounted";
import http from "src/utils/http";
import TreffasStyle from "src/utils/TreffasStyle";
import PageNotFound from "src/components/PageNotFound";
import { useTranslation } from "react-i18next";
import SL from "src/assets/images/survey-thank.png";
import TreffasTextField from "src/components/TreffasTextField";
import LoadingScreen from "src/components/LoadingScreen";
import { useSnackbarContext } from "src/context/SnackbarContext";

const TakeSurveyForm = ({
  surveyId,
  surveySlug,
  setValue,
  setSurveyHasAnswered,
  surveyIsRequired,
  clientDetails,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [requiredErrors, setRequiredErrors] = useState({});

  const [details, setDetails] = useState(null);
  const mounted = IsMounted();
  const [randomState, setRandomState] = useState({});
  const [randomErrors, setRandomErrors] = useState({});
  const [thankyDialog, setThankyouDialog] = useState(false);

  const [isProcess, setIsProcess] = useState(false);

  const { showSnackbar } = useSnackbarContext();

  const { t } = useTranslation();

  const getSurveyDetails = React.useCallback(() => {
    http
      .get("/api/general/survey/details", {
        params: {
          survey_id: surveyId,
          slug: surveySlug,
        },
      })
      .then((response) => {
        if (mounted.current) {
          setDetails(response.data.data);
        }
      })
      .catch((err) => console.log("error:", err.message))
      .finally(() => setIsLoading(false));
  }, [mounted, surveyId, surveySlug]);

  const handleMultipleAnswerWithLimit = (xx, data, key) => {
    let tricks = xx.target.checked ? "p" : "m";
    let curCount = randomState?.[`${data.id}`]?.selectedCount ?? 0;
    let sellimits = data.limits;

    setRandomState({
      ...randomState,
      [data.id]: {
        selectedCount: tricks === "p" ? curCount + 1 : curCount - 1,
      },
    });

    if (xx.target.checked && sellimits > 0 && curCount > 2) {
      setRandomErrors({
        ...randomErrors,
        [data.id]: {
          hasError: true,
          message: t("survey.take.form.error.selected_limits", { sellimits }),
        },
      });
    } else {
      setRandomErrors({
        ...randomErrors,
        [data.id]: {
          hasError: false,
          message: ``,
        },
      });
    }
  };

  const validateFields = (error) => {
    if (firstname.trim() === "" || firstname.length === 0) {
      error = "firstname-required";
      setRequiredErrors((requiredErrors) => {
        return {
          ...requiredErrors,
          firstname: {
            required: true,
            message: "Firstname is a reqiured field.",
          },
        };
      });
    }

    if (lastname.trim() === "" || lastname.length === 0) {
      error = "lastname-required";
      setRequiredErrors((requiredErrors) => {
        return {
          ...requiredErrors,
          lastname: {
            required: true,
            message: "Lastname is a reqiured field.",
          },
        };
      });
    }

    if (email.trim() === "" || email.length === 0) {
      error = "email-required";
      setRequiredErrors((requiredErrors) => {
        return {
          ...requiredErrors,
          email: {
            required: true,
            message: "Email is a reqiured field.",
          },
        };
      });
    }

    return error;
  };

  const handleSurvey = (e) => {
    e.preventDefault();
    e.persist();
    let error = [];
    setRequiredErrors({});
    let formdata = new FormData(e.target);
    formdata.append("survey_id", surveyId);
    formdata.append("firstname", firstname);
    formdata.append("lastname", lastname);
    formdata.append("email", email);
    formdata.append("mobile", phone);
    formdata.append("survey_id", surveyId);
    formdata.append("baseUrl", window.location.origin);

    if (Object.values(randomErrors).filter((x) => x.hasError).length > 0) {
      error = "max selected checkbox";
      showSnackbar(`Form has an error, Check your entry to continue.`, "error");
    }

    error = validateFields(error);

    if (error.length > 0) {
      console.log("form has an error:", error);
      return;
    }

    setIsProcess(true);

    http
      .post(`/api/general/survey/save-respondent-answer`, formdata)
      .then((response) => {
        if (response.data.message === "success") {
          setThankyouDialog(true);
          setTimeout(() => {
            handleCloseDialog();
          }, 5000);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const handleCloseDialog = () => {
    [
      { name: "firstname", value: firstname },
      { name: "lastname", value: lastname },
      {
        name: "mobile",
        value: phone,
      },
      { name: "email", value: email },
    ].forEach(({ name, value }) => setValue(name, value));
    setSurveyHasAnswered(true);
    setThankyouDialog(false);
  };

  const handleOnSurveySkip = () => {
    setSurveyHasAnswered(true);
  };

  useEffect(() => {
    getSurveyDetails();
  }, [getSurveyDetails]);

  React.useEffect(() => {
    setFirstname(clientDetails?.firstname ?? "");
    setLastname(clientDetails?.lastname ?? "");
    setPhone(clientDetails?.phone ?? "");
    setEmail(clientDetails?.email ?? "");
  }, [clientDetails]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (details === null) {
    return <PageNotFound />;
  }

  return (
    <Box
      sx={{
        mt: "58px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            mb: "40px",
          }}
        >
          <Box>
            <Typography
              className="heading-6"
              sx={{
                alignItems: "center",
                color: "#060A2D",
              }}
            >
              Survey
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#878787",
              }}
            >
              Take the survey to continue the event
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "31px",
              textTransform: "capitalize",
            }}
          >
            {details.name}
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#878787",
            }}
          >
            {details.description}
          </Typography>
        </Box>

        <form onSubmit={handleSurvey}>
          <Box>
            {details?.questionslist.length > 0 ? (
              <>
                {details?.questionslist.map((item, key) => {
                  return (
                    <Box
                      key={key}
                      sx={{
                        mt: "23px",
                        mb: 3,
                        border: "1px solid #D9DBE9",
                        borderRadius: "8px",
                        padding: "25px 28px",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                          }}
                        >
                          {key + 1}. {item.question}{" "}
                          {Boolean(parseInt(item.is_required)) && (
                            <span style={{ color: "red" }}> * </span>
                          )}
                        </Typography>
                      </Box>

                      {/* error display here */}
                      <Box>
                        {Boolean(randomErrors?.[`${item.id}`]?.hasError) && (
                          <FormHelperText error>
                            {randomErrors?.[`${item.id}`]?.message}{" "}
                          </FormHelperText>
                        )}
                      </Box>

                      {/* question-short-answer */}
                      {item.type === "question-short-answer" && (
                        <Box mt={1}>
                          <TreffasTextField
                            fullWidth
                            sx={TreffasStyle.formInputLarge("input")}
                            name={`answer[${item.id}][${item.choices[0].id}][]`}
                            required={Boolean(item.is_required)}
                          />
                        </Box>
                      )}

                      {/* question-one-answer */}
                      {item.type === "question-comment-essay" && (
                        <Box mt={1}>
                          <TreffasTextField
                            fullWidth
                            multiline
                            minRows={3}
                            sx={TreffasStyle.formInputLarge("textarea")}
                            name={`answer[${item.id}][${item.choices[0].id}][]`}
                            required={Boolean(item.is_required)}
                          />
                        </Box>
                      )}

                      {/* question-one-answer */}
                      {item.type === "question-one-answer" && (
                        <Box mx={3} mt={1}>
                          <Grid container spacing={"10px"}>
                            {item.choices.length > 0 &&
                              item.choices.map((ch, chKey) => (
                                <Grid item xs={12} md={12} key={chKey}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={`answer[${item.id}][]`}
                                      value={ch.id}
                                      type="radio"
                                      id={`flexRadioDefault1dfdsf${ch.id}`}
                                      {...(parseInt(chKey) + 1 === 1 &&
                                        Boolean(parseInt(item.is_required)) && {
                                          required: "required",
                                        })}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <label
                                      htmlFor={`flexRadioDefault1dfdsf${ch.id}`}
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "5px",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {ch.options}
                                    </label>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}

                      {/* question-multiple-answer */}
                      {item.type === "question-multiple-answer" && (
                        <Box mx={3} mt={1}>
                          <Grid container spacing={"10px"}>
                            {item.choices.length > 0 &&
                              item.choices.map((ch, chKey) => (
                                <Grid item xs={12} md={12} key={chKey}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={`answer[${item.id}][]`}
                                      value={ch.id}
                                      type="checkbox"
                                      id={`flexCheckDefault45345${ch.id}`}
                                      {...(Boolean(item.is_required) && {
                                        required: "required",
                                      })}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      onChange={(xx) => {
                                        // remove required //
                                        if (Boolean(item.is_required)) {
                                          if (xx.target.checked) {
                                            let selector =
                                              document.getElementsByName(
                                                `answer[${item.id}][]`
                                              );
                                            selector.forEach((e) =>
                                              e.removeAttribute("required")
                                            );
                                          } else {
                                            let selector =
                                              document.getElementsByName(
                                                `answer[${item.id}][]`
                                              );
                                            let hasselected = [];
                                            selector.forEach((e) => {
                                              if (e.checked) {
                                                hasselected.push(e.value);
                                              }
                                            });

                                            if (hasselected.length === 0) {
                                              selector.forEach((e) =>
                                                e.setAttribute(
                                                  "required",
                                                  "required"
                                                )
                                              );
                                            }
                                          }
                                        }

                                        // check for limit //
                                        handleMultipleAnswerWithLimit(
                                          xx,
                                          item,
                                          key
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`flexCheckDefault45345${ch.id}`}
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "5px",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        cursor: "pointer",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {ch.options}
                                    </label>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}

                      {/* question-true-false */}
                      {item.type === "question-true-false" && (
                        <Box mx={3} mt={1}>
                          <Grid container spacing={"10px"}>
                            {item.choices.length > 0 &&
                              item.choices.map((ch, chKey) => (
                                <Grid item xs={12} md={12} key={chKey}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={`answer[${item.id}][]`}
                                      value={ch.id}
                                      type="radio"
                                      id={`flexRadioDefault1dfdsf7788${ch.id}`}
                                      {...(parseInt(chKey) + 1 === 1 &&
                                        Boolean(parseInt(item.is_required)) && {
                                          required: "required",
                                        })}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <label
                                      htmlFor={`flexRadioDefault1dfdsf7788${ch.id}`}
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "5px",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {ch.options}
                                    </label>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}

                      {/* question-yes-no */}
                      {item.type === "question-yes-no" && (
                        <Box mx={3} mt={1}>
                          <Grid container spacing={"10px"}>
                            {item.choices.length > 0 &&
                              item.choices.map((ch, chKey) => (
                                <Grid item xs={12} md={12} key={chKey}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={`answer[${item.id}][]`}
                                      value={ch.id}
                                      type="radio"
                                      id={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                      {...(parseInt(chKey) + 1 === 1 &&
                                        Boolean(parseInt(item.is_required)) && {
                                          required: "required",
                                        })}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <label
                                      htmlFor={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "5px",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {ch.options}
                                    </label>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  );
                })}

                <Box>
                  <Grid container spacing="20px">
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Typography sx={TreffasStyle.formLabel}>
                          Firstname
                        </Typography>

                        <Box>
                          <TreffasTextField
                            fullWidth
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            placeholder="Enter firstname"
                            sx={TreffasStyle.formInputLarge("input")}
                            error={Boolean(requiredErrors?.firstname?.required)}
                            helperText={requiredErrors?.firstname?.message}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box>
                        <Typography sx={TreffasStyle.formLabel}>
                          Lastname
                        </Typography>

                        <Box>
                          <TreffasTextField
                            fullWidth
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            placeholder="Enter lastname"
                            sx={TreffasStyle.formInputLarge("input")}
                            error={Boolean(requiredErrors?.lastname?.required)}
                            helperText={requiredErrors?.lastname?.message}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box>
                        <Typography sx={TreffasStyle.formLabel}>
                          Phone Number
                        </Typography>
                        <Box>
                          <TreffasTextField
                            fullWidth
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Enter phone number"
                            sx={TreffasStyle.formInputLarge("input")}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box>
                        <Typography sx={TreffasStyle.formLabel}>
                          {" "}
                          Email Address{" "}
                        </Typography>

                        <Box>
                          <TreffasTextField
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email"
                            type="email"
                            sx={TreffasStyle.formInputLarge("input")}
                            error={Boolean(requiredErrors?.email?.required)}
                            helperText={requiredErrors?.email?.message}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* optins options */}
                <Box
                  sx={{
                    mt: "50px",
                    mb: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {!surveyIsRequired && (
                      <Box>
                        <Button
                          variant="outlined"
                          color={"primary"}
                          sx={{
                            width: { xs: "100%", sm: "120px" },
                            height: "48px",
                            fontSize: "14px",
                            borderRadius: "12px",
                            lineHeight: "31px",
                            ...TreffasStyle.buttonHover,
                          }}
                          onClick={() => handleOnSurveySkip()}
                        >
                          Skip Survey
                        </Button>
                      </Box>
                    )}

                    <Box>
                      <Button
                        variant="contained"
                        color={"primary"}
                        sx={{
                          width: { xs: "100%", sm: "120px" },
                          height: "48px",
                          fontSize: "14px",
                          borderRadius: "12px",
                          lineHeight: "31px",
                          ...TreffasStyle.buttonHover,
                        }}
                        startIcon={
                          isProcess && (
                            <CircularProgress color="inherit" size={15} />
                          )
                        }
                        disabled={isProcess}
                        type="submit"
                      >
                        {t("survey.take.form.button.submit")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Typography sx={{ color: "#838383" }}>
                {t("survey.take.form.error.noquestion_found")}
              </Typography>
            )}
          </Box>
        </form>
      </Box>

      <Dialog
        open={thankyDialog}
        fullScreen
        onClose={() => console.log("dialog unclosable")}
      >
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "740px",
              height: "350px",
              border: "1px solid #D9DBE9",
              borderRadius: "8px",
              px: { xs: "10px", md: "120px" },
            }}
          >
            <Box>
              <Box textAlign={"center"}>
                <img
                  src={SL}
                  alt={"treffas survey thank you logo"}
                  style={{
                    width: "88.71px",
                    height: "88px",
                  }}
                />
              </Box>

              <Box>
                <Typography
                  className="heading-4"
                  sx={{
                    textTransform: "capitalize",
                    color: "#060A2D",
                    textAlign: "center",
                  }}
                >
                  {t("survey.take.form.alert.thank_you")}
                </Typography>
              </Box>

              <Box mt={"7px"}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "center",
                  }}
                >
                  {details?.thankyou_message}
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: "50px",
                  textAlign: "center",
                }}
              >
                <Button
                  variant={"outlined"}
                  sx={(themes) => ({
                    [themes.breakpoints.up("md")]: {
                      borderRadius: "12px",
                      minWidth: "100px",
                      fontSize: "14px",
                    },
                    height: "48px",
                    ...TreffasStyle.buttonHover,
                  })}
                  onClick={() => handleCloseDialog()}
                >
                  {" "}
                  Close and contiue to booking{" "}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TakeSurveyForm;
