function initialize(user) {
  /* global pendo */
  pendo.initialize({
    visitor: {
      id: user?.id,
      email: user?.email,
      full_name: user?.name,
    },

    account: {
      id: user?.id,
      name: user?.name,
    },
  });
}

const Pendo = {
  initialize,
};

export default Pendo;
