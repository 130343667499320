import React, { useCallback, useEffect, useState } from "react";

import { Box, Container, Typography } from "@mui/material";
import http from "src/utils/http";
import SC from "src/assets/images/survey-thank.png";
import PaypalFialedPayments from "../failed-payments/Paypal";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import IconConfirm from "src/components/svg-icons/IconConfirm";
import FooterImage from "../../footer-image";
import { useSnackbarContext } from "src/context/SnackbarContext";

const PaypalSuccessPayments = ({ session_slug, direct_confirm }) => {
  const { showSnackbar } = useSnackbarContext();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const { t } = useTranslation();

  const getBooking = useCallback(() => {
    http
      .get(`/api/general/individual/appointment/details/bysession-slug`, {
        params: {
          sent_confirmation_email: false,
          session_slug,
          base_url: window.location.origin,
          payment_type: "paypal",
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          setDetails(data.session_details);
          showSnackbar(t("booking.confirm.alert.successful"), "success");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_slug]);

  useEffect(() => {
    // add time to let paypal process the IPN first
    setTimeout(() => {
      getBooking();
    }, 5000);
  }, [getBooking]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!Boolean(parseInt(details?.paypal_ispaid))) {
    return <PaypalFialedPayments session_slug={session_slug} />;
  }

  const directConfirm = parseInt(direct_confirm) === 1 ? true : false;

  return (
    <Container maxWidth={"tp"}>
      <Box
        sx={{
          border: { md: "1px solid #D9DBE9" },
          borderRadius: "8px",
          p: { xs: "10px", md: "86px" },
          mt: "80px",
          textAlign: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box>
            {directConfirm ? (
              <IconConfirm />
            ) : (
              <img
                src={SC}
                alt={"Treffas Logo"}
                style={{
                  width: "120px",
                  height: "120px",
                }}
              />
            )}
          </Box>

          <Box my={"20px"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: "19px", md: "22px" },
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {directConfirm
                ? t("booking.confirm.label.appointment_confirm")
                : t("booking.confirm.label.appointment_sent")}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {directConfirm
                ? t("booking.confirm.label.appointment_confirm.description")
                : t("booking.alert.appointment_sent_description")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "50px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FooterImage />
      </Box>
    </Container>
  );
};

export default PaypalSuccessPayments;
