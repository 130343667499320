export class Main {
  constructor(user) {
    this.user = user;
    this.integrations = {};
  }

  register(resource) {
    this.integrations = {
      ...this.integrations,
      ...resource,
    };
  }

  useName(name) {
    return this.integrations[name];
  }

  getList(type = "object") {
    // type values object || array
    if (type === "array") {
      return Object.keys(this.integrations).map((key) => ({
        name: key,
        data: this.integrations[key],
      }));
    }

    return this.integrations;
  }

  vadidatePackageForm() {
    const list = this.getList("array");
    let error = [];

    for (const integration of list) {
      let isEnabled = integration.data.IsEnabled();
      let config = integration.data.getConfig();
      let package_form = config?.data?.package_form ?? null;
      let sequence = package_form?.sequence ?? null;
      let list = package_form?.list ?? null;
      let tags = package_form?.tags ?? [];

      if (isEnabled && config.type === "marketing-tools") {
        if (package_form?.isBoxChecked && config.name === "mailchimp") {
          if (list === null || list === "") {
            error = "mailchimp-error";
          }
        }

        if (package_form?.isBoxChecked && config.name === "aweber") {
          if (list === null || list === "") {
            error = "error-aweber";
          }
        }

        if (package_form?.isBoxChecked && config.name === "getresponse") {
          if (list === null && tags.length === 0) {
            error = "error-getresponse";
          }
        }

        if (package_form?.isBoxChecked && config.name === "active campaign") {
          if ((list === null || list === "") && tags.length === 0) {
            error = "error-active_campaign";
          }
        }

        if (package_form?.isBoxChecked && config.name === "convert kit") {
          if (tags.length === 0 && sequence === null) {
            error = "error-convert_kit";
          }
        }

        // if mailerlite can create subscriber without tags
        if (package_form?.isBoxChecked && config.name === "mailerlite") {
          if (list === null || list === "") {
            error = "error-mailerlite-group-required";
          }
        }
      }
    }

    return error;
  }
}
