import React, { lazy } from "react";
import CoachOfferListForBooking from "src/views/general/booking";
// import CoachOfferForBooking from "src/views/general/individual-booking/schedule";
import TakeSurvey from "src/views/general/survey/Take";
import Thankyou from "src/views/general/survey/Thankyou";
import RespondentsCopy from "src/views/general/survey/respondents-copy";
import NextBooking from "src/views/general/next-booking";
import Success from "src/views/general/next-booking/schedule/Success";

import BookingCancel from "src/views/general/individual-booking/cancel";
import BookingConfirmation from "src/views/general/individual-booking/confirmation";
import PaymentCancel from "src/views/general/individual-booking/redirects/PaymentCancel";
import PaymentSuccessful from "src/views/general/individual-booking/redirects/PaymentSuccessful";
import ThankyouPage from "src/views/general/individual-booking/redirects/ThankyouPage";
import BookingConfirmationWithPayment from "src/views/general/individual-booking/confirmation/WithPayment";

import PaidBookingChecker from "src/views/general/individual-booking/schedule/stepper-paid-first/PaidBookingChecker";
import RescheduleIndividualBooking from "src/views/general/reschedule";

// route accessable by coach while on sign in and in client
const OpenRoutes = [
  {
    exact: true,
    path: "/appointment/booking/:organizationId/:userId",
    component: CoachOfferListForBooking, // manual selection of appointment
  },

  {
    exact: true,
    path: "/a/:personalLink",
    component: CoachOfferListForBooking, // manual selection of appointment with meta tags
  },

  {
    exact: true,
    path: "/:personalLink",
    component: CoachOfferListForBooking, // manual selection of appointment
  },

  // {
  //   exact: true,
  //   path: "/a/:personalLink/package/:organizationId/:userId/:offerId",
  //   component: lazy(() => import("src/views/general/booking-request")),
  // },

  {
    exact: true,
    path: "/appointment/individual/booking/:organizationId/:userId/:offerId", // manual selection of appointment with meta tags
    // component: CoachOfferForBooking,
    component: lazy(() => import("src/views/general/booking-request")),
  },

  {
    exact: true,
    path: "/appointment/individual/landing-page/:organizationId/:userId/:offerId", // manual viewing of coach package with from package details meta tags
    component: lazy(() => import("src/views/general/landing-page")),
  },

  {
    exact: true,
    path: "/appointment-individual/booking/reschedule/:appointment_id/:session_slug", // manual selection of appointment with meta tags
    component: RescheduleIndividualBooking,
  },

  {
    exact: true,
    path: "/survey/:surveyId/:surveySlug",
    component: TakeSurvey,
  },

  {
    exact: true,
    path: "/survey/respodent/copy/:surveyId/:respondentId/:surveySlug",
    component: RespondentsCopy,
  },

  {
    exact: true,
    path: "/survey/thank-you/:surveyId/:surveySlug",
    component: Thankyou,
  },

  {
    exact: true,
    path: "/next/booking/:sessionSlug",
    component: NextBooking,
  },

  {
    exact: true,
    path: "/next/booking/scheduled/successfuly",
    component: Success,
  },

  {
    exact: true,
    path: "/appointments/individual/booking/confirmation/:organization_id/:user_id/:offer_id/:appointment_id/:client_id",
    component: BookingConfirmation,
  },

  {
    exact: true,
    path: "/appointments/individual/booking/confirmation/with-payment",
    component: BookingConfirmationWithPayment,
  },

  {
    exact: true,
    path: "/appointments/individual/booking/cancel/:organization_id/:user_id/:offer_id/:appointment_id/:client_id",
    component: BookingCancel,
  },

  {
    exact: true,
    path: "/appointment-individual/booking/payment-successful",
    component: PaymentSuccessful,
  },

  {
    exact: true,
    path: "/appointment-individual/paid-booking/successful/:organizationId/:userId/:offerId",
    component: PaidBookingChecker,
  },

  {
    exact: true,
    path: "/appointment-individual/booking/payment-cancelled",
    component: PaymentCancel,
  },

  {
    exact: true,
    path: "/appointment-individual/booking/thank-you",
    component: ThankyouPage,
  },

  {
    exact: true,
    path: "/booking/request/declined/:organizationId/:userId/:appointmentId/:clientId",
    component: lazy(() => import("src/views/general/booking-request/declined")),
  },

  {
    exact: true,
    path: "/booking/request/reschedule/:organizationId/:userId/:appointmentId/:clientId",
    component: lazy(() =>
      import("src/views/general/booking-request/reschedule")
    ),
  },

  {
    exact: true,
    path: "/booking/request/reschedule-form/:appointment_id/:session_slug",
    component: lazy(() =>
      import("src/views/general/booking-request-accepted-reschedule")
    ),
  },

  {
    exact: true,
    path: "/booking/request/cancel-form/:organization_id/:user_id/:offer_id/:appointment_id/:client_id",
    component: lazy(() =>
      import("src/views/general/booking-request-accepted-cancelled")
    ),
  },

  {
    exact: true,
    path: "/booking/request/accept/:organizationId/:userId/:appointmentId/:clientId",
    component: lazy(() => import("src/views/general/booking-request/accept")),
  },

  {
    exact: true,
    path: "/buy/digital-product/:organizationId/:userId/:offerId",
    component: lazy(() => import("src/views/general/buy-digital-product")),
  },

  {
    exact: true,
    path: "/buy/digital-product/paypal/payment-successful",
    component: lazy(() =>
      import("src/views/general/buy-digital-product/pages/PaypalPaymentSuccess")
    ),
  },

  {
    exact: true,
    path: "/buy/digital-product/paypal/payment-cancelled",
    component: lazy(() =>
      import("src/views/general/buy-digital-product/pages/PaypalPaymentCancel")
    ),
  },

  {
    exact: true,
    path: "/buy/digital-products/stripe/payment-successful",
    component: lazy(() =>
      import("src/views/general/buy-digital-product/pages/StripePaymentSuccess")
    ),
  },

  {
    exact: true,
    path: "/buy/digital-products/stripe/payment-cancelled",
    component: lazy(() =>
      import("src/views/general/buy-digital-product/pages/StripePaymentCancel")
    ),
  },

  // buy course
  {
    exact: true,
    path: "/buy/course/:organizationId/:userId/:offerId",
    component: lazy(() => import("src/views/general/buy-course")),
  },

  {
    exact: true,
    path: "/buy/course/paypal/payment-successful",
    component: lazy(() =>
      import("src/views/general/buy-course/pages/PaypalPaymentSuccess")
    ),
  },

  {
    exact: true,
    path: "/buy/course/paypal/payment-cancelled",
    component: lazy(() =>
      import("src/views/general/buy-course/pages/PaypalPaymentCancel")
    ),
  },

  {
    exact: true,
    path: "/buy/course/stripe/payment-successful",
    component: lazy(() =>
      import("src/views/general/buy-course/pages/StripePaymentSuccess")
    ),
  },

  {
    exact: true,
    path: "/buy/course/stripe/payment-cancelled",
    component: lazy(() =>
      import("src/views/general/buy-course/pages/StripePaymentCancel")
    ),
  },
  // buy course end
];

export default OpenRoutes;
