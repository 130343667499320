import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import http from "src/utils/http";
import SC from "src/assets/images/survey-thank.png";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import { useSnackbarContext } from "src/context/SnackbarContext";

const StripeFailedPayments = ({ session_slug }) => {
  const { showSnackbar } = useSnackbarContext();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const { t } = useTranslation();

  const getBooking = useCallback(() => {
    http
      .get(`/api/general/individual/appointment/details/bysession-slug`, {
        params: {
          sent_confirmation_email: false,
          session_slug,
          base_url: window.location.origin,
          payment_type: "stripe",
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            "Unable to complete booking, Payment unsuccessful.",
            "success"
          );
          setDetails(data.session_details);
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_slug]);

  useEffect(() => {
    getBooking();
  }, [getBooking]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={"lg"}>
      <Box
        sx={{
          border: "1px solid #D9DBE9",
          borderRadius: "8px",
          p: "86px",
          mt: "80px",
          textAlign: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box>
            <img
              src={SC}
              alt={"Treffas Logo"}
              style={{
                width: "120px",
                height: "120px",
              }}
            />
          </Box>

          <Box my={"20px"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {t("booking.redirect.payment.paypal.failed.label.unpaid")}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {t("booking.redirect.payment.paypal.failed.label.unpaid.limit")}
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              fullWidth
              sx={{
                textTransform: "inherit",
                borderRadius: "12px",
                fontSize: "14px",
                padding: "10px",
                lineHeight: "31px",
                fontWeight: 400,
                height: "48px",
                maxWidth: "256px",
              }}
              onClick={() => {
                let paymenturl = null;
                if (details?.custom_data) {
                  paymenturl = JSON.parse(details?.custom_data).stripe_config
                    ?.payment_url;
                } else {
                  paymenturl = JSON.parse(
                    details?.session_records_firstentry?.appointment?.booking
                  )?.stripe_config?.payment_url;
                }

                if (paymenturl) {
                  window.open(paymenturl, "_blank");
                } else {
                  showSnackbar(
                    t("booking.confirm.error.payment_url_notfound"),
                    "error"
                  );
                }
              }}
            >
              {t("booking.button.open_payment_url")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default StripeFailedPayments;

// const styles = {
//   label: {
//     color: "#838383",
//     fontWeight: 400,
//     fontSize: "14px",
//     lineHeight: "31px",
//     textTransform: "capitalize",
//   },
//   value: {
//     color: "#060A2D",
//     fontWeight: 400,
//     fontSize: "14px",
//     lineHeight: "24px",
//   },
// };
