import React from "react";
import SurveyForm from "./addiotional-information/Survey";
import { Box, Button, CircularProgress } from "@mui/material";
import TreffasStyle from "src/utils/TreffasStyle";
import { useTranslation } from "react-i18next";

const Survey = ({
  hideThisSurvey,
  selectedOffer,
  errors,
  control,
  randomState,
  setRandomState,
  randomErrors,
  setRandomErrors,
  setValue,
  getValues,
  stepperGuideLength,
  isProcess,
  submitButtonRef,
  setError,
}) => {
  const { t } = useTranslation();

  const checkSurveyFields = () => {
    let error = [];

    if (selectedOffer?.survey.length > 0) {
      selectedOffer?.survey.forEach((survey) => {
        if (survey?.questionslist.length > 0) {
          survey?.questionslist.forEach((item) => {
            let field = "";
            if (item.type === "question-short-answer") {
              field = `answer[_${item.id}][_${item.choices[0].id}`;
            }

            if (item.type === "question-comment-essay") {
              field = `answer[_${item.id}][_${item.choices[0].id}`;
            }

            if (item.type === "question-one-answer") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-multiple-answer") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-true-false") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-yes-no") {
              field = `answer[_${item.id}]`;
            }

            let current_value = getValues(field) ?? "";

            if (Boolean(item.is_required) && !current_value) {
              error = `question-${field}-is-required`;
              setError(field, {
                required: true,
                message: t("booking.error.required.question"),
              });
            }
          });
        }
      });
    }

    if (error.length > 0) {
      console.log("form has an error:");
      return;
    }

    submitButtonRef.current.click();
  };

  return (
    <>
      {/* additional survey */}
      {!hideThisSurvey && selectedOffer?.survey.length > 0 && (
        <SurveyForm
          selectedOffer={selectedOffer}
          errors={errors}
          control={control}
          randomState={randomState}
          setRandomState={setRandomState}
          randomErrors={randomErrors}
          setRandomErrors={setRandomErrors}
          setValue={setValue}
          getValues={getValues}
          stepperGuideLength={stepperGuideLength}
        />
      )}

      <Box
        sx={{
          mt: "30px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          variant={"contained"}
          sx={{
            height: "48px",
            borderRadius: "12px",
            fontSize: "14px",
            ...TreffasStyle.buttonHover,
          }}
          // onClick={() => submitButtonRef.current.click()}
          onClick={() => checkSurveyFields()}
          disabled={isProcess}
          startIcon={
            isProcess && <CircularProgress size={15} color="inherit" />
          }
        >
          {t(
            "individual.booking.schedule.choose_schedule.button.schedule_event"
          )}
        </Button>
      </Box>
    </>
  );
};

export default Survey;
