import React, { Fragment, useState } from "react";
import Large from "./Large";
import Small from "./Small";
import Helper from "src/utils/Helper";
import MobileTrialEndedDialog from "./mobile";
import { Users } from "src/context";
import moment from "moment";

const SubscriptionTrialsEnd = () => {
  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") ?? "large"
  ); // large, small
  const [dialog, setDialog] = useState(true); // large, small
  const isTablet = Helper.IsTablet();

  const { user } = React.useContext(Users);

  const initialStartDate = new Date(
    moment.utc(user.server_time).format("YYYY-MM-DD HH:mm")
  );
  const endDate = new Date(
    moment(user.trial_ends_at).format("YYYY-MM-DD HH:mm")
  );

  console.log("user.trial_ends_at:", user.trial_ends_at);
  console.log("user.server_time:", user.server_time);

  function getTimeRemaining() {
    const totalMilliseconds = endDate - initialStartDate;
    const seconds = Math.floor((totalMilliseconds / 1000) % 60);
    const minutes = Math.floor((totalMilliseconds / 1000 / 60) % 60);
    const hours = Math.floor((totalMilliseconds / 1000 / 60 / 60) % 24);
    const days = Math.floor(totalMilliseconds / 1000 / 60 / 60 / 24);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  const subscription = user.subscription ? JSON.parse(user.subscription) : null;
  const isFreeSubscription = subscription?.process_from === "free-subscription";

  const timeRemaining = getTimeRemaining();

  console.log("isFreeSubscription:", isFreeSubscription);
  console.log("free subscription remaining time");
  const text = `${timeRemaining.days} days ${timeRemaining.hours} hours ${timeRemaining.minutes} minutes`;
  console.log(text);

  if (
    !isFreeSubscription ||
    window.location.pathname.includes("app/onboarding")
  ) {
    return null;
  }

  return (
    <Fragment>
      {isTablet ? (
        <MobileTrialEndedDialog
          days={timeRemaining.days}
          open={dialog}
          close={() => setDialog(false)}
        />
      ) : (
        <>
          {viewType === "large" && (
            <Large days={timeRemaining.days} setViewType={setViewType} />
          )}
          {viewType === "small" && (
            <Small days={timeRemaining.days} setViewType={setViewType} />
          )}
        </>
      )}
    </Fragment>
  );
};

export default SubscriptionTrialsEnd;
