import {
  Grid,
  Box,
  Typography,
  Divider,
  TextField,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import TreffasStyle from "src/utils/TreffasStyle";
import { StaticDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import http from "src/utils/http";
import Constant from "src/utils/Constant";
import { useTranslation } from "react-i18next";
import Timezone from "src/utils/Timezone";
import TimezonePicker from "../../timezone-picker";
import TreffasTextField from "src/components/TreffasTextField";
import { ArrowDropDown } from "@mui/icons-material";
import Booking from "src/utils/Booking";
import RenderTimeSlots from "../../individual-booking/schedule/RenderTimeSlots";
import Helper from "src/utils/Helper";
import momentTimezone from "moment-timezone";

let dateFormat = "YYYY/MM/DD";

const DateAndTime = ({
  errors,
  setSelectedTimezone,
  selectedTimezone,
  sessionList,
  selectedSessionTime,
  setSessionSelectedTime,
  selectedOffer,
  setSessionList,
  setBlockTimeOnSelectedDate,
  appForThisOffer,
  blockTimeOnSelectedDate,
  selectedDate,
  setSelectedDate,
  setError,
  clearErrors,
  calendarMaxDate,
  userDateFormat,
  userTimeFormat,
  userMinimumBookingNotice,
  coachDetails,
  allUpcommingAppt,
  setAllUpcommingAppt,
}) => {
  const { user_id, id, organization_id } = selectedOffer;
  const [preparingSessionList, setPreparingSesisonList] = useState(false);

  const { t } = useTranslation();
  const userId = user_id;
  const offerId = id;
  const organizationId = organization_id;

  const appointmentInterval =
    selectedOffer?.custom_options?.interval ?? Constant.interval;

  const appointmentSlots = selectedOffer?.slots;

  const [overFlowTimeToRender, setOverFlowTimeToRender] = useState([]);
  const [underFlowTimeToRender, setUnderFlowTimeToRender] = useState([]);
  const [isTimezoneChange, setIsTimezoneChange] = useState(false);
  const [timeHover, setTimeHover] = useState(null);

  const [isUseTimeRecord, setIsUseTimeRecord] = useState(false);
  const [timeRecords, setTimeRecords] = useState({
    listofOverFlowDates: [],
    listofUnderFlowDates: [],
    listofOverflow: {},
    listofUnderflow: {},
    listOfAvailableDates: {},
  });

  const [timezoneDialog, setTimezoneDialog] = React.useState({ open: false });
  let listofOverFlowDates = [];
  let listofUnderFlowDates = [];

  let listofOverflow = {};
  let listofUnderflow = {};
  let listOfAvailableDates = {};

  const shouldDisabledDate = (date) => {
    let isWholeDayBlockedByCoach =
      Booking._dateandtime.isWholeDayBlockedByCoach(
        selectedOffer,
        date,
        coachDetails?.timezone_utc,
        selectedTimezone
      );

    if (isWholeDayBlockedByCoach) {
      return true; // calendar date is block by coach wholeday 00:00 to 23:59
    }

    let package_availability = Booking._dateandtime.getPackageAvailability(
      date,
      selectedOffer
    );

    // call fn to initialize listofOverFlowDates and listofUnderFlowDates variables
    Booking._dateandtime.generateSessionTime(
      package_availability,
      selectedOffer?.duration,
      date,
      selectedTimezone,
      coachDetails,
      appointmentInterval,
      listofOverFlowDates,
      listofOverflow,
      listofUnderFlowDates,
      listofUnderflow,
      listOfAvailableDates
    );

    let today = moment(date).format(dateFormat);
    let day = moment(date, "dddd").format("dddd");
    let daysEnabled = Booking._dateandtime.getDayEnableInPackage(
      date,
      selectedOffer
    );
    let dayIsEnable = Booking._dateandtime.isDayEnable(daysEnabled, day);
    // if isUseTimeRecord is true use states to match records if false use actual variables
    let hasOverflow = isUseTimeRecord
      ? timeRecords.listofOverFlowDates.includes(today.toString())
      : listofOverFlowDates.includes(today.toString());
    let hasUnderFlow = isUseTimeRecord
      ? timeRecords.listofUnderFlowDates.includes(today.toString())
      : listofUnderFlowDates.includes(today.toString());
    let hasSessions = isUseTimeRecord
      ? timeRecords.listOfAvailableDates[today]
      : listOfAvailableDates[today];

    if (hasOverflow) return false;
    if (hasUnderFlow) return false;
    if (!hasSessions && !hasOverflow && !hasUnderFlow) return true;
    if (!dayIsEnable) return false;
    return true;
  };

  const handleSelectedDate = async (date) => {
    if (preparingSessionList) return;
    setPreparingSesisonList(true);
    clearErrors();
    setSessionSelectedTime({});
    setSelectedDate(date);
    setIsTimezoneChange(false);
    setIsUseTimeRecord(false);

    let today = moment(date).format(dateFormat);

    const gettodayAppt = await http.get(
      `/api/general/individual/offer/details/get-todays-offer-appts`,
      {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
          selected_date: today,
        },
      }
    );

    const getallUpcomingAppt = await http.get(
      `/api/general/individual/offer/details/get-all-upcomming-appts`,
      {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
          selected_date: today,
        },
      }
    );

    // if (getallUpcomingAppt.data.length > 0) {
    setAllUpcommingAppt(getallUpcomingAppt.data);
    // }

    if (gettodayAppt.data.length >= parseInt(appointmentSlots)) {
      setSessionList([]);
      setError("time", {
        message: t("booking.error.required.no_available_time", {
          today: today.replaceAll("/", "-"),
        }),
        required: true,
      });
      setPreparingSesisonList(false);
      return;
    }

    setSessionList(
      listOfAvailableDates[today] ?? [] // if time has underflow get the yesterday data as start of availabeltime
    );
    setOverFlowTimeToRender(listofOverflow[today] ?? []);
    setUnderFlowTimeToRender(listofUnderflow[today] ?? []);
    setPreparingSesisonList(false);

    Booking._dateandtime.handleBlockedDate(
      date,
      selectedOffer,
      setBlockTimeOnSelectedDate
    );
  };

  const handleSelectedTime = async (time) => {
    setIsUseTimeRecord(true);
    // store prev records and use to rerender when back button is press
    setTimeRecords({
      listofOverFlowDates: listofOverFlowDates,
      listofUnderFlowDates: listofUnderFlowDates,
      listofOverflow: listofOverflow,
      listofUnderflow: listofUnderflow,
      listOfAvailableDates: listOfAvailableDates,
    });

    return Booking._dateandtime.handleSelectedTime(
      time,
      userMinimumBookingNotice,
      userDateFormat,
      userTimeFormat,
      setSessionSelectedTime,
      null, // nextButtonRef
      setError,
      t
    );
  };

  const filterList = (list) => {
    const coachTimezone = coachDetails?.timezone_utc;
    return Booking._dateandtime.filterList(
      list,
      blockTimeOnSelectedDate,
      appForThisOffer,
      allUpcommingAppt,
      selectedOffer,
      selectedTimezone,
      selectedDate,
      coachTimezone
    );
  };

  const ___filteredList = [
    ...overFlowTimeToRender,
    ...sessionList,
    ...underFlowTimeToRender,
  ];

  const filteredList = filterList(___filteredList);

  const clientToday = momentTimezone(new Date(), Helper.zeroTimezone).tz(
    selectedTimezone
  );

  return (
    <>
      <Box
        sx={{
          my: "31px",
        }}
      >
        <Box>
          <Typography
            className="heading-6"
            sx={{
              alignItems: "center",
              color: "#060A2D",
            }}
          >
            {t("booking.label.choose_date_and_time")}
          </Typography>
        </Box>

        <Box my={"16px"}>
          <Divider />
        </Box>

        <Box
          sx={(themes) => ({
            [themes.breakpoints.up("md")]: {
              display: "flex",
              gap: "30px",
            },
            [themes.breakpoints.down("md")]: {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
          })}
        >
          <Box
            sx={{
              // backgroundColor: "#fafcff",
              px: { xs: "10px" },
              pt: "10px",
              borderRadius: "16px",
            }}
          >
            <Box className={"new-static-calendar hide-selected-button"}>
              <StaticDatePicker
                // disablePast
                minDate={
                  new Date(
                    moment(clientToday, "YYYY/MM/DD").format("YYYY/MM/DD")
                  )
                }
                displayStaticWrapperAs="desktop"
                value={selectedDate ? moment(selectedDate, "YYYY/MM/DD") : null}
                shouldDisableDate={(date) => shouldDisabledDate(date)}
                onChange={(value) => handleSelectedDate(value)}
                onMonthChange={() => setIsUseTimeRecord(false)}
                renderInput={(params) => <TextField {...params} />}
                views={["day"]}
                inputFormat={userDateFormat}
                maxDate={
                  new Date(
                    moment(calendarMaxDate, "YYYY/MM/DD").format("YYYY/MM/DD")
                  )
                }
              />
            </Box>
          </Box>

          {/* availabitlity */}
          <Box
            style={{
              width: "100%",
            }}
          >
            <Box mt={"10px"} mb={"20px"}>
              <Box mb={"10px"}>
                <Typography sx={styles.labels}>
                  {t("booking.placeholder.select_timezone")}
                </Typography>
              </Box>

              <Box>
                <TreffasTextField
                  value={Timezone.getLabel(selectedTimezone) ?? ""}
                  sx={TreffasStyle.formInputLarge("autocomplete")}
                  placeholder={t("booking.placeholder.select_timezone")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size={"small"}
                          onClick={() => setTimezoneDialog({ open: true })}
                        >
                          <ArrowDropDown />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box>
                  <TimezonePicker
                    dialog={timezoneDialog}
                    close={() =>
                      setTimezoneDialog({
                        open: false,
                      })
                    }
                    selectedTimezone={selectedTimezone}
                    onChange={(data) => {
                      setSelectedTimezone(data);
                      setIsTimezoneChange(true);
                      setIsUseTimeRecord(false);
                      setOverFlowTimeToRender([]);
                      setUnderFlowTimeToRender([]);
                      setSessionList([]);
                      listofUnderflow = {};
                      listofOverflow = {};
                      listofOverFlowDates = [];
                      listofUnderFlowDates = [];
                      listOfAvailableDates = {};
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box>
              <Box mb={"10px"}>
                <Typography sx={styles.labels}>
                  {t("booking.placeholder.time_slots")}
                </Typography>
              </Box>

              {errors?.time && (
                <Box mb={"5px"}>
                  <FormHelperText error>{errors.time.message}</FormHelperText>
                </Box>
              )}

              {selectedDate === null || selectedDate === "" ? (
                <NoAvailableTime
                  message={t("booking.error.required.select_date")}
                />
              ) : (
                <Box
                  className="session-time-scroll"
                  sx={{
                    height: "338px",
                    overflowY: "auto",
                    // width: "240px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {preparingSessionList ? (
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          mt: "10px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    ) : filteredList?.length > 0 ? (
                      <>
                        <Grid container spacing={2}>
                          {filteredList?.map((time, key) => {
                            return (
                              <Grid item xs={12} sm={6} key={key}>
                                <Box
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <RenderTimeSlots
                                    selectedSessionTime={selectedSessionTime}
                                    time={time}
                                    handleSelectedTime={handleSelectedTime}
                                    timeHover={timeHover}
                                    setTimeHover={setTimeHover}
                                    userTimeFormat={userTimeFormat}
                                    _key={key}
                                  />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <NoAvailableTime
                        message={
                          isTimezoneChange
                            ? t(
                                "individual.booking.schedule.choose_schedule.select_date_again"
                              )
                            : t(
                                "booking.error.required.no_time_on_selected_date"
                              )
                        }
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DateAndTime;

const NoAvailableTime = ({ message }) => (
  <Box
    sx={{
      height: "338px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      width: "100%",
      borderRadius: "8px",
    }}
  >
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "11px",
        letterSpacing: "0.07em",
        color: "#A1A1A1",
        textTransform: "uppercase",
        textAlign: "center",
      }}
    >
      {message}
    </Typography>
  </Box>
);

const styles = {
  labels: {
    fontFamily: "var(--base-font-neuehaasmedium) !important",
    fontSize: "10px",
    lineHeight: "11px",
    letterSpacing: "0.07em",
    color: "#A1A1A1",
    textTransform: "uppercase",
  },
};
