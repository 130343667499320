import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Logo from "src/components/Logo";

const UnGuardedTopBar = () => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#fff", borderBottom: "2px solid #f5f5f5" }}
      elevation={0}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Logo />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default UnGuardedTopBar;
