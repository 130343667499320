import http from "src/utils/http";

export class Processor {
  constructor(user) {
    this.user = user;
  }

  async getLists() {
    let { data } = await http.get(
      `/api/integrations/integration/user-integrated/get-mailchimp-lists`,
      {
        params: {
          user_id: this.user?.id,
          organization_id: this.user?.organization?.id,
        },
      }
    );

    return data;
  }
}
