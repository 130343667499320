import { useContext } from "react";
import SampleComponent from "./SampleComponent";
import { Users } from "src/context";
import { Processor } from "./Processor";

let state = null;

const IsEnabled = () => {
  const { userIntegrations } = useContext(Users);
  let active_integrations = userIntegrations ?? [];

  if (active_integrations.length === 0) return false;

  let check = active_integrations.filter((x) => x.id === 9);
  if (check.length > 0 && check?.[0]?.user_integrations === "active") {
    return true;
  }

  return false;
};

const getComponent = () => {
  return SampleComponent;
};

const getConfig = () => {
  return {
    name: "active campaign",
    type: "marketing-tools",
    data: state,
  };
};

const updateData = (data) => {
  state = data;
};

const useProcessor = (user) => {
  return new Processor(user);
};

// check if exist in package or not
const isActiveInpackage = (_package) => {
  let config = _package?.options?.active_compaign_config ?? {};
  return {
    ["active campaign"]: {
      active: Object.keys(config).length > 0 ? true : false,
      list: config?.list_id ?? null,
      type: config?.type ?? null,
      tags: config?.tags ? JSON.parse(config?.tags) : [],
      tags_isrequired_list: false,
    },
  };
};

export default {
  IsEnabled,
  getComponent,
  getConfig,
  updateData,
  useProcessor,
  isActiveInpackage,
};
