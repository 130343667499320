import { styled } from "@mui/material/styles";
import CryptoJS from "crypto-js";

const Constant = {
  // mixPanelKey: "4ddc0ebf5fc0aa8363c3f6ee50c81001", //  --- jhomar sample live mix panel key
  mixPanelKey: "667c4af62f691ac63a0acd33fa49c8cd", // --- treffas live mix panel key
  googleLoginClientId:
    "1054174964780-bsfj494jcht68jjvp8d59tnpikr9curf.apps.googleusercontent.com", // folyeo project @ integration@treffas.com account
  // treffas clientId -> "572662465947-cev1tu29n2fn1o7bg90o6bkg737gnsp4.apps.googleusercontent.com", // source ---->  https://console.cloud.google.com/apis/credentials/oauthclient/686596697431-8ihdjho9avhf10rshhmpctdd6hkmmrud.apps.googleusercontent.com?authuser=1&project=smiling-matrix-342415
  linkedInClientId: "861ndxz4bflhoj", // source ----> https://www.linkedin.com/developers/apps/203856493/auth @ jhomar.baluca@gmail.com account
  linkedInClientSecret: "WPL_AP1.tEkM79M42QUjkJwL.2wbn1Q==", // source ----> https://www.linkedin.com/developers/apps/203856493/auth @ jhomar.baluca@gmail.com account
  cryptoSecretPass: "treffasAB987456321zxcvbnmasdfghjklqwertyuiop",
  eventColor: [
    "#ff4f00",
    "#ff758e",
    "#e55cff",
    "#8247f5",
    "#0099ff",
    "#0ae8f0",
    "#17e885",
    "#ccf000",
    "#f8e436",
    "#ffa600",
  ],

  quillToolbar: {
    toolbar: [
      "italic",
      "bold",
      "underline",
      "strike",
      "link",
      "align", // left
      { align: "center" },
      { align: "right" },
      { list: "bullet" },
      "clean",
    ],
  },

  renderEventColor: styled("div")`
    ${({ bgcolor }) => `
        background-color: ${bgcolor};
    `}
    width: 46px;
    height: 46px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: 0.2s all;
    display: inline-block;
    margin-right: 8px;
    position: relative;

    &:last-child {
      margin-right: 0px;
    }

    > .MuiSvgIcon-root {
      position: absolute;
      top: 50%;
      left: 50%;
      color: white;
      font-size: 23px;
      transform: translate(-49%, -54%);
    }
  `,

  bannerColor: [
    "#ffffff",
    "#FCF4F4",
    "#FFF5EB",
    "#FFFDE7",
    "#F6FFF4",
    "#F2FBFC",
    "#F1F1FF",
    "#F5F2EE",
    "#FFC9C9",
    "#D0AEAE",
    "#F4CCA7",
    "#FFF6B0",
    "#C5FFBB",
    "#BDF4FD",
  ],

  interval: 15, // default appointment interval/gap

  dataCenterLocation: [
    { label: "Asia", value: "Asia" },
    { label: "Europe", value: "Europe" },
    { label: "Australia/New Zealand", value: "Australia/New Zealand" },
    // { label: "Oceania", value: "Oceania" },
    { label: "USA/Canada", value: "USA/Canada" },
  ],

  typeOfCoaching: [
    "Beauty and Personal Care",
    "Fitness and Sports",
    "Legal",
    "Food and Cooking",
    "People and Pets",
    "Lifestyle and Leisure",
    "Education",
    "IT and Web",
    "Healthcare",
    "Coaching and Consulting",
    "Business and Marketing",
    "Finance and Money",
    "Construction and Home",
    "Arts and Entertainment",
    "Others",
  ],

  // if you update the list of currency include the list in utils service in backend
  currency: [
    { label: "USD", value: "USD", symbols: "$" },
    { label: "EUR", value: "EUR", symbols: "€" },
    { label: "GBP", value: "GBP", symbols: "£" },
    { label: "AUD", value: "AUD", symbols: "$" },
    { label: "NZD", value: "NZD", symbols: "$" },
    { label: "CHF", value: "CHF", symbols: "Fr" },
  ],

  getCurrency: (currency) => {
    return Constant.currency.find((curr) => curr.value === currency);
  },

  checkQuestionType: (type) => {
    switch (type) {
      case "question-one-answer":
        return 1;
      case "question-multiple-answer":
        return 2;
      case "question-comment-essay":
        return 3;
      case "question-true-false":
        return 4;
      case "question-short-answer":
        return 5;
      default:
        return 6; // question-yes-no
    }
  },

  decodeQuestionType: (type) => {
    switch (type) {
      case 1:
        return "Multiple Choice (Single)";
      case 2:
        return "Multiple Choice (Multiple)";
      case 3:
        return "Essay";
      case 4:
        return "True/False";
      case 5:
        return "Short Answer";
      default:
        return "Yes/No"; // question-yes-no
    }
  },

  imageUrl: (path, filename) => {
    return `${process.env.REACT_APP_API}/${path}/${filename}`;
  },

  getFile: (path, filename) => {
    return `${process.env.REACT_APP_API}/api/get-files/${path}/${filename}`;
  },

  getFileInStorage: (path, filename) => {
    return `${process.env.REACT_APP_API}/storage/${path}/${filename}`;
  },

  encrypto: (text) => {
    try {
      const data = CryptoJS.AES.encrypt(
        text,
        Constant.cryptoSecretPass
      ).toString();

      return data;
    } catch (error) {
      console.log(`encrypt error`, error);
    }
  },

  decrypto: (text) => {
    try {
      const data = CryptoJS.AES.decrypt(text, Constant.cryptoSecretPass);
      return data.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log(`decrypto error`, error);
    }
  },

  paymentTerms: [
    { label: "7 days", value: "7 days" },
    { label: "15 days", value: "15 days" },
    { label: "30 days", value: "30 days" },
    { label: "45 days", value: "45 days" },
  ],

  automatedEmailNotificationStatus: {
    created_booking: "unsent",
    confirmed_booking: "unsent",
    reschedule_booking: "unsent",
    canceled_booking: "unsent",
    start_booking: "unsent",
    end_booking: "unsent",
    next_booking: "unsent",
  },

  availableFonts: [
    { label: "Alegreya Sans", value: "alegreya_sans" },
    { label: "Alegreya", value: "alegreya" },
    { label: "Archivo Narrow", value: "archivo_narrow" },
    { label: "Archivo", value: "archivo" },
    { label: "BioRhyme", value: "biorhyme" },
    { label: "Cardo", value: "cardo" },
    { label: "Cormorant", value: "cormorant" },
    { label: "Eczar", value: "eczar" },
    { label: "Fira Sans", value: "fira_sans" },
    { label: "Fraunces", value: "fraunces" },
    { label: "Karla", value: "karla" },
    { label: "Lato", value: "lato" },
    { label: "Libre Baskerville", value: "libre_baskerville" },
    { label: "Libre Franklin", value: "libre_franklin" },
    { label: "Lora", value: "lora" },
    { label: "Manrope", value: "manrope" },
    { label: "Merriweather", value: "merriweather" },
    { label: "Nanum Myeongjo", value: "nanum" },
    { label: "Neuton", value: "neuton" },
    { label: "Open Sans", value: "open_sans" },
    { label: "Poppins", value: "poppins" },
    { label: "PT Sans", value: "pt_sans" },
    { label: "Raleway", value: "raleway" },
    { label: "Roboto", value: "roboto" },
    { label: "Source Sans 3", value: "source_sans_3" },
    { label: "Source Serif 4", value: "source_serif_4" },
    { label: "Space Mono", value: "space_mono" },
    { label: "Spectral", value: "spectral" },
    { label: "Work Sans", value: "work_sans" },
  ],
};

export default Constant;
