import http from "src/utils/http";

export class Processor {
  constructor(user) {
    this.user = user;
  }

  async getLists() {
    let { data } = await http.post(
      `/api/integrations/convertkit/sequences-list/${this.user?.id}`,
      {
        id: this.user?.id,
        organization_id: this.user?.organization?.id,
      }
    );

    return data;
  }
}
