import React from "react";
import Helper from "src/utils/Helper";
import StepperPaidBooking from "src/views/general/individual-booking/schedule/stepper-paid-first";
import StepperPaidBookingMobile from "../stepper-paid-first-mobile";

const PaidBookingChecker = () => {
  const isMobile = Helper.IsTablet();

  if (isMobile) {
    return <StepperPaidBookingMobile />;
  }

  return <StepperPaidBooking />;
};

export default PaidBookingChecker;
