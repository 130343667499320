import {
  Box,
  ButtonBase,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useState } from "react";
import GL from "src/assets/images/linkedin-logo.png";

import { useLinkedIn } from "react-linkedin-login-oauth2";
import Constant from "src/utils/Constant";
import http from "src/utils/http";
import { useNavigate } from "react-router-dom";
import Pendo from "src/utils/Pendo";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const LinkedLogin = () => {
  const redirectURI = `${window.location.origin}/login/auth-linkedin`;
  const [isProcess, setIsProcess] = useState(false);
  const navigate = useNavigate();
  const scopes = "openid profile email";
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation();

  const [isLoginProcess, setIsLoginProcess] = useState(false);

  const { linkedInLogin } = useLinkedIn({
    clientId: Constant.linkedInClientId,
    redirectUri: redirectURI,
    scope: scopes,
    onSuccess: (code) => {
      getLinkedInProfile(code);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const getLinkedInProfile = (linkedInCode) => {
    setIsProcess(true);
    http
      // .post("/api/oauth/registration/linkedin-profile", {
      .post("/api/oauth/registration/linkedin-profile/using-open-id", {
        grant_type: "authorization_code",
        code: linkedInCode,
        client_id: Constant.linkedInClientId,
        client_secret: Constant.linkedInClientSecret,
        redirect_uri: redirectURI,
      })
      .then((response) => {
        if (response.data.message === "success") {
          onLogin(response.data);
        }

        if (response.data.message === "error") {
          showSnackbar(t("login.form.validation.linkedin_error"), "error"); //"Linked in token has an error on reload.");
        }
      })
      .catch((err) => console.log("error message:", err.message))
      .finally(() => setIsProcess(false));
  };

  const onLogin = (linkedInData) => {
    let name = `${linkedInData.profile.firstname} ${linkedInData.profile.lastname}`;
    setIsLoginProcess(true);
    http
      .post("/api/oauth/social/login", {
        email: linkedInData?.profile.email,
        organization: name?.trim()?.toLowerCase(),
      })
      .then(({ data }) => {
        if (data.message === "email-not-found") {
          showSnackbar(t("login.form.validation.email_not_found"), "error");
        }

        if (data.message === "organization-not-found") {
          showSnackbar(
            t("login.form.validation.organization_not_found"),
            "error"
          );
        }

        if (data.message === "success") {
          // initialize pendo //
          Pendo.initialize(data.data);

          if (data.two_factor_enable) {
            navigate(
              `/two-factor/verification?token=${data.access_token}&id=${data?.data?.id}&organization=${data.data.organization.id}&mask=${data.mask}&type=${data.two_factor_type}`
            );
          } else {
            localStorage.setItem(`accessToken`, data.access_token);
            localStorage.setItem(`__loginType`, "coach");
            window.location.href = `/app/v2/calendar`;
          }
        }
      })
      .catch((err) => {
        console.log("error:", err.message);
      })
      .finally(() => setIsLoginProcess(false));
  };

  return (
    <>
      <ButtonBase onClick={linkedInLogin}>
        <Box
          sx={{
            width: "190px",
            height: "40px",
            border: "1px solid #dadce0",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // padding: "10px",
            gap: "10px",
          }}
        >
          <img
            src={GL}
            alt={"LinkedIn Logo"}
            style={{ height: "25px", width: "25px" }}
          />

          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#3c4043",
              lineHeight: "31px",
              fontFamily: "DM Sans",
            }}
          >
            {/* Sign in with LinkedIn */}
            {t("login.form.linkedin_signin")}
          </Typography>
        </Box>
      </ButtonBase>

      <Backdrop
        sx={{
          backgroundColor: "#333970",
          opacity: "0.8 !important",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoginProcess || isProcess}
      >
        <CircularProgress color="inherit" size={25} />
      </Backdrop>
    </>
  );
};

export default LinkedLogin;
