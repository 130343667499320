import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import Logo from "src/components/Logo";
import SideBg from "../SideBg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import SetEmail from "./SetEmail";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";
import Page from "src/components/Page";

const ResetPassword = () => {
  const [category, setCategory] = useState("email"); //email, email-sent
  const { mixpanel } = useContext(Users);
  const { t } = useTranslation();

  const Schema = Yup.object({
    email: Yup.string()
      .email(t("forgot-password.form.error.email-invalid")) //"Enter valid email address.")
      .required(t("forgot-password.form.error.email-required")),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      email: "",
    },
  });

  React.useEffect(() => {
    mixpanel.track("Reset Password Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Reset Password">
      {/* show on small screen as logo */}
      <Box
        sx={(themes) => ({
          height: "80px",
          alignItems: "center",
          [themes.breakpoints.up("md")]: {
            alignItems: "center",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
            pl: "99px",
            display: "none",
          },
          [themes.breakpoints.down("md")]: {
            justifyContent: "flex-start",
            pl: "24px !important",
            display: "flex",
          },
        })}
      >
        <Logo />
      </Box>

      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            pt: {
              md: "120px",
            },
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SetEmail
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            setError={setError}
            category={category}
            setCategory={setCategory}
            getValues={getValues}
          />
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            width: "1100px",
          }}
        >
          <SideBg />
        </Box>
      </Box>
    </Page>
  );
};

export default ResetPassword;
