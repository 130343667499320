import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import UnGuardedTopBar from "../topbar/unguarded";

function UnGuardedBaseLayout({ children }) {
  const [, setMobileNavOpen] = useState(false);

  return (
    <Box>
      <Box>
        <UnGuardedTopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Box>

      <Box>
        <Container maxWidth="md">
          <Box>{children}</Box>
        </Container>
      </Box>
    </Box>
  );
}

export default UnGuardedBaseLayout;
