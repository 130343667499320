/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "src/components/PageNotFound";

import GuardedRoutes from "./guarded";
import OpenRoutes from "./open";
import UnguardedRoutes from "./unguarded";

const renderRoutes = (routes) =>
  routes ? (
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.withSubs ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}

      <Route path={"/page-not-found"} element={<PageNotFound />} />
      <Route path={"*"} element={<Navigate to="/page-not-found" />} />
    </Routes>
  ) : null;

function TreffasRoutes() {
  let routes = [...UnguardedRoutes, ...GuardedRoutes, ...OpenRoutes];
  return renderRoutes(routes);
}

export default TreffasRoutes;
