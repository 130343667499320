import {
  Box,
  Typography,
  useTheme,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "src/components/Logo";
import SideBg from "../SideBg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import * as Yup from "yup";
import TreffasStyle from "src/utils/TreffasStyle";
import { useLocation, useNavigate } from "react-router-dom";
import http from "src/utils/http";
import PS from "src/assets/images/pass-change.png";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewPassword = () => {
  const searchParams = useQuery();
  const resetToken = searchParams.get("q");
  const resetEmail = searchParams.get("email");
  const themes = useTheme();
  const navigate = useNavigate();
  const [isProcess, setIsProcess] = useState(false);
  const { showSnackbar } = useSnackbarContext();

  const [category, setCategory] = useState("update"); // update, success

  const { t } = useTranslation();

  const Schema = Yup.object({
    password: Yup.string()
      .required(t("forgot-password.form.error.new-password.password"))
      .min(6, t("forgot-password.form.error.new-password.password-short")),
    password_confirm: Yup.string()
      .required(t("forgot-password.form.error.new-password.passowrd-confirm"))
      .oneOf(
        [Yup.ref("password"), null],
        t("forgot-password.form.error.new-password.password-notmatch")
      ),
  }).required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      password: "",
      password_confirm: "",
    },
  });

  const onReset = (values) => {
    setIsProcess(true);
    let form = new FormData();
    form.append("reset_code", resetToken);
    form.append("email", resetEmail);
    form.append("password", values.password);
    http
      .post("/api/oauth/reset/password/new-password", form)
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            t("forgot-password.form.success.new-password"),
            "success"
          );
          setCategory("success");
        }

        if (data.message === "verification-error") {
          showSnackbar(
            t("forgot-password.form.error.new-password.link"),
            "error"
          );
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          flexDirection: { lg: "row-reverse" },
        }}
      >
        <Box flexGrow={1}>
          {/* logo */}
          <Box
            sx={(themes) => ({
              height: "80px",
              display: {
                xs: "flex",
                lg: "none",
              },
              alignItems: "center",
              [themes.breakpoints.up("md")]: {
                alignItems: "center",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                pl: "99px",
              },
              [themes.breakpoints.down("md")]: {
                justifyContent: "flex-start",
                pl: "24px !important",
              },
            })}
          >
            <Logo />
          </Box>
          {/* folyeo login */}
          <Box
            sx={{
              mt: "149px",
              pb: "24px",
            }}
          >
            <Box
              sx={{
                width: "406px",
                maxWidth: "406px",
                margin: "auto",
              }}
            >
              {category === "update" && (
                <Box px={"24px"}>
                  <form onSubmit={handleSubmit(onReset)}>
                    <Box>
                      <Typography
                        className="heading-2"
                        sx={{
                          letterSpacing: "-0.01em",
                          color: "#060A2D",
                          textAlign: "center",
                        }}
                      >
                        {/* Create new password */}
                        {t("forgot-password.new-password")}
                      </Typography>
                    </Box>

                    <Box mt={"8px"}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "##838383",
                        }}
                      >
                        {/* Your new password must be different from */}
                        {t("forgot-password.new-password.description.first")}
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "##838383",
                        }}
                      >
                        {/* previous used passwords. */}
                        {t("forgot-password.new-password.description.second")}
                      </Typography>
                    </Box>

                    <Box mt={"48px"}>
                      <Typography sx={TreffasStyle.formLabel}>
                        {/* New Password */}
                        {t("forgot-password.form.label.new-password")}
                      </Typography>

                      <Fc
                        name="password"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder={t(
                              "forgot-password.form.placeholder.new-password"
                            )}
                            type="password"
                            sx={TreffasStyle.formInput(themes)}
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                          />
                        )}
                      />
                    </Box>

                    <Box mt={"24px"}>
                      <Typography sx={TreffasStyle.formLabel}>
                        {/* Confirm New Password */}
                        {t("forgot-password.form.label.confirm-password")}
                      </Typography>

                      <Fc
                        name="password_confirm"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder={t(
                              "forgot-password.form.placeholder.confirm-password"
                            )} //"Enter confirm password"
                            type="password"
                            sx={TreffasStyle.formInput(themes)}
                            error={Boolean(errors.password_confirm)}
                            helperText={errors.password_confirm?.message}
                          />
                        )}
                      />
                    </Box>

                    <Box sx={{ mt: "30px" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                          textTransform: "inherit",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "10px",
                          lineHeight: "31px",
                          fontWeight: 400,
                          height: "48px",
                        }}
                        disabled={isProcess}
                        startIcon={
                          isProcess && (
                            <CircularProgress color="inherit" size={15} />
                          )
                        }
                      >
                        {/* Reset Password */}
                        {t("forgot-password.form.button.reset")}
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}

              {category === "success" && (
                <Box px={"24px"}>
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      alt="folyeo logo"
                      src={PS}
                      style={{
                        width: "230px",
                        height: "215px",
                      }}
                    />
                  </Box>

                  <Box mt={"12px"}>
                    <Typography
                      className="heading-2"
                      sx={{
                        textAlign: "center",
                        letterSpacing: "-0.01em",
                        color: "#060A2D",
                      }}
                    >
                      {/* Password Changed! */}
                      {t("forgot-password.new-password.success")}
                    </Typography>
                  </Box>

                  <Box mt={"8px"}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#838383",
                      }}
                    >
                      {/* Your password has been changed successfully. */}
                      {t(
                        "forgot-password.new-password.success.description.first"
                      )}
                    </Typography>
                  </Box>

                  <Box mt={"32px"}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        textTransform: "inherit",
                        borderRadius: "8px",
                        fontSize: "14px",
                        padding: "10px",
                        lineHeight: "31px",
                        fontWeight: 400,
                        height: "48px",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      {/* Back to Log In */}
                      {t("forgot-password.form.button.back-to-login")}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <SideBg />
      </Box>
    </>
  );
};

export default NewPassword;
