import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Button,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TreffasStyle from "src/utils/TreffasStyle";
import { Controller as Fc } from "react-hook-form";
import SM from "src/assets/images/sent-mail.png";

import http from "src/utils/http";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const SetEmail = ({
  errors,
  control,
  handleSubmit,
  setError,
  category,
  setCategory,
  getValues,
}) => {
  const themes = useTheme();
  const [isProcess, setIsProcess] = useState(false);
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const { showSnackbar } = useSnackbarContext();

  const { t } = useTranslation();

  const onReset = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/reset/password/sent-link", {
        email: values.email,
        base_url: baseUrl,
      })
      .then(({ data }) => {
        if (data.message === "email-error") {
          setError("email", {
            type: "requried",
            message: t("forgot-password.form.error.email-notfound"), //"Email address not found.",
          });
        }

        if (data.message === "success") {
          showSnackbar(t("forgot-password.form.success"), "success");
          setCategory("email-sent");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(90vh - 120px)",
      }}
    >
      {category === "email" && (
        <form onSubmit={handleSubmit(onReset)}>
          <Box px={"24px"}>
            <Box>
              <Typography
                className="heading-2"
                sx={{
                  fontWeight: 400,
                  fontSize: "32px",
                  lineHeight: "46px",
                  textAlign: "center",
                  letterSpacing: "-0.01em",
                  color: "#060A2D",
                  fontFamily: "var(--font-alike)",
                }}
              >
                {t("forgot-password")}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#838383",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("forgot-password.description")}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: "40px",
              }}
            >
              <Typography
                sx={{
                  ...TreffasStyle.formLabel,
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("forgot-password.form.label.email")}
              </Typography>

              <Fc
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="e.g. bettycooper@gmail.com"
                    type="text"
                    sx={TreffasStyle.formInput(themes)}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>

            <Box sx={{ mt: "32px" }}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  textTransform: "inherit",
                  borderRadius: "8px",
                  fontSize: "14px",
                  padding: "10px",
                  lineHeight: "31px",
                  fontWeight: 400,
                  height: "48px",
                  backgroundColor: "var(--bg-black)",
                  fontFamily: "var(--font-work-sans)",
                }}
                disabled={isProcess}
                startIcon={
                  isProcess && <CircularProgress color="inherit" size={15} />
                }
              >
                {/* Send instructions */}
                {t("forgot-password.form.button.sent-instruction")}
              </Button>
            </Box>

            <Box sx={{ mt: "8px" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  textDecorationLine: "underline",
                  color: "var(--color-orange)",
                  cursor: "pointer",
                  fontFamily: "var(--font-work-sans)",
                }}
                onClick={() => navigate("/login")}
              >
                {/* Back to Log in */}
                {t("forgot-password.form.button.back-to-login")}
              </Typography>
            </Box>
          </Box>
        </form>
      )}

      {category === "email-sent" && (
        <Box>
          <Box textAlign={"center"}>
            <img
              alt={"folyeo logo"}
              src={SM}
              style={{
                width: "199.08px",
                height: "168px",
              }}
            />
          </Box>

          <Box mt={"31px"}>
            <Typography
              className="heading-2"
              sx={{
                fontWeight: 400,
                fontSize: "32px",
                lineHeight: "46px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                color: "#060A2D",
                fontFamily: "var(--font-alike)",
              }}
            >
              {t("forgot-password.success")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                textAlign: "center",
                fontFamily: "var(--font-work-sans)",
              }}
            >
              We have sent a password recover instructions <br /> to your email{" "}
              <span style={{ color: "var(--color-orange)" }}>
                {" "}
                {getValues("email")}{" "}
              </span>
              .
            </Typography>
          </Box>

          {/* <Box sx={{ mt: "32px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                textTransform: "inherit",
                borderRadius: "8px",
                fontSize: "14px",
                padding: "10px",
                lineHeight: "31px",
                fontWeight: 400,
                height: "48px",
                backgroundColor: "var(--bg-black)",
              }}
              onClick={() => (window.location = `mailto:${getValues("email")}`)}
            > 
              {t("forgot-password.form.button.open-email")}
            </Button>
          </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default SetEmail;
