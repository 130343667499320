import { Box } from "@mui/system";
import React, { useState } from "react";
import ClientPortalTopBar from "../topbar/client-portal";

function ClientPortalBaseLayout({ children }) {
  const [, setMobileNavOpen] = useState(false);

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 0px)",
        backgroundColor: { xs: "#F7F7F7", md: "#fff" },
      }}
    >
      <Box>
        <ClientPortalTopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Box>

      <Box>{children}</Box>
    </Box>
  );
}

export default ClientPortalBaseLayout;
