import React from "react";

const IconClock = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M26 12.458c-8.676 0-15.708 7.033-15.708 15.709 0 8.675 7.032 15.708 15.708 15.708 8.675 0 15.708-7.033 15.708-15.708 0-8.676-7.033-15.709-15.708-15.709zM7.042 28.167C7.042 17.697 15.53 9.208 26 9.208c10.47 0 18.958 8.488 18.958 18.959 0 10.47-8.488 18.958-18.958 18.958-10.47 0-18.958-8.488-18.958-18.958z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M26 15.708c.897 0 1.625.728 1.625 1.625v9.933l5.736 3.585a1.625 1.625 0 11-1.722 2.756l-6.5-4.062a1.625 1.625 0 01-.764-1.378V17.333c0-.897.727-1.625 1.625-1.625zM14.149 7.518a1.625 1.625 0 010 2.298l-5.417 5.416a1.625 1.625 0 01-2.298-2.298l5.417-5.416a1.625 1.625 0 012.298 0zM37.85 7.518a1.625 1.625 0 000 2.298l5.417 5.416a1.625 1.625 0 002.299-2.298l-5.417-5.416a1.625 1.625 0 00-2.298 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconClock;
