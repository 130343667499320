import { Box, Typography, Checkbox, FormHelperText } from "@mui/material";
import React from "react";
import { Square } from "react-feather";
import { useTranslation } from "react-i18next";
import IconCheckbox from "src/components/svg-icons/IconCheckbox";

const Agreement = ({ errors, setValue }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          mt: "32px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            name="terms"
            icon={<Square size={20} />}
            checkedIcon={<IconCheckbox />}
            sx={{
              ...(Boolean(errors?.terms) && {
                color: "red",
              }),
              padding: "0px !important",
            }}
            size={"small"}
            onClick={(e) => setValue("terms", e.target.checked)}
          />
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            {t("individual.booking.agreement")}
            <Typography
              variant="caption"
              sx={{
                color: "var(--color-orange)",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {" "}
              {t("individual.booking.agreement.terms")}
            </Typography>
            {` & `}
            <Typography
              variant="caption"
              sx={{
                color: "var(--color-orange)",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {t("individual.booking.agreement.policy")}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <Box>
        {Boolean(errors?.terms) && (
          <Box sx={{ ml: "30px" }}>
            <FormHelperText error> {errors?.terms.message} </FormHelperText>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          mt: "8px",
        }}
      >
        <Checkbox
          name="new_letter"
          icon={<Square size={20} />}
          checkedIcon={<IconCheckbox />}
          sx={{
            padding: "0px !important",
          }}
        />

        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
          }}
        >
          {t("individual.booking.agreement.news_letter")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Agreement;
