import { PowerSettingsNew, Share } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { Code, Copy, Eye, X } from "react-feather";
import TreffasTextField from "src/components/TreffasTextField";
import TreffasStyle from "src/utils/TreffasStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const Booking = ({ bookingURL, close, onChangeCategory, type = "booking" }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarContext();

  return (
    <Box>
      <Box
        sx={{
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={() => close()}>
            <X size={20} />
          </IconButton>
        </Box>

        <Box sx={{ mt: "4px" }}>
          <Typography
            className="heading-3"
            sx={{
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {type === "website"
              ? `Share your Website URL`
              : t("package.label.booking.share_your_booking_url")}
          </Typography>
        </Box>

        <Box sx={{ mt: "8px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              textAlign: "center",
              color: "#6E757C",
            }}
          >
            {type === "website"
              ? `To share your website link in emails and chats, just copy the link below and paste it.`
              : t("package.label.booking.share_your_booking_url.description")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          px: { xs: "20px", md: "40px" },
          mt: "32px",
        }}
      >
        <Box>
          <TreffasTextField
            InputProps={{ readOnly: true }}
            value={bookingURL}
            fullWidth
            sx={{
              ...TreffasStyle.formInputLarge("input"),
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px !important",
                height: "46px !important",
                backgroundColor: "#F4F5F6",
              },
              "& .MuiInputBase-input": {
                fontWeight: 500,
                fontSize: "15px",
                color: "#353A3F",
              },
            }}
          />
        </Box>

        <Box sx={{ mt: "8px" }}>
          <CopyToClipboard
            text={bookingURL}
            onCopy={() =>
              showSnackbar(
                t("individual.booking.label.copy.clipboard"),
                "success"
              )
            }
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                minHeight: "44px",
                fontWeight: 700,
                fontSize: "15px",
                lineHeight: "20px",
                borderRadius: "8px",
                ...TreffasStyle.buttonHover,
              }}
              startIcon={<Copy />}
            >
              {type === "website"
                ? `Copy My Folyeo Link`
                : t("package.label.booking.copy_url")}
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>

      <Box
        sx={{
          p: "24px 40px 32px",
          backgroundColor: "#F4F5F6",
          mt: "32px",
          display: "flex",
          gap: { xs: "6px", sm: "33px" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF",
              width: { xs: "50px", sm: "60px" },
              height: { xs: "50px", sm: "60px" },
              color: "#000",
              ...TreffasStyle.buttonHover,
            }}
            onClick={() => {
              window.open(bookingURL, "_blank");
            }}
          >
            <Eye />
          </IconButton>
          <Typography
            sx={{
              color: "#353A3F",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "normal",
              textAlign: "center",
              mt: "8px",
              display: { xs: "none", md: "inherit" },
            }}
          >
            Preview Link
          </Typography>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF",
              width: { xs: "50px", sm: "60px" },
              height: { xs: "50px", sm: "60px" },
              color: "#000",
              ...TreffasStyle.buttonHover,
            }}
            onClick={() => onChangeCategory("embed")}
          >
            <Code />
          </IconButton>
          <Typography
            sx={{
              color: "#353A3F",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "normal",
              textAlign: "center",
              mt: "8px",

              display: { xs: "none", md: "inherit" },
            }}
          >
            Embed
          </Typography>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF",
              width: { xs: "50px", sm: "60px" },
              height: { xs: "50px", sm: "60px" },
              color: "#000",
              ...TreffasStyle.buttonHover,
            }}
            onClick={() => onChangeCategory("share")}
          >
            <Share />
          </IconButton>

          <Typography
            sx={{
              color: "#353A3F",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "normal",
              textAlign: "center",
              mt: "8px",
              display: { xs: "none", md: "inherit" },
            }}
          >
            Social
          </Typography>
        </Box>

        <Box>
          <IconButton
            sx={{
              border: "1px solid #DFE2E6",
              backgroundColor: "#FFFFFF",
              width: { xs: "50px", sm: "60px" },
              height: { xs: "50px", sm: "60px" },
              color: "#000",
              "& svg path": {
                fill: "#000", // Change SVG fill color to white on hover
              },
              "&:hover": {
                color: "white",
                backgroundColor: "var(--bg-black)",
                borderColor: "var(--color-black)",
                "& svg path": {
                  fill: "#FFF", // Change SVG fill color to white on hover
                },
              },
            }}
            onClick={() => onChangeCategory("button")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path d="M11.778 9.64551C11.778 9.44246 11.5675 9.30926 11.3911 9.40978C9.79713 10.318 8.7224 12.0328 8.7224 13.9986C8.7224 16.9134 11.0853 19.2764 14.0002 19.2764C16.915 19.2764 19.278 16.9134 19.278 13.9986C19.278 12.0328 18.2032 10.318 16.6093 9.40978C16.4329 9.30926 16.2224 9.44246 16.2224 9.64551V11.0166C16.2224 11.1021 16.2622 11.1823 16.3275 11.2374C17.1126 11.8998 17.6113 12.891 17.6113 13.9986C17.6113 15.993 15.9945 17.6097 14.0002 17.6097C12.0058 17.6097 10.3891 15.993 10.3891 13.9986C10.3891 12.891 10.8877 11.8998 11.6728 11.2374C11.7382 11.1823 11.778 11.1021 11.778 11.0166V9.64551Z" />
              <path d="M14.8335 8.44303C14.8335 7.9828 14.4604 7.6097 14.0002 7.6097C13.5399 7.6097 13.1668 7.9828 13.1668 8.44303V13.9986C13.1668 14.4588 13.5399 14.8319 14.0002 14.8319C14.4604 14.8319 14.8335 14.4588 14.8335 13.9986V8.44303Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.27795 13.9986C4.27795 8.62915 8.63074 4.27637 14.0002 4.27637C19.3696 4.27637 23.7224 8.62915 23.7224 13.9986C23.7224 19.368 19.3696 23.7208 14.0002 23.7208C8.63074 23.7208 4.27795 19.368 4.27795 13.9986ZM14.0002 5.94303C9.55122 5.94303 5.94462 9.54963 5.94462 13.9986C5.94462 18.4476 9.55122 22.0541 14.0002 22.0541C18.4491 22.0541 22.0557 18.4476 22.0557 13.9986C22.0557 9.54963 18.4491 5.94303 14.0002 5.94303Z"
              />
            </svg>
          </IconButton>

          <Typography
            sx={{
              color: "#353A3F",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "normal",
              textAlign: "center",
              mt: "8px",
              display: { xs: "none", md: "inherit" },
            }}
          >
            Button Link
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Booking;
