import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import React, { Fragment } from "react";
import { Controller as Fc } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StepperGuide from "src/components/StepperGuide";
import TreffasStyle from "src/utils/TreffasStyle";
import Agreement from "../../individual-booking/schedule/agreement";
import { Info } from "react-feather";
import TreffasTextField from "src/components/TreffasTextField";

const Information = ({
  errors,
  control,
  selectedOffer,
  clearErrors,
  stepperGuideLength,
  isProcess,
  submitButtonRef,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        pt: "58px",
      }}
    >
      <Box>
        <Grid container spacing={"20px"}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mb: "5px",
              }}
            >
              <Box>
                <Typography
                  className="heading-6"
                  sx={{
                    alignItems: "center",
                    color: "#060A2D",
                  }}
                >
                  {t(
                    "individual.booking.schedule.choose_schedule.client_details"
                  )}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#878787",
                  }}
                >
                  {t("individual.booking.schedule.choose_schedule.final_step")}
                </Typography>
              </Box>

              <Box>
                <StepperGuide
                  length={stepperGuideLength}
                  activeLength={[1, 2]}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.firstname")}{" "}
              </Typography>

              <Fc
                name="firstname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Alex"
                    type="text"
                    sx={(themes) => ({
                      ...TreffasStyle.formInput(themes),
                      ...TreffasStyle.formInputLarge("input"),
                    })}
                    error={Boolean(errors.firstname)}
                    helperText={errors.firstname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.lastname")}{" "}
              </Typography>

              <Fc
                name="lastname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Smith"
                    type="text"
                    sx={(themes) => ({
                      ...TreffasStyle.formInput(themes),
                      ...TreffasStyle.formInputLarge("input"),
                    })}
                    error={Boolean(errors.lastname)}
                    helperText={errors.lastname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {t("booking.label.client_information.phonenumber")}
              </Typography>

              <Fc
                name="mobile"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="+63 917000 0000"
                    type="text"
                    sx={(themes) => ({
                      ...TreffasStyle.formInput(themes),
                      ...TreffasStyle.formInputLarge("input"),
                    })}
                    error={Boolean(errors.mobile)}
                    helperText={errors.mobile?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.email")}{" "}
              </Typography>

              <Fc
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="alexsmith@gmail.com"
                    type="text"
                    sx={(themes) => ({
                      ...TreffasStyle.formInput(themes),
                      ...TreffasStyle.formInputLarge("input"),
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {t("individual.booking.schedule.reschedule.reason")}
              </Typography>

              <Fc
                name="reason"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder={t(
                      "individual.booking.schedule.placeholder.reason_for_change"
                    )}
                    type="text"
                    sx={(themes) => ({
                      ...TreffasStyle.formInput(themes),
                      ...TreffasStyle.formInputLarge("textarea"),
                    })}
                    error={Boolean(errors.reason)}
                    helperText={errors.reason?.message}
                    multiline
                    minRows={3}
                  />
                )}
              />
            </Box>
          </Grid>

          {/* additional fields */}
          {selectedOffer?.fields?.length > 0 && (
            <Grid item xs={12}>
              <Box mt={"12px"}>
                <Typography
                  className="heading-6"
                  sx={{
                    color: "#060A2D",
                  }}
                >
                  {t("booking.label.additional_information")}
                </Typography>
              </Box>
            </Grid>
          )}

          {selectedOffer?.fields.map((extra, key) => (
            <Fragment key={key}>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    sx={{
                      ...TreffasStyle.formLabel,
                      textTransform: "capitalize",
                    }}
                  >
                    {extra.name?.toLowerCase()}

                    {extra?.options?.has_description && (
                      <Typography
                        variant={"caption"}
                        sx={{ cursor: "help", ml: "10px" }}
                      >
                        <Tooltip arrow title={extra?.options?.description}>
                          <Info size={15} color={"#6E757C"} />
                        </Tooltip>
                      </Typography>
                    )}
                  </Typography>

                  {extra.type === "option" ? (
                    <Fc
                      name={extra.name?.toLowerCase()}
                      control={control}
                      rules={{
                        required: Boolean(parseInt(extra.required)),
                      }}
                      render={({ field }) => {
                        return (
                          <TreffasTextField
                            {...field}
                            placeholder={`Please select your answer...`}
                            fullWidth
                            type={"text"}
                            variant="outlined"
                            sx={{
                              ...TreffasStyle.formInputLarge("input"),
                              "& .MuiNativeSelect-select": {
                                textTransform: "capitalize",
                              },
                            }}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={Boolean(errors[extra.name?.toLowerCase()])}
                            helperText={
                              errors[extra.name?.toLowerCase()]?.message
                            }
                            value={field.value ?? ""}
                          >
                            <option value={""}> Select </option>
                            {JSON.parse(extra.values).map((option, key) => (
                              <option
                                key={key}
                                values={option?.value ?? option}
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {option?.value ?? option}
                              </option>
                            ))}
                          </TreffasTextField>
                        );
                      }}
                    />
                  ) : (
                    <Fc
                      name={extra.name?.toLowerCase()}
                      control={control}
                      rules={{
                        required: Boolean(parseInt(extra.required)),
                      }}
                      render={({ field }) => {
                        return (
                          <TreffasTextField
                            {...field}
                            value={field.value ?? ""}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(`${extra.name?.toLowerCase()}`);
                            }}
                            placeholder={`Please write your answer...`}
                            fullWidth
                            type={extra.type === "email" ? "email" : "text"}
                            variant="outlined"
                            sx={TreffasStyle.formInputLarge(
                              extra.type === "paragraph" ? "textarea" : "input"
                            )}
                            error={Boolean(errors[extra.name?.toLowerCase()])}
                            helperText={
                              errors[extra.name?.toLowerCase()]?.message
                            }
                            multiline={
                              extra.type === "paragraph" ? true : false
                            }
                            minRows={extra.type === "paragraph" ? 3 : 0}
                          />
                        );
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mt: "32px" }}>
        <Box>
          <Agreement errors={errors} setValue={setValue} />
        </Box>

        <Box
          sx={{
            mt: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <Button
            variant={"contained"}
            sx={{
              height: "48px",
              borderRadius: "12px",
              fontSize: "14px",
              ...TreffasStyle.buttonHover,
            }}
            onClick={() => submitButtonRef.current.click()}
            disabled={isProcess}
            startIcon={
              isProcess && <CircularProgress size={15} color="inherit" />
            }
          >
            {t(
              "individual.booking.schedule.choose_schedule.button.update_schedule"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Information;
