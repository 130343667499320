import React from "react";

const IconConfirm = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="88"
      fill="none"
      viewBox="0 0 89 88"
    >
      <path
        fill="#38D600"
        fillRule="evenodd"
        d="M44.5 82.532a38.285 38.285 0 100-76.57 38.285 38.285 0 000 76.57zm0 5.47a43.755 43.755 0 100-87.51 43.755 43.755 0 000 87.51z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#38D600"
        fillRule="evenodd"
        d="M60.742 27.678a4.102 4.102 0 015.858 5.743L44.766 60.713a4.101 4.101 0 01-5.907.11L24.392 46.35a4.103 4.103 0 115.798-5.797L41.643 52l18.995-24.202a1.3 1.3 0 01.11-.12h-.006z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconConfirm;
