import React from "react";
import { Navigate } from "react-router-dom";
import { Clients } from "src/context";

function ClientPortalGuestGuard({ children }) {
  const { client } = React.useContext(Clients);

  if (client) {
    return <Navigate to="/client/appointments" />;
  }

  return children;
}

export default ClientPortalGuestGuard;
