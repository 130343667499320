import { Container, Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as Yup from "yup";
import http from "src/utils/http";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PageNotFound from "src/components/PageNotFound";
import momentTimezone from "moment-timezone";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import Page from "src/components/Page";
import Helper from "src/utils/Helper";
import Timezone from "src/utils/Timezone";
import RenderBooking from "./RenderBooking";
import BookingHeader from "../../individual-booking/schedule/booking-header";
import { useSnackbarContext } from "src/context/SnackbarContext";
import RescheduleTimeLimitDialog from "../RescheduleTimeLimitDialog";

const steps = [
  {
    key: 1,
    label: "Choose Date and Time",
    code: "datetime",
  },

  {
    key: 2,
    label: "Client Information",
    code: "information",
  },
];

const IndividualBookingReschedule = () => {
  const { session_slug, appointment_id } = useParams();

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const { userId, offerId, organizationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOffer, setSelectedOffer] = React.useState(null);
  const { t } = useTranslation();
  /** container of selected date match in block date **/
  // subtract current date with 1 days to trigger auto onchange on date picker //
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date(), "YYYY/MM/DD").subtract(1, "day")
  );
  const [selectedSessionTime, setSessionSelectedTime] = useState({});
  const [blockTimeOnSelectedDate, setBlockTimeOnSelectedDate] = useState({});
  const [sessionList, setSessionList] = useState([]);

  const [allUpcommingAppt, setAllUpcommingAppt] = useState([]);

  const [isProcess, setIsProcess] = useState(false);
  // initialize default timezone by ip address
  let zone = momentTimezone.tz.guess();

  const [selectedTimezone, setSelectedTimezone] = useState(zone);

  const eventColor = "#ff4f00";

  const [coachDetails, setCoachDetails] = useState(null);

  const [appForThisOffer, setAppForThisOffer] = useState([]);

  const { showSnackbar } = useSnackbarContext();

  const nextButtonRef = useRef(null);

  const [overFlowTimeToRender, setOverFlowTimeToRender] = useState([]);
  const [underFlowTimeToRender, setUnderFlowTimeToRender] = useState([]);

  const [isUseTimeRecord, setIsUseTimeRecord] = useState(false);
  const [timeRecords, setTimeRecords] = useState({
    listofOverFlowDates: [],
    listofUnderFlowDates: [],
    listofOverflow: {},
    listofUnderflow: {},
    listOfAvailableDates: {},
  });

  const submitButtonRef = useRef();
  const navigate = useNavigate();

  const [bookingInfo, setBookingInfo] = useState(null);

  const Schema = Yup.object({
    firstname: Yup.string().required(t("booking.error.required.firstname")),
    lastname: Yup.string().required(t("booking.error.required.lastname")),
    // mobile: Yup.string().required(t("booking.error.required.mobile")),
    email: Yup.string()
      .email(t("booking.error.invalid.email"))
      .required(t("booking.error.required.email")),
    reason: Yup.string().required("Reason is a required field."),
    terms: Yup.bool() // use bool instead of boolean
      .oneOf(
        [true],
        t("individual.booking.schedule.choose_schedule.accept_policy")
      ),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      organization_id: organizationId,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      notes: "",
      topic: "",
      address: "",
      reason: "",
      other_infos: "",
      price: 0,
      terms: false,
      new_letter: true,
    },
  });

  const getAppointmentDetailsBySlug = useCallback(() => {
    http
      .get(
        "/api/general/individual/reschedule-booking/get-appointment-details-by-slug",
        {
          params: {
            session_slug: session_slug,
            appointment_id: appointment_id,
          },
        }
      )
      .then(({ data }) => {
        if (data.message === "success") {
          let offer = data.session_details.appointment.offer;
          let coach_info = data.session_details.appointment.coach_info;
          let client = data.session_details.appointment.client;
          let appointment = data.session_details.appointment;

          let current_appointments =
            data.session_details.current_appointments.length > 0
              ? data.session_details.current_appointments.filter(
                  (x) => x.id !== appointment.id
                ) // remove current appointment id to enable button in reschedule
              : [];

          let booking = JSON.parse(appointment?.custom_booking);
          if (booking?.timezone_utc) {
            setSelectedTimezone(booking?.timezone_utc);
          }
          setSelectedOffer(offer);
          setCoachDetails(coach_info);
          setAppForThisOffer(current_appointments);
          setBookingInfo(appointment);
          setValue("price", offer.price);
          setValue("topic", offer.name);
          setValue("firstname", client.firstname);
          setValue("lastname", client.lastname);
          setValue("mobile", client.phone ?? "");
          setValue("email", client.email);

          let xx = offer?.fields;

          if (xx.length > 0) {
            appointment?.client_fields?.forEach((el) => {
              let field = xx.filter((x) => x.id === el.field_id);
              setValue(field?.[0]?.name?.toLowerCase(), el.value);
            });
          }
        } else {
          showSnackbar(
            `Appointment has been ${data.session_details.appointment.status}`,
            "error"
          );
          navigate("/404");
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, organizationId, userId]);

  const validateOnNext = async () => {
    let error = [];
    if (activeStep === 0) {
      if (Object.keys(selectedSessionTime).length === 0) {
        error = "date and time is required";
      }
    }

    if (activeStep === 1) {
      let customError = [];

      let info = await trigger([
        "firstname",
        "lastname",
        "email",
        "mobile",
        "topic",
      ]);

      if (!info) {
        error = "infor-has-an-error";
        customError = "infor-has-an-error";
      }

      if (selectedOffer?.fields.length > 0) {
        let checkHasRequired = selectedOffer?.fields.filter((x) =>
          Boolean(x.required)
        );

        if (checkHasRequired.length > 0) {
          checkHasRequired.forEach((xfield) => {
            let field = xfield.name?.toLowerCase();

            if (!Boolean(getValues(`${field}`))) {
              setError(field, {
                required: true,
                message: t("booking.error.required.dynamic_field", { field }),
              });

              error = `${field}-has-an-error`;
              customError = `${field}-has-an-error`;
            }
          });
        }
      }

      if (customError.length === 0) {
        // submit forms directly for free package
        // ignore next step and submit form if package is free
        const payments = selectedOffer?.price ?? 0;
        if (Number(payments) < 1) {
          error = "custom-error-to-prevent-next-step";
          submitButtonRef.current.click();
        } else {
          // redirect to payment page
          setActiveStep(3);
          return;
        }
      }
    }

    if (activeStep === 2) {
      let customError = [];

      if (
        !surveyOption?.[0]?.type ||
        surveyOption?.[0]?.type === "view-first-calendar"
      ) {
        if (selectedOffer?.survey.length > 0) {
          selectedOffer?.survey.forEach((survey) => {
            if (survey?.questionslist.length > 0) {
              survey?.questionslist.forEach((item) => {
                let field = "";
                if (item.type === "question-short-answer") {
                  field = `answer[_${item.id}][_${item.choices[0].id}`;
                }

                if (item.type === "question-comment-essay") {
                  field = `answer[_${item.id}][_${item.choices[0].id}`;
                }

                if (item.type === "question-one-answer") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-multiple-answer") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-true-false") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-yes-no") {
                  field = `answer[_${item.id}]`;
                }

                let current_value = getValues(field) ?? "";

                if (Boolean(item.is_required) && !current_value) {
                  error = `question-${field}-is-required`;
                  customError = `question-${field}-is-required`;
                  setError(field, {
                    required: true,
                    message: t("booking.error.required.question"),
                  });
                }
              });
            }
          });
        }
      }

      if (customError.length === 0) {
        // submit forms directly for free package
        // ignore next step and submit form if package is free
        const payments = selectedOffer?.price ?? 0;
        if (parseInt(payments) < 1) {
          error = "custom-error-to-prevent-next-step";
          submitButtonRef.current.click();
        }
      }
    }

    if (error.length === 0) {
      clearErrors();
      handleNext();
    } else {
      console.log("error:", error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/a/${coachDetails?.onboarding?.personal_link}`);
      return;
    }

    if (activeStep === 3) {
      if (selectedOffer?.survey.length > 0 && Number(selectedOffer.price) > 0) {
        setActiveStep(2);
        return;
      }

      if (Number(selectedOffer.price) > 0) {
        setActiveStep(1);
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (values) => {
    let bookingTimeUTCFormat = moment
      .tz(selectedSessionTime.start, "YYYY/MM/DD HH:mm", selectedTimezone)
      .tz(Helper.zeroTimezone);

    const currentBookingData = JSON.parse(bookingInfo.custom_booking);

    let data = {
      ...values,
      base_url: window.location.origin,
      duration: selectedOffer?.duration,
      date: moment(new Date(selectedSessionTime.start), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time: moment(new Date(selectedSessionTime.start), "hh:mm A").format(
        "hh:mm A"
      ),
      date_end: moment(new Date(selectedSessionTime.end), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time_end: moment(new Date(selectedSessionTime.end), "hh:mm A").format(
        "hh:mm A"
      ),
      sessions: JSON.stringify(selectedOffer?.custom_options?.sessions),
      description: selectedOffer?.description,
      phone: values.mobile,
      offer_fields: JSON.stringify(selectedOffer?.fields),
      user_id: selectedOffer?.user_id,
      organization_id: selectedOffer?.organization_id,
      offer_id: selectedOffer?.id,
      timezone: Timezone.getLabel(selectedTimezone),
      timezone_utc: selectedTimezone,
      utc_bookingdate: bookingTimeUTCFormat.format("YYYY/MM/DD HH:mm"),
      coach_bookingdate: bookingTimeUTCFormat
        .clone()
        .tz(coachDetails?.timezone_utc)
        .format("YYYY-MM-DD HH:mm"),
      coach_utc_timezone: coachDetails?.timezone_utc,
      offer_amount: selectedOffer?.price,
      offer_name: selectedOffer?.name,
      topic: selectedOffer?.name,
      number_of_sessions:
        selectedOffer?.custom_options?.number_of_sessions ?? 1,

      google_calendar_event_id:
        currentBookingData?.google_calendar_config?.event_id ?? "",
      msoutlook_calendar_event_id:
        currentBookingData?.ms_outlook_config?.event_id ?? "",
      current_fields_values: JSON.stringify(bookingInfo?.client_fields),
      appointment_id: appointment_id,
      color: eventColor,
      meeting_type:
        selectedOffer?.custom_options?.meeting_type ?? "virtual-meeting",
      currency: selectedOffer?.custom_options?.currency ?? "USD",
    };

    setIsProcess(true);

    http
      .post(
        "/api/general/individual/reschedule-booking/save-updated-booking",
        data
      )
      .then((response) => {
        if (response.data.message === "success") {
          setActiveStep(4);
        }
      })
      .catch((err) => console.log("error indi create booking:", err))
      .finally(() => setIsProcess(false));
  };

  useEffect(() => {
    getAppointmentDetailsBySlug();
  }, [getAppointmentDetailsBySlug]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (selectedOffer === null) {
    return <PageNotFound />;
  }

  const user_settings = coachDetails?.settings ? coachDetails?.settings : null;
  const langauge_settings = user_settings?.langauge_settings;
  const appointment_settings = user_settings?.appointment_settings;

  const userDateFormat = langauge_settings?.date_format ?? "MM/DD/YYYY";
  const userTimeFormat = langauge_settings?.time_format ?? "hh:mm A";

  const userAdvanceBookingNotice =
    appointment_settings?.advance_booking_notice ?? 3; // months

  const userMinimumBookingNotice =
    appointment_settings?.minimum_booking_notice ?? 0; // hours

  const userReschedulableNotice = appointment_settings?.reschedulable_notice
    ? appointment_settings?.reschedulable_notice * 60
    : 120; // 24hours

  const calendarMaxDate = moment().add(userAdvanceBookingNotice, "M");

  const packagePrice = selectedOffer?.price ?? 0;

  let surveyOption = selectedOffer?.custom_options?.survey
    ? JSON.parse(selectedOffer.custom_options.survey)
    : null;

  const customProps = {
    handleSubmit,
    onSubmit,
    getValues,
    maxSteps,
    handleBack,
    validateOnNext,
    isProcess,
    packagePrice,
    errors,
    control,
    setSelectedTimezone,
    selectedTimezone,
    sessionList,
    selectedSessionTime,
    setSessionSelectedTime,
    setSessionList,
    selectedOffer,
    setBlockTimeOnSelectedDate,
    appForThisOffer,
    blockTimeOnSelectedDate,
    selectedDate,
    setSelectedDate,
    setError,
    clearErrors,
    nextButtonRef,
    calendarMaxDate,
    userDateFormat,
    userTimeFormat,
    userMinimumBookingNotice,
    activeStep,
    submitButtonRef,
    bookingInfo,
    setValue,
    coachDetails,

    overFlowTimeToRender,
    setOverFlowTimeToRender,
    underFlowTimeToRender,
    setUnderFlowTimeToRender,
    isUseTimeRecord,
    setIsUseTimeRecord,
    timeRecords,
    setTimeRecords,
    allUpcommingAppt,
    setAllUpcommingAppt,
  };

  const booking_info = JSON.parse(bookingInfo.custom_booking);

  const bookingdate = Helper.convertTimeToClientTimezone(
    bookingInfo.date,
    booking_info.timezone_utc
  );

  let duration = moment.duration(moment(bookingdate).diff(moment(new Date())));
  let minutes_remaining = Math.round(duration.asMinutes());

  return (
    <Page title={`${selectedOffer?.name} - ${coachDetails?.name}`}>
      <Box
        sx={{
          backgroundColor: activeStep === 4 ? "#FFFFFF" : "#FAFAFA",
          minHeight:
            activeStep === 4 ? "calc(100vh - 160px)" : "calc(100vh - 1px)",
          pb: "30px",
        }}
      >
        {activeStep !== 4 && (
          <>
            <BookingHeader
              selectedOffer={selectedOffer}
              selectedSessionTime={selectedSessionTime}
              selectedDate={selectedDate}
              handleBack={handleBack}
              showBackButton={activeStep === 0 ? false : true}
              coachDetails={coachDetails}
            />

            <Box
              sx={{
                display: "flex",
                padding: "10px",
                gap: { xs: "10px", md: "24px" },
                backgroundColor: "#F2F2F2",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "flex-start", md: "center" },
                alignItems: { xs: "flex-start", md: "center" },
              }}
            >
              <Box
                sx={{
                  fontFamily: "var(--base-font-neuehaasmedium) !important",
                  fontSize: "9px",
                  lineHeight: "10px",
                  letterSpacing: " 0.07em",
                  color: "#6F6D77",
                }}
              >
                {t("individual.booking.schedule.reschedule")}
              </Box>

              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "17px",
                  color: "#222128",
                }}
              >
                {bookingdate.format("dddd, DD MMMM YYYY")}
                {` `}
                {bookingdate.format("hh:mm a")} - {` `}
                {bookingdate
                  .add(bookingInfo.offer.duration, "minutes")
                  .format("hh:mm a")}
              </Box>
            </Box>
          </>
        )}
        <Box>
          <Container
            maxWidth={"ntc"}
            sx={{
              px: { xs: "30px", md: "10px !important" },
            }}
          >
            <RenderBooking {...customProps} hideThisSurvey={true} />
          </Container>
        </Box>
      </Box>

      {/* <Box>
        <PowerdBy colored={activeStep === 4 ? false : true} />
      </Box> */}

      {/* show reschedule time limit  */}
      {parseInt(userReschedulableNotice) > parseInt(minutes_remaining) && (
        <RescheduleTimeLimitDialog />
      )}
    </Page>
  );
};

export default IndividualBookingReschedule;
