import { useContext, useEffect, useState } from "react";
import SplashScreen from "src/components/SplashScreen";
import { Clients } from "src/context";
import http from "src/utils/http";
import Pendo from "src/utils/Pendo";

const ClientAuth = ({ children }) => {
  const { updateClient } = useContext(Clients);
  const [loading, setLoading] = useState(false);

  const silentLogin = () => {
    http
      .post("/api/client-portal/me")
      .then((response) => {
        let data = response.data;

        if (data.message === "success") {
          updateClient(data.client);
          // initialize pendo //
          Pendo.initialize(data.client);
        }
      })
      .catch(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("__loginType");
      })
      .finally(() => setLoading(true));
  };

  useEffect(() => {
    silentLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    return <SplashScreen />;
  }

  return children;
};

export default ClientAuth;
