import { lazy } from "react";
import AuthGuard from "src/components/guard/AuthGuard";
import GuardedBaseLayout from "src/layout/menu/guarded";

const Onboarding = [
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/onboarding/calendar/connect-google",
    component: lazy(() =>
      import("src/views/onboarding/stepper/connect/calendar/GoogleCalendar")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/onboarding/calendar/connect-msoutlook",
    component: lazy(() =>
      import("src/views/onboarding/stepper/connect/calendar/MsOutlookCalendar")
    ),
    show: false,
  },
];

export default Onboarding;
