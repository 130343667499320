import React, { createContext, useContext } from "react";
import { useSnackbar } from "notistack";

const SnackbarContext = createContext();

export const useSnackbarContext = () => useContext(SnackbarContext);

export function SnackbarContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      className: "notistack-customize",
    });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
}
