import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box textAlign={"center"} my={2}>
      <CircularProgress size={25} color="primary" />
      {/* <Typography color="primary">Please wait...</Typography> */}
    </Box>
  );
};

export default Loading;
