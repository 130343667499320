import en from "src/assets/locales/en/translation.json";
import en_US from "src/assets/locales/en_US/translation.json";
// import pt from "src/assets/locales/pt/translation.json";
import pt_BR from "src/assets/locales/pt_BR/translation.json";
// import sv from "src/assets/locales/sv/translation.json";
import sv_SE from "src/assets/locales/sv_SE/translation.json";

const translations = [
  {
    value: "en",
    label: "English",
    translation: en,
  },
  {
    value: "en-US",
    label: "English (US)",
    translation: en_US,
  },
  // {
  //   value: "pt",
  //   label: "Portuguese",
  //   translation: pt,
  // },
  {
    value: "pt-BR",
    label: "Portuguese (Brazil)",
    translation: pt_BR,
  },
  // {
  //   value: "sv",
  //   label: "Swedish",
  //   translation: sv,
  // },
  {
    value: "sv-SE",
    label: "Swedish (Sweden)",
    translation: sv_SE,
  },
];

const Resources = translations.reduce((acc, locale) => {
  return {
    ...acc,
    [locale.value]: {
      translation: locale.translation,
    },
  };
}, {});

const i18nResources = { translations: translations, ...Resources };

export default i18nResources;
