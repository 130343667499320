// IntegrationsContext.js
import React, { createContext, useContext } from "react";

const IntegrationsContext = createContext();

// register resource in the list

export const IntegrationsContextProvider = ({
  integrationResource,
  children,
}) => {
  return (
    <IntegrationsContext.Provider
      value={{
        integrationResource,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrationResource = () => {
  return useContext(IntegrationsContext);
};
