import { Box } from "@mui/material";
import React, { Suspense, useState } from "react";
import LoadingScreen from "src/components/LoadingScreen";
import GuardedTopBar from "../topbar/guarded";
import SubscriptionTrialsEnd from "src/components/subscription-trials";

function GuardedBaseLayout({ children }) {
  const [, setMobileNavOpen] = useState(false);

  return (
    <Box
      sx={{
        height: "calc(100vh - 0px)",
      }}
    >
      <Box>
        <GuardedTopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Box>

      <Box>
        <Suspense fallback={<LoadingScreen />}>
          <Box
            sx={(themes) => ({
              pb: 2,
              [themes.breakpoints.up("md")]: {
                padding: "0px !important",
              },

              [themes.breakpoints.down("md")]: {
                padding: "10px !important",
              },
            })}
          >
            {children}

            {/* free subscription count days count to end */}
            <SubscriptionTrialsEnd />
          </Box>
        </Suspense>
      </Box>
    </Box>
  );
}

export default GuardedBaseLayout;
