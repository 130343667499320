import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import IconEmptyPackageWithRocket from "./svg-icons/new/IconEmptyPackageWithRocket";
import { X } from "react-feather";
import Plans from "src/utils/Plans";
import { CheckCircle } from "@mui/icons-material";
import TreffasStyle from "src/utils/TreffasStyle";
import Helper from "src/utils/Helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpgradePlan = ({ title, onClose }) => {
  const mobile = Helper.IsMobile();
  const navigate = useNavigate();
  const [planType, setPlanyType] = useState("yearly");
  const { t } = useTranslation();
  const isMontly = planType === "monthly";
  const isYearly = planType === "yearly";
  const selectedPlan = Plans.list[isMontly ? 1 : 2];

  return (
    <Dialog
      open={true}
      onClose={() => console.log("Dialog unclosable")}
      PaperProps={{
        sx: {
          minWidth: { xs: "auto", md: "900px" },
          borderRadius: { xs: "0px", md: "12px" },
        },
      }}
      scroll="body"
      fullScreen={mobile ? true : false}
    >
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <CloseButton onClose={onClose} />
            </Box>

            <Box
              sx={(themes) => ({
                [themes.breakpoints.down("md")]: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                backgroundColor: { xs: "#FFF", md: "#F4F5F6" },
                minHeight: { xs: "auto", md: "560px" },
              })}
            >
              <Box
                sx={(themes) => ({
                  [themes.breakpoints.up("md")]: {
                    pt: "116px",
                    pb: "50px",
                    position: "relative",
                    right: "35px",
                    scale: { xs: "1", md: "1.5" },
                  },
                })}
              >
                <IconEmptyPackageWithRocket />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <CloseButton onClose={onClose} />
            </Box>

            <Box
              sx={(themes) => ({
                [themes.breakpoints.up("md")]: {
                  padding: "0px 52px 0px 52px",
                  ml: "15px",
                },
              })}
            >
              <Box
                sx={(themes) => ({
                  [themes.breakpoints.down("md")]: {
                    px: "44px",
                  },
                })}
              >
                <Typography
                  className="heading-2"
                  sx={{
                    color: "#19181B",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  {t("plan.upgrade.label.unlock_pro", { label: title })}
                </Typography>
              </Box>

              <Box
                sx={(themes) => ({
                  mt: "8px",
                  [themes.breakpoints.down("md")]: {
                    px: "44px",
                  },
                })}
              >
                <Typography
                  sx={{
                    color: "#6E757C",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  {t("plan.upgrade.label.unlock_pro.description")}
                </Typography>
              </Box>

              <Box
                sx={{
                  p: "10px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  mt: "20px",
                  backgroundColor: "#F4F5F6",
                  borderRadius: "6px",
                  flexDirection: { xs: "column", md: "row" },
                  gap: "20px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#6E757C",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    {t("plan.upgrade.label.plan_to_stick_arround")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#353A3F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    {t("plan.upgrade.label.get_2_months_free")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    padding: "4px",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #353A3F",
                    borderRadius: "100px",
                  }}
                >
                  <Box
                    sx={{
                      p: "8px 14px",
                      cursor: "pointer",
                      ...(isMontly && {
                        backgroundColor: "#19181B",
                        borderRadius: "100px",
                      }),
                    }}
                    onClick={() => setPlanyType("monthly")}
                  >
                    <Typography
                      sx={{
                        color: isMontly ? "#FFF" : "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {t("plan.upgrade.label.monthly")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      p: "8px 14px",
                      cursor: "pointer",
                      ...(isYearly && {
                        backgroundColor: "#19181B",
                        borderRadius: "100px",
                      }),
                    }}
                    onClick={() => setPlanyType("yearly")}
                  >
                    <Typography
                      sx={{
                        color: isYearly ? "#FFF" : "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {t("plan.upgrade.label.yearly")}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={(themes) => ({
                  mt: "40px",
                  [themes.breakpoints.down("md")]: {
                    px: "44px",
                  },
                })}
              >
                {selectedPlan.inclusive.map((data, key) => {
                  return (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        mt: "6px",
                      }}
                    >
                      <CheckCircle sx={{ fill: "#102067", fontSize: "22px" }} />

                      <Typography
                        sx={{
                          color: "#353A3F",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "22px",
                        }}
                      >
                        {data}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

              <Box
                sx={(themes) => ({
                  mt: "49px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pb: "16px",
                  [themes.breakpoints.down("md")]: {
                    px: "25px",
                  },
                })}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "flex-start", md: "center" },
                    gap: "4px",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box>
                    <Typography
                      className="heading-2"
                      sx={{
                        color: "#353A3F",
                      }}
                    >
                      ${selectedPlan.amount}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        color: "#6E757C",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                      }}
                    >
                      {isMontly
                        ? t("plan.upgrade.label.billed_monthly")
                        : t("plan.upgrade.label.billed_yearly")}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Button
                    variant={"contained"}
                    sx={{
                      borderRadius: "8px",
                      minHeight: "44px",
                      minWidth: "168px",
                      ...TreffasStyle.buttonHover,
                    }}
                    onClick={() => {
                      navigate(`/app/billings/plans/upgrade?pro=${planType}`);
                      onClose();
                    }}
                  >
                    {t("plan.upgrade.button.go_pro")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePlan;

const CloseButton = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        pr: "20px",
        pt: "20px",
      }}
    >
      <IconButton sx={{ border: "1px solid #DFE2E6" }} onClick={onClose}>
        <X />
      </IconButton>
    </Box>
  );
};
