/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "src/components/PageNotFound";
import ClientPortalGuardedRoutes from "./client-portal-guarded";
import LoginClient from "src/views/welcome/login-client-portal";
import ClientPortalGuestGuard from "src/components/guard/ClientPortalGuestGuard";

const unguardedRoutes = [
  {
    exact: true,
    guard: ClientPortalGuestGuard,
    path: "/login/client-portal",
    component: () => <LoginClient />,
    show: false,
  },
];

const openRoutes = [
  {
    exact: true,
    path: "/test/open-routes",
    component: () => <h1> Test </h1>,
    show: false,
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.withSubs ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}

      <Route path={"/404"} element={<PageNotFound />} />
      <Route path={"*"} element={<Navigate to="/404" />} />
    </Routes>
  ) : null;

function ClientPortalRoutes() {
  let routes = [
    ...ClientPortalGuardedRoutes,
    ...unguardedRoutes,
    ...openRoutes,
  ];
  return renderRoutes(routes);
}

export default ClientPortalRoutes;
