import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import i18nResources from "./i18n-resources";

const storedLanguage = localStorage.getItem("i18nextLng");

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources: i18nResources,
    fallbackLng: ["en-US"],
    lng: storedLanguage || "en-US",
    // defaultNS: "___",
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });
