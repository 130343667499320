import React from "react";

const IconGettingStarted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="324"
      height="243"
      fill="none"
      viewBox="0 0 324 243"
    >
      <path
        fill="#3C6C9F"
        d="M241.2 217.013H54.495l-13.455 4.59h214.628l-14.468-4.59z"
      ></path>
      <path
        fill="#306966"
        d="M162.923 214.448s88.875 11.767 104.377-61.223c15.503-72.99-31.252-76.14-66.465-81.967-35.212-5.828-68.647-48.263-90.36-45.833-21.712 2.43-58.275 33.345-46.89 78.885 11.385 45.54 37.35 96.818 99.338 110.138z"
      ></path>
      <path
        fill="#306966"
        d="M207.09 52.2c-.405 3.308-3.105 14.243-14.355 11.835-11.25-2.407-47.227-21.15-44.572-27.225 2.655-6.075 24.502-.81 34.537-.81s26.348.113 24.39 16.2z"
      ></path>
      <path
        fill="#E8F0FF"
        d="M161.303 213.75s88.875 11.768 104.377-61.222c15.503-72.99-31.252-76.14-66.465-81.968-35.212-5.85-68.625-48.24-90.36-45.832-21.735 2.407-58.275 33.345-46.89 78.885 11.385 45.54 37.35 96.84 99.338 110.137z"
      ></path>
      <path
        fill="#0173FE"
        d="M205.47 51.503c-.405 3.307-3.105 14.242-14.355 11.835-11.25-2.408-47.227-21.15-44.572-27.225 2.655-6.075 24.502-.81 34.537-.81s26.348.112 24.39 16.2z"
      ></path>
      <g opacity="0.45">
        <path
          fill="#698190"
          d="M192.398 83.43v4.793h4.162v4.23h-4.162v43.2h-10.89l-.293.225h-16.92l.315-.225h-8.685v5.76h41.31v-70.83a63.345 63.345 0 01-4.837-1.215V83.43zM147.218 39.263c.81 1.192 2.16 2.542 3.937 4.005v-4.005h-3.937zM155.925 39.263v5.76h36.473v18.945c1.845.247 3.442.112 4.837-.293V39.263h-41.31zM106.898 45.023v-5.76H81.382a71.617 71.617 0 00-5.602 5.76H106.898zM143.663 135.878l.315-.225H127.71l-.315.225H118.8l.293-.225h-7.448v5.737h39.533v-5.512h-7.515zM111.645 45.023h37.463c-2.768-1.958-5.468-3.893-8.123-5.76h-29.34v5.76zM106.897 135.878H72.45c.787 1.845 1.575 3.69 2.43 5.535h32.017v-5.535zM67.815 98.37v-5.94H64.35V88.2h3.465V56.34c-1.575 2.858-2.992 5.873-4.14 9.045v44.933c1.237 4.432 2.61 8.91 4.14 13.365V98.37z"
        ></path>
        <path
          fill="#18ACFE"
          d="M105.525 45.023H75.78a67.373 67.373 0 00-7.965 11.362v14.85l37.71-26.212zM192.397 45.023h-5.422l-15.098 10.89a175.892 175.892 0 007.65 3.622l12.87-9.135v-5.377zM151.177 118.755l-23.467 16.898h16.267l7.2-5.13v-11.768zM106.898 70.988l-24.57 17.235h9.247l15.323-10.913v-6.322zM155.925 109.418l23.828-16.988h-10.598l-13.23 9.585v7.403zM111.645 54.518l13.568-9.495h-9.698l-3.87 2.7v6.795zM106.897 51.053L67.815 78.39v6.795l39.082-27.36v-6.772zM67.815 98.37v6.75l17.842-12.69h-9.36l-8.482 5.94zM151.178 105.458l-39.533 28.62v1.575h7.448l32.085-22.86v-7.335zM192.398 135.653v-8.235l-10.89 8.235h10.89zM111.645 67.68v6.255l39.038-27.787c-.54-.383-1.058-.743-1.575-1.125h-5.13L111.645 67.68zM155.925 135.653h8.708l27.765-19.868v-8.865l-36.473 26.325v2.408zM121.253 92.43l-9.608 6.953v8.302l21.488-15.255h-11.88zM155.925 115.335v11.813l36.473-25.965V92.43h-4.703l-31.77 22.905zM106.897 102.803l-36.99 26.685a155.578 155.578 0 002.43 6.075l34.56-24.525v-8.235zM155.925 76.275l19.733-13.995a136.74 136.74 0 01-6.998-4.027l-12.735 9.18v8.842zM192.397 83.43v-7.807l-17.415 12.6h10.688l6.727-4.793zM151.178 79.628v-8.775l-24.075 17.37h11.97l12.105-8.595z"
        ></path>
        <path fill="#18ACFE" d="M115.515 45.023h-3.87v2.7l3.87-2.7z"></path>
        <path fill="#fff" d="M115.515 45.023h-3.87v2.7l3.87-2.7z"></path>
        <path
          fill="#18ACFE"
          d="M106.897 45.023h-1.372l-37.71 26.19v7.177l39.082-27.337v-6.03z"
        ></path>
        <path
          fill="#fff"
          d="M106.897 45.023h-1.372l-37.71 26.19v7.177l39.082-27.337v-6.03z"
        ></path>
        <path
          fill="#18ACFE"
          d="M111.645 67.68l32.333-22.657h-18.765l-13.568 9.495V67.68z"
        ></path>
        <path
          fill="#fff"
          d="M111.645 67.68l32.333-22.657h-18.765l-13.568 9.495V67.68z"
        ></path>
        <path fill="#18ACFE" d="M67.815 92.43v5.94l8.482-5.94h-8.482z"></path>
        <path fill="#fff" d="M67.815 92.43v5.94l8.482-5.94h-8.482z"></path>
        <path
          fill="#18ACFE"
          d="M106.897 70.988V57.825l-39.082 27.36v3.038h14.512l24.57-17.235z"
        ></path>
        <path
          fill="#fff"
          d="M106.897 70.988V57.825l-39.082 27.36v3.038h14.512l24.57-17.235z"
        ></path>
        <path
          fill="#18ACFE"
          d="M91.575 88.223h15.322V77.311L91.575 88.223z"
        ></path>
        <path
          fill="#fff"
          d="M91.575 88.223h15.322V77.311L91.575 88.223z"
        ></path>
        <path
          fill="#18ACFE"
          d="M111.645 88.223h15.458l24.075-17.37V46.485l-.473-.337-39.06 27.787v14.288z"
        ></path>
        <path
          fill="#fff"
          d="M111.645 88.223h15.458l24.075-17.37V46.485l-.473-.337-39.06 27.787v14.288z"
        ></path>
        <path
          fill="#18ACFE"
          d="M106.898 92.43h-21.24l-17.82 12.69v18.608c.675 1.912 1.35 3.847 2.092 5.76l36.99-26.685V92.43h-.022z"
        ></path>
        <path
          fill="#fff"
          d="M106.898 92.43h-21.24l-17.82 12.69v18.608c.675 1.912 1.35 3.847 2.092 5.76l36.99-26.685V92.43h-.022z"
        ></path>
        <path
          fill="#18ACFE"
          d="M168.66 58.23c-4.342-2.655-8.595-5.512-12.735-8.415V67.41l12.735-9.18z"
        ></path>
        <path
          fill="#fff"
          d="M168.66 58.23c-4.342-2.655-8.595-5.512-12.735-8.415V67.41l12.735-9.18z"
        ></path>
        <path
          fill="#18ACFE"
          d="M155.925 45.023v1.732c4.59 3.083 10.328 6.345 15.975 9.158l15.098-10.89h-31.073z"
        ></path>
        <path
          fill="#fff"
          d="M155.925 45.023v1.732c4.59 3.083 10.328 6.345 15.975 9.158l15.098-10.89h-31.073z"
        ></path>
        <path
          fill="#18ACFE"
          d="M111.645 92.43v6.953l9.608-6.953h-9.608z"
        ></path>
        <path fill="#fff" d="M111.645 92.43v6.953l9.608-6.953h-9.608z"></path>
        <path
          fill="#698190"
          d="M106.898 135.653H72.36c.023.067.068.157.09.225h34.448v-.225z"
        ></path>
        <path
          fill="#fff"
          d="M106.898 135.653H72.36c.023.067.068.157.09.225h34.448v-.225z"
        ></path>
        <path
          fill="#18ACFE"
          d="M106.898 111.038l-34.56 24.525c0 .022.022.045.022.067h34.538v-24.592z"
        ></path>
        <path
          fill="#fff"
          d="M106.898 111.038l-34.56 24.525c0 .022.022.045.022.067h34.538v-24.592z"
        ></path>
        <path
          fill="#18ACFE"
          d="M179.528 59.513c4.725 2.092 8.887 3.622 11.632 4.207.428.09.855.158 1.26.225V50.378l-12.892 9.135z"
        ></path>
        <path
          fill="#fff"
          d="M179.528 59.513c4.725 2.092 8.887 3.622 11.632 4.207.428.09.855.158 1.26.225V50.378l-12.892 9.135z"
        ></path>
        <path
          fill="#18ACFE"
          d="M155.925 88.223h19.058l17.415-12.6v-6.255c-5.67-1.665-11.25-4.14-16.74-7.088l-19.733 13.995v11.948z"
        ></path>
        <path
          fill="#fff"
          d="M155.925 88.223h19.058l17.415-12.6v-6.255c-5.67-1.665-11.25-4.14-16.74-7.088l-19.733 13.995v11.948z"
        ></path>
        <path
          fill="#18ACFE"
          d="M151.178 79.628l-12.105 8.595h12.105v-8.595z"
        ></path>
        <path
          fill="#fff"
          d="M151.178 79.628l-12.105 8.595h12.105v-8.595z"
        ></path>
        <path
          fill="#18ACFE"
          d="M151.178 92.43h-18.045l-21.488 15.255v26.393l39.533-28.62V92.43z"
        ></path>
        <path
          fill="#fff"
          d="M151.178 92.43h-18.045l-21.488 15.255v26.393l39.533-28.62V92.43z"
        ></path>
        <path
          fill="#18ACFE"
          d="M169.155 92.43h-13.23v9.585l13.23-9.585z"
        ></path>
        <path fill="#fff" d="M169.155 92.43h-13.23v9.585l13.23-9.585z"></path>
        <path
          fill="#698190"
          d="M118.8 135.878h8.595l.315-.225h-8.617l-.293.225z"
        ></path>
        <path
          fill="#fff"
          d="M118.8 135.878h8.595l.315-.225h-8.617l-.293.225z"
        ></path>
        <path
          fill="#18ACFE"
          d="M155.925 115.335l31.77-22.905h-7.942l-23.828 16.988v5.917z"
        ></path>
        <path
          fill="#fff"
          d="M155.925 115.335l31.77-22.905h-7.942l-23.828 16.988v5.917z"
        ></path>
        <path
          fill="#18ACFE"
          d="M192.397 88.223V83.43l-6.727 4.793h6.727z"
        ></path>
        <path fill="#fff" d="M192.397 88.223V83.43l-6.727 4.793h6.727z"></path>
        <path
          fill="#18ACFE"
          d="M151.178 112.793l-32.085 22.86h8.617l23.468-16.898v-5.962z"
        ></path>
        <path
          fill="#fff"
          d="M151.178 112.793l-32.085 22.86h8.617l23.468-16.898v-5.962z"
        ></path>
        <path
          fill="#698190"
          d="M164.318 135.878h16.897l.293-.225h-16.875l-.315.225z"
        ></path>
        <path
          fill="#fff"
          d="M164.318 135.878h16.897l.293-.225h-16.875l-.315.225z"
        ></path>
        <path
          fill="#18ACFE"
          d="M181.508 135.653l10.89-8.235v-11.633l-27.765 19.868h16.875z"
        ></path>
        <path
          fill="#fff"
          d="M181.508 135.653l10.89-8.235v-11.633l-27.765 19.868h16.875z"
        ></path>
        <path
          fill="#698190"
          d="M143.978 135.653l-.315.225h7.515v-.225h-7.2z"
        ></path>
        <path
          fill="#fff"
          d="M143.978 135.653l-.315.225h7.515v-.225h-7.2z"
        ></path>
        <path
          fill="#18ACFE"
          d="M155.925 133.245l36.473-26.325v-5.737l-36.473 25.965v6.097z"
        ></path>
        <path
          fill="#fff"
          d="M155.925 133.245l36.473-26.325v-5.737l-36.473 25.965v6.097z"
        ></path>
        <path fill="#18ACFE" d="M151.178 130.523l-7.2 5.13h7.2v-5.13z"></path>
        <path fill="#fff" d="M151.178 130.523l-7.2 5.13h7.2v-5.13z"></path>
        <path
          fill="#698190"
          d="M111.645 39.263h-4.748v5.76h4.748v-5.76zM109.462 135.653l-.292.225h-2.273v5.512h4.748v-5.737h-2.183zM109.463 135.653h2.182v-1.575l-2.182 1.575zM106.897 77.31l4.748-3.375V67.68l-4.748 3.308v6.322zM111.645 99.383l-4.748 3.42v8.235l4.748-3.353v-8.302zM111.645 47.723l-4.748 3.33v6.772l4.748-3.307v-6.795z"
        ></path>
        <path
          fill="#698190"
          d="M111.645 47.723v-2.7h-4.748v6.03l4.748-3.33zM106.897 57.825v13.163l4.748-3.308V54.518l-4.748 3.307zM106.897 88.223h4.748V73.936l-4.748 3.374v10.913zM111.645 92.43h-4.748v10.373l4.748-3.42V92.43zM109.462 135.653h-2.565v.225h2.273l.292-.225z"
        ></path>
        <path
          fill="#698190"
          d="M111.645 134.078v-26.392l-4.748 3.352v24.615h2.565l2.183-1.575zM155.925 39.263h-4.748v4.005a83.661 83.661 0 002.273 1.755h2.475v-5.76zM152.572 135.653l-.292.225h-1.103v5.512h4.748v-5.737h-3.353zM155.925 76.275v-8.842l-4.748 3.42v8.775l4.748-3.353zM155.925 102.015l-4.748 3.443v7.335l4.748-3.375v-7.403zM152.573 135.653h3.352v-2.408l-3.352 2.408zM151.177 130.523l4.748-3.375v-11.813l-4.748 3.42v11.768zM155.925 67.433V49.838c-1.598-1.125-3.173-2.228-4.748-3.353v24.368l4.748-3.42z"
        ></path>
        <path
          fill="#698190"
          d="M153.45 45.023c.787.562 1.597 1.147 2.475 1.732v-1.732h-2.475zM155.925 102.015V92.43h-4.748v13.028l4.748-3.443zM151.177 88.223h4.748V76.275l-4.748 3.353v8.595zM151.177 112.793v5.962l4.748-3.42v-5.917l-4.748 3.375zM151.177 135.878h1.103l.292-.225h-1.395v.225zM155.925 127.148l-4.748 3.375v5.13h1.395l3.353-2.408v-6.097z"
        ></path>
        <path
          fill="#31464C"
          d="M201.645 141.39H74.88a139.334 139.334 0 002.678 5.558h124.087v-5.558z"
        ></path>
        <path
          fill="#698190"
          d="M192.397 92.43h4.163v-4.207h-4.163v4.207zM67.815 88.223H64.35v4.23h3.465v-4.23zM179.753 92.43l5.917-4.207h-10.687l-5.828 4.207h10.598zM133.132 92.43l5.94-4.207h-11.97l-5.85 4.207h11.88z"
        ></path>
        <path
          fill="#698190"
          d="M187.695 92.43h4.703v-3.375l-4.703 3.375zM82.328 88.223l-6.03 4.207h9.36l5.917-4.207h-9.247zM82.327 88.223H67.815v4.207h8.482l6.03-4.207z"
        ></path>
        <path
          fill="#698190"
          d="M91.575 88.223l-5.918 4.207h21.24v-4.207H91.575zM121.253 92.43l5.85-4.207h-15.458v4.207h9.608zM151.178 88.223h-12.105l-5.94 4.207h18.045v-4.207zM169.155 92.43l5.828-4.207h-19.058v4.207h13.23zM179.752 92.43h7.943l4.702-3.375v-.832h-6.727l-5.918 4.207zM111.645 88.223h-4.748v4.23h4.748v-4.23z"
        ></path>
        <path
          fill="#698190"
          d="M155.925 88.223h-4.748v4.23h4.748v-4.23z"
        ></path>
        <path
          fill="#203762"
          d="M201.645 146.948H77.535c1.238 2.452 2.52 4.86 3.87 7.245h124.02l-3.78-7.245z"
          opacity="0.57"
        ></path>
      </g>
      <path
        fill="#F28431"
        d="M71.145 124.403c.09.067-1.305 14.647-13.837 13.995-12.555-.653 13.837-13.995 13.837-13.995z"
      ></path>
      <path
        fill="#F28431"
        d="M42.48 117.54c.068-.09 14.31 2.655 12.51 15.233-1.822 12.577-12.51-15.233-12.51-15.233z"
      ></path>
      <path
        fill="#F28431"
        d="M32.198 139.928c-.068-.09 7.852-12.375 18.675-5.918 10.822 6.48-18.675 5.918-18.675 5.918z"
      ></path>
      <path
        fill="#F28431"
        d="M62.663 193.14h-.225v-14.872c0-.248-.968-24.908-1.935-30.803-.968-5.85-7.245-11.272-7.29-11.317l.157-.18c.068.045 6.39 5.512 7.38 11.475.968 5.917 1.935 30.6 1.935 30.847l-.022 14.85z"
      ></path>
      <path
        fill="#F28431"
        d="M60.548 144.833c-.743-.653-1.395-1.283-1.868-1.733.293.54.9 1.103 1.868 1.733zM71.955 145.058c3.87-.945 10.283-4.343 13.77-6.278.383-.472.563-.765.563-.765s-28.935-.337-27.788 4.635c1.98.855 8.798 3.533 13.455 2.408z"
      ></path>
      <path
        fill="#F28431"
        d="M59.985 145.08c-.27-1.463-1.665-1.733-4.725-.023-13.927 7.695-7.2 25.178-7.042 25.178 0 0 .202-.315.54-.855 1.552-6.075 5.737-20.655 11.227-24.3zM51.278 143.393c3.127-2.453 3.735-4.455 3.825-4.883a9.381 9.381 0 00-1.665-.067c-12.555.292-12.87 14.962-12.78 15.007 0 0 .382-.225 1.035-.63 2.115-2.272 6.907-7.312 9.585-9.427zM80.032 156.308s-.021.021 0 0c-.021.021 0 .021 0 0z"
      ></path>
      <path
        fill="#D86D27"
        d="M59.985 145.08c-5.49 3.645-9.675 18.203-11.227 24.3 2.407-3.87 12.105-19.777 11.227-24.3zM60.638 148.612c-.023-.045-.045-.067-.068-.09.09.54.338 1.238.72 2.07 5.243 11.25 18.135 6.098 18.72 5.76.023 0 .023-.022.023-.022s-17.73-4.995-19.395-7.718zM55.125 138.51c-.09.428-.697 2.43-3.825 4.883-2.7 2.115-7.47 7.132-9.562 9.405 4.32-2.723 20.61-13.253 13.387-14.288zM85.725 138.78c-3.487 1.935-9.9 5.333-13.77 6.278-4.657 1.125-11.452-1.553-13.455-2.408.045.158.09.315.18.473.473.45 1.148 1.057 1.868 1.732.27.18.562.36.9.54 12.015 6.75 21.892-3.622 24.277-6.615z"
      ></path>
      <path
        fill="#F28431"
        d="M69.053 149.355c-2.43-1.327-4.77-2.385-6.368-2.655-1.507-.247-2.385.203-2.092 1.823.022.022.045.067.067.09 1.665 2.722 19.395 7.717 19.395 7.717s-5.76-4.117-11.002-6.975z"
      ></path>
      <path
        fill="#4D60AB"
        d="M71.145 193.928l-.27 2.97-1.935 21.735H56.138l-1.958-21.735-.247-2.97h17.212z"
      ></path>
      <path
        fill="#273B72"
        d="M71.145 193.928l-.27 2.97H54.18l-.247-2.97h17.212z"
      ></path>
      <path fill="#4D60AB" d="M71.798 192.06h-18.54v3.51h18.54v-3.51z"></path>
      <path
        fill="#1C2D56"
        d="M71.01 195.547h-8.685s2.228 7.673-.382 12.803c-2.61 5.13-5.805 10.282-5.805 10.282H68.94l2.07-23.085z"
        opacity="0.55"
      ></path>
      <path
        fill="#414C5C"
        d="M244.597 208.215c-.427-.743-2.722-.405-4.86-.068-.72.113-1.305.045-1.8-.112.36 2.43-1.552 5.017-2.857 5.827-.945.585-1.17 1.53-1.193 2.205.765.068 2.723.315 4.343.765 1.417-.18 2.722-.472 3.06-.81.247-.247 1.125-1.35 2.092-2.61.428-.945.9-1.98 1.238-2.677.742-1.53.405-1.778-.023-2.52zm-6.885 3.892c-.877 1.778-1.62 2.25-2.25 2.7-.63.45-1.327.81-1.327.81s.382-1.147 1.125-1.44c.742-.315 1.687-1.732 2.115-2.227.405-.54 1.215-1.62.337.157zm4.095 2.07c-.427.495-.81.9-1.035 1.058-.337.225-1.08.495-1.665.697a8.776 8.776 0 01-.922.293s.405-.113.922-.293a8.367 8.367 0 002.7-1.755c.788-.922 1.665-2.07 1.665-2.07s-.54 1.035-1.665 2.07z"
      ></path>
      <path
        fill="#1E2733"
        d="M233.955 217.013l1.102-.023c.878.068 2.048.023 3.15-.112-1.62-.473-3.555-.698-4.342-.765 0 .517.09.9.09.9zM245.632 208.193c-.27-.765-2.609-3.375-2.609-3.375s-7.898-1.305-5.828 1.485c.405.54.63 1.147.72 1.732a4.208 4.208 0 001.8.113c2.138-.338 4.433-.698 4.86.067.428.765.765.99 0 2.565-.337.698-.81 1.755-1.237 2.678 1.395-1.823 2.925-3.96 2.925-4.253.022-.495-.631-1.012-.631-1.012z"
      ></path>
      <path
        fill="#1E2733"
        d="M243.495 212.107s-.877 1.148-1.665 2.07c1.103-1.035 1.665-2.07 1.665-2.07zM240.795 215.235c.225-.135.607-.562 1.035-1.057-.698.652-1.598 1.305-2.7 1.732.585-.18 1.305-.45 1.665-.675zM239.13 215.91l-.922.293c.315-.068.63-.18.922-.293z"
      ></path>
      <path
        fill="#616F7F"
        d="M237.353 211.928c-.428.517-1.373 1.935-2.115 2.227-.743.315-1.125 1.44-1.125 1.44s.697-.36 1.327-.81c.63-.45 1.373-.9 2.25-2.7.9-1.755.09-.675-.337-.157z"
      ></path>
      <path
        fill="#414C5C"
        d="M228.848 211.41c.135-1.417.517-2.385.855-2.947l-.495-1.485s-4.433-.248-5.288 3.217c-.855 3.465-4.027 3.893-6.052 4.253-2.048.382-2.453 2.565-2.453 2.565s11.633-.765 13.163-1.755c1.507-.968.045-1.35.27-3.848zm-6.615 2.183c-.09.09-.315.18-.63.292-.743.405-1.958 1.058-2.858 1.35-1.44.473-2.632.833-2.632.833s1.147-1.395 2.812-1.62c1.103-.135 2.07-.36 2.678-.563.472-.247.742-.382.63-.292z"
      ></path>
      <path
        fill="#1E2733"
        d="M231.053 215.145c.247-1.372-.72-4.725-1.373-6.682-.315.562-.72 1.53-.855 2.947-.225 2.498 1.238 2.88-.292 3.848-1.53.967-13.163 1.755-13.163 1.755h15.705l-.022-1.868z"
      ></path>
      <path
        fill="#616F7F"
        d="M216.113 216.068s1.215-.338 2.632-.833c.9-.292 2.115-.967 2.858-1.35-.608.203-1.575.428-2.678.563-1.665.225-2.812 1.62-2.812 1.62zM221.603 213.885c.315-.112.54-.202.63-.292.112-.09-.158.045-.63.292z"
      ></path>
      <path
        fill="#141D45"
        d="M243.653 204.683s-7.268-25.898-7.268-28.103c0-2.227-4.05-11.34-4.05-11.34l3.825-11.497-9.36-23.288-10.17 1.755s-.18 23.558.81 27.405c.36 1.418 1.71 5.85 3.285 10.89l6.008-4.05s3.757 16.538 4.005 19.058c.225 2.52 5.737 8.28 7.582 12.307 1.35 2.925 1.575 7.515 1.62 9.788 1.53-.338 3.083-1.125 3.713-2.925z"
      ></path>
      <path
        fill="#2F64A4"
        d="M230.738 185.513c-.225-2.52-4.005-19.058-4.005-19.058l-6.008 4.05c2.7 8.685 6.008 19.148 6.008 19.148l9.54 18.067s1.8.27 3.645-.135c-.023-2.272-.27-6.84-1.62-9.787-1.823-4.005-7.335-9.765-7.56-12.285z"
      ></path>
      <path
        fill="#3667B0"
        d="M222.458 186.322c-.225-3.217-2.97-8.662-3.713-10.08-.765-1.417-2.835-3.195.743-5.062 3.555-1.868 7.312-3.24 8.82-6.008 1.507-2.767 3.69-15.75 9.202-17.82 5.513-2.07 7.358-11.497 7.358-11.497s-15.368 5.31-23.378-1.08c-1.08 4.477-6.525 27.112-8.122 33.187-1.755 6.75 9.652 42.75 9.652 42.75s2.295-.405 4.613-1.215c1.012-.36 1.665-1.08 2.047-1.8-1.35-1.89-4.05-5.737-5.062-7.605-1.35-2.497-1.913-10.552-2.16-13.77z"
      ></path>
      <path
        fill="#194788"
        d="M224.888 171.967s2.295-3.735 3.802-5.58c1.508-1.845 8.033-10.35 12.803-15.39 4.77-5.062 3.802-19.35 3.802-19.35l-23.58 2.385s-.067.27-.18.765c7.988 6.39 23.378 1.08 23.378 1.08s-1.845 9.428-7.358 11.498c-5.512 2.07-7.695 15.052-9.202 17.82-1.508 2.767-5.265 4.14-8.82 6.007-3.555 1.868-1.485 3.645-.743 5.063.765 1.417 3.488 6.862 3.713 10.08.225 3.217.81 11.25 2.182 13.792 1.013 1.868 3.713 5.715 5.063 7.605.495-.9.607-1.755.607-1.755l-5.467-34.02z"
      ></path>
      <path
        fill="#5189C7"
        d="M214.763 168.457s5.985-22.365 7.357-23.13c1.373-.765-2.452 16.448-7.357 23.13zM214.74 176.647c-.112.473 6.12 29.97 9.09 31.185 2.97 1.215-9.09-31.185-9.09-31.185z"
      ></path>
      <path
        fill="#A41D36"
        d="M244.71 118.598c-.225-2.723-.09-6.998-.022-8.685-.428.202-.833.36-1.17.472-2.52.855-13.77 5.22-15.953 5.243 0 0-1.148.067-1.44-1.575-.293-1.62-.36-3.803-.36-3.803s14.467-8.73 15.682-8.46l-1.259-4.905s-1.103 3.015-2.07 5.085c-.968 2.07-10.688 6.008-15.301 7.47-4.612 1.463-3.959-3.082-5.489-4.072-.18-.113-.36-.293-.563-.495-.922-1.035-1.935-2.993-2.498-4.208a2.716 2.716 0 01-.472-1.035v-.022c-.022-.068-.023-.113-.045-.18.112 1.507 1.507 4.5 2.88 7.402 1.35 2.858 2.678 5.625 2.723 6.773v.135c.36-.405.719-.563 1.102-.428 0 0 5.22 3.105 8.235 2.768 3.015-.338 4.072-.968 7.042-1.26 2.948-.293 4.298-1.035 4.186 1.485-.113 2.52-3.803 2.52-4.546 6.367-.742 3.848-.224 6.48-2.407 7.785-2.182 1.305-12.015 2.34-18.315 1.125 0 0 3.915 1.148 6.03 2.07 2.115.923 9.023 2.363 14.423 3.038 5.4.675 11.137-4.995 11.137-4.995s-1.26-9.653-1.53-13.095z"
      ></path>
      <path
        fill="#fff"
        d="M222.795 84.938c-.067.72.09 2.632 3.465 2.858 2.138.134 3.667-1.306 4.635-2.746-.09.023-.157.046-.247.068-2.948.743-5.805.27-7.853-.18zM218.137 85.163c1.035.585 3.24-.023 4.23-.338a.793.793 0 00-.225-.045c-.99-.225-2.587.045-4.005.383zM232.245 82.395c-.608-1.687-3.263-1.372-3.263-1.372l-8.145.315s-2.767.067-3.217 2.34c.27-.27.562-.473.9-.54 1.935-.518 13.725-.743 13.725-.743z"
      ></path>
      <path
        fill="#fff"
        d="M218.138 85.163c1.44-.337 3.037-.608 4.004-.382a.806.806 0 00.225.044c.293-.09.473-.157.473-.157s-.022.09-.045.27c2.048.45 4.905.945 7.875.18.09-.022.158-.045.248-.067a11.305 11.305 0 001.35-2.656s-11.791.226-13.726.743c-.337.09-.63.292-.9.54a2.014 2.014 0 00-.045.36c-.045.585.158.922.541 1.125z"
      ></path>
      <path
        fill="#C6203A"
        d="M214.672 89.46c-.697 1.553-1.44 3.285-1.845 4.5-1.395 4.185-5.355 11.048-5.355 11.048s-1.935-1.17-3.892-2.115c-1.958-.945-7.65-3.038-7.65-3.038s-3.398 4.073-2.52 5.153c.292.36 1.8 1.53 3.712 2.902 3.465.608 10.643 1.193 12.42 1.958 2.183.922 2.7-4.073 2.475-7.47-.18-2.475 1.665-9.428 2.655-12.938zM246.645 90.27c-1.553-2.924-4.275-5.49-4.275-5.49-.63-.607-2.588-1.147-4.59-1.53-2.7-.54-5.535-.855-5.535-.855s-.428 1.328-1.35 2.656c-.968 1.44-2.498 2.88-4.635 2.745-3.375-.226-3.533-2.16-3.465-2.858.022-.157.045-.27.045-.27s-.18.067-.473.157c-.967.316-3.195.9-4.23.338-.36-.203-.585-.563-.54-1.125 0-.135.023-.248.045-.36-.472.472-.855 1.17-1.147 1.913-.54 1.35-.855 2.857-.99 3.42-.248 1.035-1.935 7.762-1.823 9.99 0 .157.023.292.045.427 0 .068.023.112.045.18v.022c.09.383.248.743.473 1.036.562 1.215 1.575 3.172 2.497 4.207.18.203.383.383.563.495 1.53 1.013.855 5.535 5.49 4.073 4.635-1.463 14.355-5.4 15.3-7.47.967-2.07 2.07-5.086 2.07-5.086l1.26 4.906c-1.215-.27-15.683 8.46-15.683 8.46s.068 2.16.36 3.802c.293 1.62 1.44 1.575 1.44 1.575 2.183-.022 13.433-4.387 15.953-5.242.36-.113.742-.27 1.17-.473 2.61-1.192 6.255-3.667 6.817-6.592.63-3.398-3.285-10.103-4.837-13.05zm-9.36 10.801c-1.035 1.012-10.463-.788-14.513-.36-2.385.247-4.567.562-6.097.18-1.08-.248-1.823-.855-2.093-2.026-.427-1.957 1.013-5.715 1.958-7.852.427-.968.742-1.598.742-1.598 1.103-2.43 4.005 6.863 5.04 8.033 1.035 1.17 5.355.698 11.43.833 6.12.112 4.568 1.777 3.533 2.79zm-8.348 8.37s12.15-7.808 12.375-6.368c.225 1.463-12.375 6.368-12.375 6.368zm14.603-16.785c-1.193-1.733-.045-4.433.765-4.14.81.314 5.737 10.597 5.94 10.912-.023 0-5.535-5.04-6.705-6.772z"
      ></path>
      <path
        fill="#C6203A"
        d="M235.687 114.818c-2.947.293-4.027.923-7.042 1.26-3.015.338-8.235-2.767-8.235-2.767-.36-.158-.743.022-1.103.427-.112 2.723-4.702 17.843-4.702 17.843 6.3 1.215 16.132.18 18.315-1.125 2.182-1.305 1.665-3.915 2.407-7.785.743-3.87 4.433-3.848 4.545-6.368.113-2.52-1.237-1.777-4.185-1.485zm-10.237 12.803c-7.133 1.597-10.193 3.667-10.193 3.667s4.185-17.235 4.635-16.515c0 0 3.533.72 4.253 3.398.72 2.677 2.767 3.555 4.522 4.95 1.755 1.395 3.915 2.902-3.217 4.5z"
      ></path>
      <path
        fill="#D43359"
        d="M222.772 100.71c4.05-.427 13.478 1.373 14.513.36 1.035-1.012 2.587-2.677-3.51-2.79-6.098-.112-10.395.36-11.43-.832-1.035-1.192-3.938-10.462-5.04-8.032 0 0-.315.63-.743 1.597-.945 2.137-2.385 5.895-1.957 7.852.27 1.17 1.012 1.755 2.092 2.025 1.53.36 3.713.068 6.075-.18zM228.668 123.143c-1.755-1.373-3.803-2.25-4.523-4.95-.72-2.678-4.252-3.398-4.252-3.398-.45-.72-4.635 16.515-4.635 16.515s3.06-2.07 10.192-3.667c7.133-1.598 4.973-3.128 3.218-4.5zM244.305 88.515c-.81-.315-1.957 2.408-.765 4.14 1.193 1.733 6.705 6.773 6.705 6.773-.202-.293-5.152-10.598-5.94-10.913zM228.938 109.44s12.599-4.905 12.374-6.367c-.225-1.463-12.374 6.367-12.374 6.367z"
      ></path>
      <path
        fill="#911A35"
        d="M212.018 102.398c.225 3.397-.293 8.392-2.475 7.47-1.8-.765-8.955-1.35-12.42-1.958 3.757 2.723 9.112 6.323 10.057 6.615 1.463.428 2.52 1.485 4.568-.202 1.755-1.44 4.207-6.21 4.86-7.493-1.373-2.902-2.768-5.872-2.88-7.402a2.443 2.443 0 01-.045-.428c-.113-2.25 1.575-8.955 1.822-9.99.135-.562.428-2.047.99-3.42 0 0-.877 1.778-1.822 3.848-.99 3.532-2.835 10.485-2.655 12.96z"
      ></path>
      <path
        fill="#911A35"
        d="M237.78 83.25c-2.7-.54-5.535-.855-5.535-.855-.607-1.687-3.262-1.372-3.262-1.372l-8.145.315s-2.768.067-3.218 2.34c-.472.472-.855 1.17-1.147 1.912.472-.135 1.057-.292 1.642-.45 1.44-.337 3.038-.607 4.005-.382a.806.806 0 00.225.045c.135.022.293.067.45.09 2.048.45 4.905.945 7.875.18.09-.023.158-.045.248-.068 3.24-.832 7.2-1.665 7.2-1.665s-.135 0-.338-.09z"
        opacity="0.56"
      ></path>
      <path
        fill="#EEC2A0"
        d="M192.51 102.758c1.238 0 3.263-2.7 3.263-2.7s-2.385-2.34-2.948-2.678c-.585-.315-2.88-1.102-3.015-1.485-.157-.382-1.215-.787-1.147.113.067.9.675 1.35.675 1.35s-3.083-1.328-3.42-1.62c-.338-.293-.99-.023-.968.675.045.697.833 1.687.788 2.25-.045.562.382 2.745.63 3.285.787.225 2.452.697 3.442.787 1.283.158 1.44.023 2.7.023z"
      ></path>
      <path
        fill="#C3865F"
        d="M190.44 103.793c.968.202 2.97.36 2.97.36l2.363-4.095s-2.025 2.7-3.263 2.7c-1.237 0-1.417.135-2.745 0-.99-.09-2.632-.563-3.442-.788 0 .023.022.045.022.068.248.427 3.128 1.53 4.095 1.755z"
      ></path>
      <path
        fill="#C3865F"
        d="M189.292 97.357l1.35 1.058s.068 1.89.81 2.677c0 0-1.215-1.192-1.237-1.867-.023-.675-.518-.923-.698-1.148-.18-.225-2.587-1.8-2.587-1.8l2.362 1.08z"
      ></path>
      <path
        fill="#F8DAC5"
        d="M194.422 99.54s-.855-1.125-1.327-1.53c-.45-.383-2.678-1.282-2.678-1.282s1.17.787 2.003 1.305c.832.517.022 1.642 0 2.115-.023.472 1.192 1.125 1.305.967.112-.18.697-1.575.697-1.575z"
      ></path>
      <path
        fill="#FDC07D"
        d="M222.952 117.855c-.157.023-.292.045-.45.045-2.07.068-4.387-.697-4.95-1.192-.585-.473-.967-.788-.765-1.215 0 0-1.305-.338-1.057-1.08 0 0-.653-1.08-.248-1.35 0 0 .045-.99 1.17-.765 1.125.225 3.555.225 4.343-.18l-.09-.248c-.27.068-2.385.225-2.385.225s-.675-.315.517-.36c1.193-.045 1.463-1.012 1.463-1.012l.18-.72c-1.283-3.398-2.88-6.12-4.725-6.165-1.71 0-3.465 3.037-3.578 3.217h-4.567s4.545 8.01 4.545 15.975h11.677c-.022 0-.405-2.227-1.08-5.175z"
      ></path>
      <path
        fill="#FDC07D"
        d="M220.68 110.003l.247.697c-.09-.225-.157-.45-.247-.697z"
      ></path>
      <path
        fill="#E65B26"
        d="M215.932 103.815h-12.6s-2.047.135-3.33 3.24h12.353c.112-.202 1.867-3.217 3.577-3.24zM221.22 111.578c-.023-.225-.158-.765-.293-.878l-.247-.697v-.023l-.18.72s-.293.968-1.463 1.013c-1.17.045-.517.36-.517.36s2.115-.158 2.385-.225l.09.247c-.788.405-3.218.405-4.343.18-1.125-.225-1.17.765-1.17.765-.405.27.248 1.35.248 1.35-.248.743 1.057 1.08 1.057 1.08-.202.428.203.72.765 1.215.585.473 2.903 1.26 4.95 1.193.158 0 .293-.023.45-.045-.45-1.913-1.035-4.14-1.732-6.255z"
      ></path>
      <path
        fill="#EEC2A0"
        d="M223.695 116.032c1.08-.855.608-2.16.923-3.622.202-.855-.473-1.193-1.035-1.305a1.39 1.39 0 01-.338-.045c-.54-.09-1.597-.023-2.182.045l.09.27c-.788.405-3.218.405-4.343.18-1.125-.225-1.17.765-1.17.765-.405.27.248 1.35.248 1.35-.248.742 1.057 1.08 1.057 1.08-.202.427.203.72.765 1.215.315.27 1.193.63 2.25.877.54-.112 1.688-.36 2.543-.45.427-.045.81-.18 1.192-.36z"
      ></path>
      <path
        fill="#C3865F"
        d="M226.575 114.863c-.292-.743-.495-1.71-.607-2.52-.068-.608-.158-1.17-.158-1.193-.022-.315-.045-.517-.045-.517l-.067-.068c-.945-.652-5.018-.607-5.018-.607l.405 1.125c.585-.045 1.643-.113 2.183-.045.09.022.225.022.337.045.563.135 1.238.45 1.035 1.305-.337 1.44.158 2.767-.922 3.622-.383.18-.765.315-1.17.36-.855.113-2.003.338-2.543.45.833.203 1.8.338 2.7.293 2.07-.068 4.14-1.778 4.14-1.778-.112-.112-.202-.292-.27-.472zM215.64 112.343s1.463.45 3.128.427l-3.128-.427z"
      ></path>
      <path
        fill="#C3865F"
        d="M216.945 114.772s1.328.405 2.385.563c1.058.157 1.508.045 1.508.045l-3.893-.608zM215.888 113.693s2.362.495 3.51.562c0 0-3.308-.63-3.51-.562z"
      ></path>
      <path
        fill="#F8DAC5"
        d="M216.945 111.938s3.622.427 4.995 0c1.372-.428-.225.54-1.328.517-1.125-.022-4.657-.292-3.667-.517z"
      ></path>
      <path
        fill="#101010"
        d="M208.62 61.043s-1.17 1.395-.27 2.947c.9 1.553 5.378 8.506 3.285 13.455-1.35 3.196-2.767 3.353-2.767 3.353s2.407.293 3.825-.698c0 0 0 .383-.405.743 0 0 1.755-.023 2.385-.765l3.51-.653-5.085-17.122-4.478-1.26z"
      ></path>
      <path
        fill="#EEC2A0"
        d="M229.658 66.263c.18-2.498-1.71-1.755-2.453-1.35-.45-1.125-1.08-2.093-1.957-2.7-.81-.585-1.98-.9-3.263-1.058.9.968 1.935 2.07 1.935 2.07s-7.65 1.688-10.8.968c0 0-.607 1.012.27 1.732.9.72 1.913.923 1.98 1.44.09.495 0 5.535 0 5.535l1.485.608s-1.26 5.332.788 6.727c.18.068.36.135.54.158.157.022.292.045.45.067h.045c.45.045.9.068 1.35.045 2.385-.09 4.635-1.8 6.007-2.79.45-.315 1.08-.81 1.418-1.125-.675 1.103-1.868 3.308-2.993 3.938-1.462.81-3.015 1.777-3.78 2.25.338.45 1.958 2.34 4.725 1.372 3.128-1.102 3.87-2.88 3.87-2.88s-1.26-4.297-1.462-5.49l-.023-.09v-.022c-.18-1.395.27-6.818.27-6.818.563-.045 1.44-.405 1.598-2.587z"
      ></path>
      <path
        fill="#C3865F"
        d="M216.878 73.463l-1.485-.607s.09-5.04 0-5.535c-.09-.495-1.103-.72-1.98-1.44-.9-.72-.27-1.733-.27-1.733 3.127.72 10.8-.967 10.8-.967s-1.035-1.103-1.935-2.07c-4.613-.54-11.048 1.057-11.048 1.057s.855 5.85 1.688 8.482c.787 2.453 2.407 8.348 5.04 9.518-2.093-1.373-.81-6.705-.81-6.705zM227.475 76.568s-2.385 3.127-4.275 3.802c-.99.36-1.98.743-2.7 1.013.113.337.158.765.135 1.282 0 0 .023.023.068.09.765-.472 2.317-1.44 3.78-2.25 1.125-.63 2.295-1.8 2.992-3.937z"
      ></path>
      <path
        fill="#7F4320"
        d="M220.028 80.483c.157.202.337.495.472.9.72-.27 1.53-.495 2.565-.765 3.285-.855 4.41-4.05 4.41-4.05-.337.315-1.665 1.305-2.115 1.62-1.372.967-2.79 1.935-5.332 2.295z"
      ></path>
      <path
        fill="#92261E"
        d="M218.61 76.253c-.585.09-.697 1.058-.697 1.058.36-1.193 1.912-1.103 2.7-.99.382-.923.562-1.778.562-1.778-1.147.832-5.985.517-5.985.517s.293.72.81 1.508c.495-.608 2.61-.315 2.61-.315z"
      ></path>
      <path
        fill="#C6403C"
        d="M218.475 78.593c.99.067 1.71-1.17 2.16-2.273-.787-.112-2.34-.202-2.7.99 0 0 .113-.945.698-1.057 0 0-2.115-.293-2.588.315.563.922 1.44 1.957 2.43 2.025z"
      ></path>
      <path
        fill="#F8DAC5"
        d="M215.685 69.706s-.202 2.925 0 3.015c.203.09.338.112.338.112l-.338-3.127z"
      ></path>
      <path
        fill="#3A3939"
        d="M227.453 72.878c-.068.742-.135 1.192-.135 1.192.09-.382.112-.787.135-1.192z"
      ></path>
      <path
        fill="#767575"
        d="M223.448 61.988s3.577 5.895 3.87 7.605c0 0-1.598-7.245-3.87-7.605z"
      ></path>
      <path
        fill="#C3865F"
        d="M228.42 65.678s.63.338.383 1.35c0 0-.248-.832-.473-.967-.225-.135-.337-.495.09-.383z"
      ></path>
      <path
        fill="#3A3939"
        d="M219.42 68.04c0 .203-.18.293-.427.293-.225 0-.428-.09-.428-.292 0-.203.18-.36.428-.36.225 0 .427.157.427.36zM214.065 68.198c0 .202-.18.292-.427.292-.248 0-.428-.09-.428-.292 0-.203.18-.36.428-.36.247 0 .427.157.427.36zM218.003 65.993c-.023.022.18.427.315.427s1.89.203 2.07.315c.18.113-.045-.405-.045-.45 0-.045-2.025-.45-2.34-.292zM214.515 66.443s-.945.135-1.125.135c-.202.022-.945.293-.922.27.022-.022.09-.472.247-.54.158-.068 1.598-.405 1.688-.36.09.045.427.36.112.495z"
      ></path>
      <path
        fill="#3A3939"
        d="M228.893 65.093c.27.157.81.742.675 1.98.675 1.53 1.53 3.33 1.53 4.792 0 0 .292-5.76-.833-8.887-.36-.72-.607-1.193-.607-1.193.225.315.427.72.607 1.193.765 1.53 2.048 4.14 2.543 5.332l-1.778-5.265 3.78 6.188-2.61-8.708c-1.485-3.555-3.42-6.817-5.602-7.065-4.905-.562-12.128-1.507-14.58 1.35-2.453 2.858-6.255 5.76-3.353 6.21 0 0 4.725 4.725 15.278 2.498 0 0 3.6 5.265 3.532 9.337.18-1.665.428-4.815.225-7.807 0 0 .653-.248 1.125-.023 0 .023.023.023.068.068z"
      ></path>
      <path
        fill="#101010"
        d="M230.243 62.955c-.158-.472-.36-.877-.608-1.192.023 0 .27.495.608 1.192zM228.825 65.048c.023.022.045.022.067.044-.044-.044-.067-.044-.067-.044zM227.475 78.93s3.96-1.462 5.333-3.577l.495-1.53v1.102s2.812-3.15 1.912-4.927c0 0-1.17-4.95-3.037-9.45l2.61 8.707-3.78-6.187 1.777 5.265c-.495-1.215-1.777-3.803-2.542-5.333 1.125 3.128.832 8.888.832 8.888 0-1.463-.877-3.263-1.53-4.793-.09.473-.382 1.485-1.44 1.778l-.292 4.522c0-.045.607 3.78-.338 5.535zM223.898 63.54s-.923-1.282-1.845-1.282c-.923 0-7.065 1.417-11.138.292.023 0 4.41 2.88 12.983.99z"
      ></path>
      <path
        fill="#767575"
        d="M209.16 60.143s1.103-2.228 2.745-3.803c1.62-1.597 1.935-1.98 4.32-2.295 2.385-.337-7.065 6.098-7.065 6.098zM229.95 63.293s-1.102-3.398-2.475-4.095c-1.395-.72 2.475 4.095 2.475 4.095z"
      ></path>
      <path
        fill="#3A6492"
        d="M122.895 216.99h4.005l-13.185-49.117-4.612-1.778 13.792 50.895zM83.723 216.99h4.005l14.512-53.55-3.757-1.44-14.76 54.99zM223.83 210.195l1.823 6.795h4.005l-1.35-5.062-4.478-1.733zM186.503 216.99h4.005l4.815-17.752-3.69-1.418-5.13 19.17z"
      ></path>
      <path
        fill="#203762"
        d="M105.3 152.1l3.803 13.995 4.612 1.778-4.23-15.773H105.3zM101.115 152.1l-2.632 9.9 3.757 1.44 3.06-11.34h-4.185zM212.265 152.1h-4.185l15.75 58.095 4.478 1.733-16.043-59.828z"
      ></path>
      <path
        fill="#203762"
        d="M203.895 152.1l-12.262 45.72 3.69 1.418L208.08 152.1h-4.185z"
      ></path>
      <path
        fill="#141D45"
        d="M163.935 169.425l-14.693-.45.833-2.183-23.22.09a3.111 3.111 0 00-3.105 3.105v.113a2.684 2.684 0 002.678 2.677h47.452c-2.205-.022-4.68-.247-7.447-.675-1.328-.202-2.476-1.35-2.498-2.677z"
      ></path>
      <path
        fill="#141D45"
        d="M166.32 166.793c.315-.045.63-.068.945-.023 11.655 1.823 17.685.473 24.75-12.487 6.975-12.803 6.367-35.415 6.345-35.663-.09-1.395.36-3.015 1.642-3.127h-30.105l-19.822 51.3 16.177-.068.068.068z"
      ></path>
      <path
        fill="#141D45"
        d="M163.935 169.425c0-.135 0-.27.022-.405.18-1.147 1.215-2.025 2.385-2.227l-.067-.045-16.178.067-.832 2.183 14.67.427z"
      ></path>
      <path
        fill="#194788"
        d="M202.208 119.948c-.09-1.485-.068-4.275-1.935-4.455 0 0-1.26 12.802-1.665 19.462s-3.848 20.903-8.955 27.495c-5.108 6.593-14.468 7.29-14.468 7.29l-4.927-.157 4.432 3.172c3.623-.045 6.57-.697 9.27-1.957 4.815-2.273 8.753-6.57 12.803-13.973 7.717-14.107 5.535-35.82 5.445-36.877zM163.935 169.425c.023 1.305 1.17 2.475 2.52 2.678 2.79.427 5.243.652 7.448.675h.81l-4.433-3.173-6.345-.18z"
      ></path>
      <path
        fill="#194788"
        d="M198.36 118.643c.023.247.63 22.86-6.345 35.662-7.065 12.96-13.095 14.31-24.75 12.488a2.681 2.681 0 00-.945.022l3.96 2.835 4.928.158s9.337-.698 14.467-7.29c5.13-6.593 8.55-20.835 8.955-27.495.405-6.66 1.665-19.463 1.665-19.463h-.292c-1.26.045-1.733 1.688-1.643 3.083z"
      ></path>
      <path
        fill="#194788"
        d="M163.958 169.02c-.023.135-.023.27-.023.405l6.345.202-3.96-2.835c-1.147.203-2.182 1.08-2.362 2.228z"
      ></path>
      <path
        fill="#141D45"
        d="M162.068 172.8v7.673l8.505-7.673h-8.505zM191.677 216.945l.045.068h.338l-.383-.068z"
      ></path>
      <path
        fill="#141D45"
        d="M165.668 185.985c.18-1.957 1.912-3.735 2.767-4.5v-1.012h-4.207v32.242l-23.625 4.298h51.12l-26.033-2.993c-.022 0-.247-25.425-.022-28.035z"
      ></path>
      <path
        fill="#324277"
        d="M168.435 212.715v-31.23c-.855.765-2.587 2.543-2.767 4.5-.225 2.61 0 28.035 0 28.035l26.032 2.993-.045-.068-23.22-4.23zM170.573 180.473V172.8l-8.505 7.673H170.573z"
      ></path>
      <path
        fill="#C64E28"
        d="M157.86 109.192s-.27.158-.765.428c.113-.068.383-.18.765-.338v-.09zM156.105 121.635l.765-5.49c0-3.713.158-6.48.158-6.48-.54.315-1.328.765-2.318 1.327-.517.293-1.755 2.228-3.352 4.928-1.373 6.39-4.163 19.642-4.388 22.342-.315 3.6-3.285 5.355-7.47 4.523-3.285-.653-13.815.045-18.18.382l.518 5.67 24.592-.855s1.778-2.722 4.005-6.165l1.755-19.71c.023 0 1.553-.202 3.915-.472z"
      ></path>
      <path
        fill="#E65B26"
        d="M171.63 157.275c2.408.112 6.255-3.96 6.368-6.683.045-.877.742-2.925 1.665-5.377-2.52.855-4.208 1.305-4.208 1.305l-2.925-4.883 11.61-7.605c.428-1.102.698-1.935.743-2.317.27-2.003-3.645-10.17-3.645-10.17.652 1.597 3.172 8.977-3.758 15.187-6.885 6.188-19.665-.202-22.59-1.777-1.44 2.227-3.037 4.68-4.432 6.84l-1.058 11.722c4.86-.697 14.423 1.305 19.485 3.803.968-.045 1.958-.09 2.745-.045z"
      ></path>
      <path
        fill="#E65B26"
        d="M154.283 134.64l1.822-13.005c-2.362.27-3.892.472-3.892.472l-1.755 19.71c1.417-2.16 2.992-4.612 4.432-6.84l-.607-.337z"
      ></path>
      <path
        fill="#F4D8BD"
        d="M181.238 121.567c-.09-.202-.135-.315-.135-.315.045.09.09.203.135.315z"
      ></path>
      <path
        fill="#FBB25A"
        d="M156.105 121.635l-1.822 13.005s.225.135.607.337c1.193-1.822 2.273-3.487 3.083-4.725-.878-4.14-1.08-9.765-1.08-14.107l-.788 5.49zM157.905 109.282l-.045-.09-.022.112c.022 0 .045-.022.067-.022zM139.523 142.785c4.185.832 7.155-.923 7.47-4.523.225-2.7 3.015-15.952 4.387-22.342-4.612 7.875-12.037 22.342-12.037 22.342-1.598-.697-18.158 3.15-18.158 3.15l.158 1.755c4.365-.337 14.895-1.035 18.18-.382zm-9.9-1.89s10.732-3.105 13.32-1.44c2.61 1.665-13.32 1.44-13.32 1.44z"
      ></path>
      <path
        fill="#C64E28"
        d="M184.905 131.737c-.045.383-.337 1.215-.742 2.318l5.4-3.533s-6.345-4.657-8.303-8.932c-.022-.023 3.915 8.145 3.645 10.147zM177.998 150.592c-.113 2.723-3.96 6.795-6.368 6.683-.787-.045-1.777 0-2.745.09.248.112.473.247.698.36 4.815 2.677 15.93 4.747 15.93 4.747l2.7-20.677a86.464 86.464 0 01-8.55 3.42c-.923 2.452-1.62 4.5-1.665 5.377z"
      ></path>
      <path
        fill="#FBB25A"
        d="M189.878 110.002c-.338-.517-1.08-1.125-2.025-1.732-1.26-.63-2.768-1.35-3.915-1.778-1.44-.517-3.465-1.102-4.86-1.485-.18.383-.405.833-.743 1.283-1.462 2.34-3.87 3.24-7.155 3.037-3.285-.202-4.342-3.645-4.342-3.645a46.71 46.71 0 00-3.083.698c-2.587 1.215-5.917 2.835-5.917 2.835s.022.022.045.09c2.047-.833 7.132-2.543 8.55-.585 1.755 2.452-.113 2.52 5.107 3.105 5.22.585 7.943.225 6.278 2.137-1.665 1.913-8.325 5.558-6.908 10.733 1.418 5.175-4.027 11.992-9.652 11.137-1.62-.247-2.655-2.497-3.308-5.58-.81 1.238-1.89 2.903-3.082 4.725 2.925 1.598 15.705 7.965 22.59 1.778 6.93-6.21 4.41-13.613 3.757-15.188-.045-.112-.09-.202-.135-.315 0 0 .045.113.135.315 1.958 4.275 8.303 8.933 8.303 8.933l-5.4 3.532-11.61 7.605 2.925 4.883s1.665-.45 4.207-1.305c2.34-.788 5.4-1.958 8.55-3.42.585-.27 1.148-.54 1.733-.833.18-.09.337-.18.495-.27 6.682-3.667 8.167-10.305 8.167-10.305-.36-5.175-8.707-20.385-8.707-20.385zm-14.67 30.42l14.175-9.202s-10.913 9.225-14.175 9.202zm5.4-34.222c.922-.383 7.627 3.33 7.627 3.33-3.982.63-8.55-2.925-7.627-3.33zm8.932 22.815s-6.345-6.548-7.245-7.785c-.9-1.238-1.845-3.713.585-2.7 2.43 1.012 7.628 9.247 6.66 10.485z"
      ></path>
      <path
        fill="#E65B26"
        d="M183.96 106.492c1.148.428 2.655 1.148 3.915 1.778-2.812-1.823-7.447-3.735-8.437-4.118-.068.203-.158.518-.315.855 1.372.383 3.397.968 4.837 1.485zM166.59 105.075c-.112.067-.157.09-.157.09-.495.18-1.508.652-2.655 1.192.855-.225 2.002-.472 3.082-.697a4.561 4.561 0 01-.27-.585zM175.455 108.585c-3.33 1.35-6.75-.585-7.582-1.373-.405-.382-.765-1.012-1.013-1.53 0 0 1.058 3.465 4.343 3.645 3.285.203 5.692-.72 7.155-3.037-.608.855-1.53 1.755-2.903 2.295z"
      ></path>
      <path
        fill="#F4D8BD"
        d="M167.873 107.212c.832.81 4.252 2.723 7.582 1.373 1.373-.54 2.295-1.44 2.903-2.295.315-.45.562-.9.742-1.283.158-.36.27-.652.315-.855-.112-.045-.18-.067-.18-.067-.09-.27-1.957-.428-1.957-.428s-7.11.63-8.213.608c-.9-.023-2.047.585-2.475.832.068.158.158.383.27.585.248.518.608 1.148 1.013 1.53z"
      ></path>
      <path
        fill="#FDC07D"
        d="M182.88 118.552c-2.407-1.012-1.462 1.463-.585 2.7.9 1.238 7.245 7.785 7.245 7.785.968-1.26-4.23-9.495-6.66-10.485zM189.383 131.22l-14.175 9.202c3.262.023 14.175-9.202 14.175-9.202zM177.84 113.94c1.665-1.913-1.057-1.575-6.277-2.138-5.22-.585-3.353-.652-5.108-3.105-1.417-1.98-6.502-.247-8.55.585-.022 0-.045.023-.045.023-.382.157-.652.27-.765.337-.022.023-.045.023-.045.023s-.157 2.745-.157 6.48c0 4.32.202 9.967 1.08 14.107.652 3.083 1.687 5.333 3.307 5.58 5.625.833 11.07-5.985 9.653-11.137-1.44-5.198 5.242-8.82 6.907-10.755zM188.235 109.552s-6.705-3.713-7.627-3.33c-.923.382 3.645 3.937 7.627 3.33zM142.965 139.455c-2.587-1.665-13.32 1.44-13.32 1.44s15.908.225 13.32-1.44z"
      ></path>
      <path
        fill="#EEC2A0"
        d="M172.733 145.372c.697-.382.022-2.925-.225-3.735-.315 0-3.645.045-5.355.338-1.823.315-3.645 2.79-3.645 2.79s.315.067.81.18c2.362.27 7.785.787 8.415.427z"
      ></path>
      <path
        fill="#C3865F"
        d="M175.478 146.52c.18-1.35-2.925-4.883-2.925-4.883h-.023c.225.81.9 3.353.225 3.735-.63.36-6.052-.157-8.415-.405 2.228.495 8.37 1.868 9.113 2.025.9.18 2.025-.472 2.025-.472z"
      ></path>
      <path
        fill="#414C5C"
        d="M116.64 214.763c1.575-.99 2.903-2.363 3.42-2.948-.022-.112-.045-.225-.045-.337-.472-.113-1.417-.293-2.362-.248-1.215.09-4.59-1.102-5.423-3.24-.27.428-.585.878-.9 1.215-.832.923-4.23 1.238-6.165 1.395-1.935.158-7.515 2.228-8.235 3.015-.72.81-.337 2.498-.337 2.498s7.65-1.125 10.732-1.238c3.06-.067 7.29 1.193 9.315-.112zm-18.765-.675c-.45.81-1.08 1.687-1.125.967 0 0 .113-1.26 2.093-1.957 1.98-.698 3.622-1.62 5.22-1.71 1.597-.09-5.738 1.89-6.188 2.7zM113.355 206.82l-.337-.157s-.023.045-.068.157c.113-.022.248-.022.405 0z"
      ></path>
      <path
        fill="#616F7F"
        d="M104.04 211.41c-1.597.09-3.262 1.013-5.22 1.71-1.98.698-2.092 1.958-2.092 1.958.067.697.675-.18 1.125-.968.45-.787 7.807-2.812 6.187-2.7z"
      ></path>
      <path
        fill="#B3BCC4"
        d="M120.218 215.28s-3.105 1.103-7.515.81c-4.41-.292-16.133.045-16.133.045-.382.293.135.9 1.215.945 1.08.045 9.585.068 13.568.203 3.982.112 8.64-.293 8.64-.293.225-.652.292-1.057.315-1.305.022-.36-.09-.405-.09-.405z"
      ></path>
      <path
        fill="#1E2733"
        d="M117.653 211.23c.922-.068 1.89.135 2.362.247-.135-.855-.27-1.507-.27-1.507l-6.39-3.15c-.157-.023-.292-.023-.427-.023-.113.225-.383.698-.698 1.193.833 2.137 4.208 3.33 5.423 3.24zM120.06 211.815c-.517.562-1.845 1.957-3.42 2.947-2.047 1.283-6.255.023-9.337.135-3.083.113-10.733 1.238-10.733 1.238s11.723-.36 16.133-.045c4.41.315 7.515-.81 7.515-.81.18-.698.022-2.228-.158-3.465z"
      ></path>
      <path
        fill="#B3BCC4"
        d="M153.158 214.56s.292.45 0 1.215c-.293.765-12.105 1.508-15.503 1.373-3.397-.135-3.397-.698-3.127-1.575l18.63-1.013z"
      ></path>
      <path
        fill="#414C5C"
        d="M151.515 213.39c-2.677-.383-3.622-.99-6.255-1.688-1.125-.292-1.417-.967-1.71-1.732-1.417 1.237-4.41 1.44-6.21 2.497-1.192.698-2.25 1.643-2.632 2.43.18.248.382.518.63.675.585.383 8.167.315 9.697.09 1.373-.202 6.975-1.035 8.123-1.192.022-.248.045-.608.045-.99-.473.022-1.058 0-1.688-.09z"
      ></path>
      <path
        fill="#1E2733"
        d="M145.26 211.702c2.633.698 3.578 1.305 6.255 1.688.63.09 1.215.112 1.71.067.023-1.327-.067-3.195-.067-3.195s-7.448-3.06-8.505-1.17c-.158.27-.855.653-1.103.878.293.765.585 1.44 1.71 1.732zM135.338 215.572c-.248-.157-.473-.427-.63-.675-.315.608-.203 1.148.54 1.418 1.71.652 9.675 0 11.925-.495a550.1 550.1 0 015.985-1.26c0-.023 0-.068.022-.09-1.17.18-6.75 1.012-8.122 1.192-1.553.225-9.135.293-9.72-.09z"
      ></path>
      <path
        fill="#698190"
        d="M122.085 209.723c2.498.495-3.442.157-5.175-.428-1.732-.585-6.097-4.635-4.207-8.235l-2.768 2.835.99 2.678s2.25 1.957 3.758 3.105c1.485 1.147 8.392 1.215 8.032.27-.337-.968.203-4.635.203-4.635l-5.67-.225c.022.022 2.362 4.14 4.837 4.635zm-10.44-2.903c-.9-.63-1.237-2.7-1.237-2.7l.967-1.282 2.408 5.715c.022-.023-1.238-1.103-2.138-1.733z"
      ></path>
      <path
        fill="#698190"
        d="M115.853 198.022c.067-.225 6.322-4.567 9.99-11.227 3.667-6.66 7.357-13.433 7.47-16.313.09-2.205-1.958-9.585 2.16-12.69-5.175 2.408-6.323 7.088-7.38 9.473-1.373 3.105-10.823 25.897-11.655 27.72-.833 1.822-3.713 6.097-3.713 6.097l2.07 2.003c-.022-.023.968-4.838 1.058-5.063zm14.175-33.052s-7.92 23.445-11.835 27.112l11.835-27.112zM149.963 207.653c0-.518-4.59-2.7-4.59-2.7h4.365s1.102-.968 1.102-5.333-.922-8.392-1.035-11.497c-.112-3.105 2.498-17.055 2.16-17.798-.337-.72-3.33-6.502-4.747-8.032-1.418-1.53 1.215-1.485 3.982-1.485 2.768 0 13.523.225 15.773-.45a2.713 2.713 0 001.17-.698c-.518-.27-.99-.54-1.395-.81-4.793-3.24-17.123-5.4-17.348-5.445-3.802.54-8.257 2.993-8.257 2.993s-1.733 2.835-1.733 7.672c0 4.815.563 11.768.563 11.768s2.812 18.202 3.217 20.677c.405 2.475 1.148 7.583 1.148 7.583l-.405 4.185c3.622 1.26 6.03-.113 6.03-.63zm-8.82-31.388s5.67 22.523 5.917 24.12c.225 1.598-.832 4.14-1.755 2.385-.922-1.777-4.162-26.505-4.162-26.505zm3.735 29.025s2.61 1.35 3.24 1.89c.63.54-3.555-.945-3.555-.945s-.743-2.205.315-.945z"
      ></path>
      <path
        fill="#566D78"
        d="M166.973 160.335c-2.25.698-13.028.45-15.773.45-2.767 0-5.4-.045-3.982 1.485 1.417 1.53 4.41 7.313 4.747 8.033.338.72-2.272 14.692-2.16 17.797.113 3.105 1.035 7.133 1.035 11.498 0 4.365-1.102 5.332-1.102 5.332h-4.365s4.59 2.183 4.59 2.7c0 .518-2.408 1.89-6.03.63l1.485 1.373s.697.337 2.992 1.372c2.295 1.035 5.58-.337 5.58-.337l1.913-43.853s7.897-.067 18.337.27c5.715.18 8.55-1.215 9.945-2.52-3.6-.787-11.677-2.722-16.065-4.95-.27.315-.63.563-1.147.72zM135.473 157.77c1.507-.698 3.375-1.215 5.67-1.395-2.61-.09-4.433.45-5.67 1.395z"
      ></path>
      <path
        fill="#566D78"
        d="M141.143 156.375c-2.318.203-4.163.698-5.67 1.395-4.118 3.128-2.07 10.507-2.16 12.69-.113 2.88-3.803 9.652-7.47 16.312-3.668 6.66-9.923 11.003-9.99 11.228-.068.225-1.08 5.062-1.08 5.062l-2.07-2.002c-1.868 3.6 2.497 7.65 4.207 8.235 1.733.585 7.65.922 5.175.427-2.475-.495-4.815-4.634-4.815-4.634l5.67.225 2.25-.878c-1.035-4.208 14.783-28.62 14.783-28.62s-.563-6.953-.563-11.768c.023-4.837 1.733-7.672 1.733-7.672z"
      ></path>
      <path
        fill="#83A6B6"
        d="M147.06 200.385c-.225-1.597-5.917-24.12-5.917-24.12s3.217 24.75 4.14 26.505c.945 1.755 2.002-.765 1.777-2.385zM148.14 207.18c-.63-.54-3.24-1.89-3.24-1.89-1.057-1.26-.315.945-.315.945s4.185 1.508 3.555.945zM110.408 204.12s.337 2.07 1.237 2.7c.9.63 2.16 1.732 2.16 1.732l-2.407-5.715-.99 1.283zM118.193 192.083c3.915-3.668 11.835-27.113 11.835-27.113l-11.835 27.113z"
      ></path>
      <path
        fill="#698190"
        d="M168.143 159.615c.945-1.012.742-2.565.742-2.565a41.656 41.656 0 01-1.305-.585c-4.342-2.07-12.757-3.195-16.785-3.217-.45 0-.922.045-1.395.112.203.045 12.555 2.205 17.348 5.445.405.293.877.563 1.395.81z"
      ></path>
      <path
        fill="#000"
        d="M168.143 159.615c.945-1.012.742-2.565.742-2.565a41.656 41.656 0 01-1.305-.585c-4.342-2.07-12.757-3.195-16.785-3.217-.45 0-.922.045-1.395.112.203.045 12.555 2.205 17.348 5.445.405.293.877.563 1.395.81z"
        opacity="0.46"
      ></path>
      <path
        fill="#566D78"
        d="M185.513 162.473c-2.295-.428-11.7-3.33-16.628-5.4 0 0 .225 1.552-.742 2.565 4.387 2.227 12.465 4.162 16.065 4.95 1.147-1.103 1.305-2.115 1.305-2.115z"
      ></path>
      <path
        fill="#000"
        d="M185.513 162.473c-2.295-.428-11.7-3.33-16.628-5.4 0 0 .225 1.552-.742 2.565 4.387 2.227 12.465 4.162 16.065 4.95 1.147-1.103 1.305-2.115 1.305-2.115z"
        opacity="0.46"
      ></path>
      <path
        fill="#616F7F"
        d="M137.138 214.92s.81-1.508 3.015-2.52c2.182-1.035-3.015 2.52-3.015 2.52z"
      ></path>
      <path
        fill="#9B9A9A"
        d="M185.063 76.433c-1.08-.113-.068.27.225.72.315.45.18 5.332.18 5.332s.247-1.103.877-3.42c.63-2.318-1.282-2.632-1.282-2.632zM163.373 74.565c.495 1.215.99.405.99-.585.022-.968 3.285-3.398 3.285-3.398s-.968.428-2.903 1.508-1.372 2.475-1.372 2.475zM176.873 79.83l6.862-3.42c-.022 0-12.487 4.68-6.862 3.42z"
      ></path>
      <path
        fill="#3A3939"
        d="M163.8 79.808c-.18.652-.697 2.767-.247 4.207.54 1.71.652 3.218.652 3.218.045-1.778.203-3.33.383-4.5.09-.653.18-1.193.27-1.598.022-.157.045-.27.067-.382a2.938 2.938 0 01-.585-.383 2.528 2.528 0 01-.54-.562zM185.287 75.937c.855-3.465-2.362-2.587-3.51-4.365-1.17-1.777-7.29.18-8.527-2.182-1.238-2.363-5.018.405-8.528 2.002-1.485.698-1.957 1.733-1.98 2.813.698 1.89 3.083 3.802 3.24 4.702.023.18.09.36.203.518.585.99 2.295 1.62 5.355 1.215 3.555-.495 4.387.045 4.387.045.18.045.293.112.383.202.45.428-.023 1.26-.023 1.26l2.003 2.228.787.877c.54.54 0 5.94 0 5.94l1.71-4.14c.45-.652.945-.9 1.373-.832 0 0 .045 0 .09.022.382.09 1.867.45 2.025.99.202.63 1.395.135 1.395.135-1.125-.922-.338-3.847.9-7.155 1.237-3.307-1.283-4.275-1.283-4.275zm-20.902-1.957c-.023.967-.495 1.777-.99.585 0 0-.563-1.373 1.372-2.475 1.935-1.103 2.903-1.508 2.903-1.508s-3.285 2.43-3.285 3.398zm12.487 5.85c-5.602 1.26 6.863-3.42 6.863-3.42l-6.863 3.42zm9.473-.765c-.63 2.317-.878 3.42-.878 3.42s.135-4.883-.18-5.333c-.315-.45-1.305-.832-.225-.72 0 0 1.913.315 1.283 2.633z"
      ></path>
      <path
        fill="#EEC19F"
        d="M182.273 86.242c-.068-.022-.09-.022-.09-.022-.428-.068-.923.157-1.373.832l-1.71 4.14s.023-2.16-.067-3.892c-.045-.788-.338-1.868-.72-2.925l-2.003-2.228s.45-.855.023-1.26c-1.688-.315-6.795 1.868-9.36 2.205-.27.045-.54.068-.765.068.562.427 1.057.967 1.125 1.597.157 1.463.202 2.138.112 3.038l-.765 6.097 1.688.63-.855 2.565s-1.373 2.745.315 4.185c.225.158.427.293.63.405 1.732.99 3.082.855 5.377-.045 1.395-.54 2.835-2.722 4.005-4.725-.45 1.845-1.282 4.748-2.047 5.648-.945 1.102-4.883 1.395-6.795 2.79l.067.495c4.545 2.835 8.438-.833 8.438-.833-.473-2.925.09-5.805.697-7.875.023-.045.023-.09.045-.112.518-1.71 1.08-2.835 1.103-2.858.292-.495.495-.832.63-.945 0 0 .765-.157 1.597-.697.675-.428 1.373-1.103 1.733-2.115a2.97 2.97 0 00.157-.675c.27-1.845-.36-3.263-1.192-3.488z"
      ></path>
      <path
        fill="#C68660"
        d="M164.565 82.732a36.251 36.251 0 00-.382 4.5c-.023.63-.023 1.283 0 1.958.157 4.972 1.215 10.35 3.397 11.947l.203.135c-1.688-1.44-.315-4.185-.315-4.185l.855-2.565-1.688-.63.765-6.097c.113-.9.045-1.598-.112-3.038-.068-.63-.585-1.17-1.125-1.597-.81 0-1.305-.18-1.598-.428z"
      ></path>
      <path
        fill="#101010"
        d="M179.348 94.14c-.023.023-.585 1.148-1.103 2.858.698-1.913 2.925-3.353 2.925-3.353 0-.337.158-.742.405-1.147-.832.517-1.597.697-1.597.697-.135.113-.338.45-.63.945zM184.298 87.233c-.158-.54-1.643-.9-2.025-.99.855.225 1.485 1.642 1.215 3.487-.045.248-.09.473-.158.675.54-.54.968-.9.968-.9.405 0 .697-.427.697-.427-.27-.45-.045-1.17-.045-1.17.428.067.765-.54.765-.54s-1.237.495-1.417-.135zM171.54 80.64c-3.06.428-4.77-.225-5.355-1.215a2.063 2.063 0 01-.202-.517c-.158-.9-2.543-2.813-3.24-4.703-.045 1.463.675 2.993.63 3.848-.023.652.067 1.237.427 1.755.135.202.315.382.54.54.158.135.36.247.585.382.09.045.203.113.315.158.383.18.833.315 1.35.472 2.768.743 7.403-.72 8.955-.72.135 0 .27.023.383.045 0 0-.833-.517-4.388-.045z"
      ></path>
      <path
        fill="#7F4320"
        d="M177.818 96.907c-1.148 2.003-5.648 5.49-9.36 4.77l.292 1.958c.878-.09 2.7-.338 4.275-.923 2.16-.787 4.793-5.805 4.793-5.805z"
      ></path>
      <path
        fill="#6D1518"
        d="M166.298 96.502c.045.383.157.743.337 1.058.54-.293 2.048-.158 2.048-.158-.383.068-.495.383-.495.383.585-.495 1.642-.225 2.092-.09.473-.45.81-.9.81-.9-.652.045-4.792-.293-4.792-.293z"
      ></path>
      <path
        fill="#DE4847"
        d="M168.21 97.785s.113-.315.495-.382c0 0-1.507-.113-2.047.157.472.81 1.305 1.35 2.047 1.215.518-.09 1.125-.585 1.598-1.057-.473-.18-1.508-.428-2.093.067z"
      ></path>
      <path
        fill="#C68660"
        d="M173.025 102.712c-1.575.585-3.42.833-4.275.923l.248 1.71c1.912-1.395 5.85-1.688 6.795-2.79.765-.9 1.597-3.825 2.047-5.648-.022 0-2.655 5.018-4.815 5.805zM178.313 84.374c.382 1.058.675 2.138.72 2.925.09 1.755.067 3.893.067 3.893s.54-5.4 0-5.94l-.787-.877zM176.31 80.865a.769.769 0 00-.382-.203c-.113-.022-.225-.045-.383-.045-1.552 0-6.21 1.463-8.955.72a7.21 7.21 0 01-1.35-.472c-.112-.045-.225-.113-.315-.158-.022.113-.045.248-.067.383-.068.405-.18.945-.27 1.597.36.315 1.057.54 2.385.36 2.565-.315 7.65-2.497 9.337-2.182zM181.575 87.48c-.45-.046 0 .652 0 .652 1.868 1.057-.292 3.262-.292 3.262s1.035.27 1.35-1.957c.292-2.227-1.058-1.957-1.058-1.957z"
      ></path>
      <path
        fill="#F8DAC5"
        d="M180.27 92.587s2.07-.742 2.385-1.8c.338-1.057.675-1.732.63-2.385 0 0 .36 2.318-1.035 3.33-1.372 1.013-2.745 1.71-1.98.855z"
      ></path>
      <path
        fill="#3A3939"
        d="M171 87.727c-.022.248-.27.27-.54.27-.247-.022-.405-.09-.382-.337.022-.248.247-.428.495-.428.247.023.45.248.427.495zM166.253 87.525c-.023.247-.27.27-.54.27-.248-.023-.405-.09-.383-.338.023-.247.248-.427.495-.427.27.022.45.225.428.495zM169.515 85.23s0 .157.36.315c.36.157 2.498.698 2.498.698s-.18-.923-.473-.923c-.292.023-2.385-.09-2.385-.09zM166.523 85.387s-1.013.18-1.17.248c-.158.09-.585.202-.495.09.09-.09 0-.45.247-.563.248-.112 1.17-.18 1.26-.135.09.045.383.27.158.36z"
      ></path>
      <path
        fill="#F8DAC5"
        d="M166.815 93.78l.518-4.095.157 4.388-.675-.293zM177.66 96.683c-.09 0-1.462 1.957-2.947 2.655-1.485.697-2.07 1.462-2.16 1.17-.09-.293 1.687-2.295 2.565-2.7.855-.45 2.542-1.125 2.542-1.125z"
      ></path>
      <path
        fill="#203762"
        d="M229.928 148.86H198.63v3.285h31.298v-3.285z"
      ></path>
      <path
        fill="#82A2D5"
        d="M198.63 148.86H61.425v3.285H198.63v-3.285z"
      ></path>
      <path
        fill="#336BA8"
        d="M229.928 147.308H198.63v3.285h31.298v-3.285z"
      ></path>
      <path
        fill="#A8BDE2"
        d="M198.63 147.308H61.425v3.285H198.63v-3.285z"
      ></path>
      <path
        fill="#82A2D5"
        d="M100.553 216.99h3.982l-11.407-42.547-4.546-1.575 11.971 44.122zM61.38 216.99h4.005l12.915-47.7-3.78-1.305-13.14 49.005zM202.05 212.333l1.26 4.657h4.005l-.832-3.127-4.433-1.53zM164.16 216.99h4.005l4.073-15.03-3.713-1.282-4.365 16.312z"
      ></path>
      <path
        fill="#3C6C9F"
        d="M82.957 152.1l5.626 20.768 4.544 1.575-5.984-22.343h-4.186z"
      ></path>
      <path
        fill="#3C6C9F"
        d="M78.772 152.1l-4.252 15.885 3.78 1.305 4.657-17.19h-4.184zM189.923 152.1h-4.185l16.312 60.233 4.433 1.53-16.56-61.763zM181.553 152.1l-13.028 48.578 3.713 1.282 13.5-49.86h-4.185z"
      ></path>
      <path
        fill="#52538C"
        d="M88.29 142.853h8.775l.135-.338-9.27-.517.36.855z"
      ></path>
      <path
        fill="#6065A3"
        d="M90.27 147.375h4.905l1.89-4.522H88.29l1.98 4.522zM96.21 140.85h-8.932v1.125l.652.023 9.27.517.428.023.967-1.688H96.21z"
      ></path>
      <path
        fill="#C64E28"
        d="M90.09 138.42c3.893-1.192 4.613-2.7 6.953-5.062 2.362-2.363-.27-6.21.135-8.685.247-1.553-1.283-4.545-3.015-6.593a17.924 17.924 0 00-1.913-1.597c-3.982-2.745-4.567 8.73-4.567 8.73s-2.7-1.598-4.455 4.252c-.878 2.948.585 5.85 2.272 8.01 1.26.855 2.88 1.485 4.59.945z"
      ></path>
      <path
        fill="#AA3D24"
        d="M99.18 125.46c.022-.023.022-.045.022-.067-.067.089-.067.133-.022.067zM91.935 116.145a1.86 1.86 0 00-.63-.202c-.45-.068-.135 0 .63.202zM83.093 135.045c-.09-.202-.113-.315-.113-.315-.045.045 0 .158.113.315z"
      ></path>
      <path
        fill="#AA3D24"
        d="M88.83 140.851h7.38c.698-.9 5.423-7.178 5.423-12.96 0-5.378-2.07-2.993-2.43-2.475 0 .022 0 .045-.023.067-.045.068-.045.023.023-.067.09-.608-2.093-4.478-5.018-7.313 1.733 2.048 3.263 5.04 3.015 6.593-.405 2.452 2.205 6.322-.135 8.685-2.362 2.362-3.082 3.892-6.952 5.062-1.71.518-3.33-.09-4.59-.967a20.956 20.956 0 003.307 3.375z"
      ></path>
      <path
        fill="#FBB25A"
        d="M94.073 134.73c0-.022 0-.067-.023-.09-.135-3.645-.99-8.73-1.552-12.15-.293-1.732-.54-3.24-.495-3.442.022-.045.022-.09 0-.113-.045-.045-.09-.045-.135 0-.113.09-.068.54.45 3.6.562 3.375 1.395 8.415 1.552 12.015-3.78-1.35-6.952-5.85-7.087-6.3 0-.022 0-.045-.023-.067-.022-.045-.09-.068-.135-.023a.174.174 0 00-.045.113c.045.337 3.353 5.13 7.313 6.502.045 1.305 0 2.43-.203 3.173-.022.045.023.112.068.112h.022c.045 0 .09-.022.09-.067.18-.788.225-1.913.203-3.263-.023 0-.023 0 0 0z"
      ></path>
      <path
        fill="#FBB25A"
        d="M100.283 126.473c-.045-.023-.113-.023-.135.022-.023.045-2.498 3.713-6.21 5.355-.045.023-.068.09-.045.135.022.045.045.068.09.068h.045c3.757-1.665 6.277-5.378 6.3-5.423 0-.067 0-.135-.045-.157z"
      ></path>
      <path
        fill="#C64E28"
        d="M47.858 196.11c.022-.878.045-1.755.045-2.678 0-.945-.023-1.867-.045-2.767-1.643.495-3.218.922-3.713 1.44.675-.72 2.093-2.498 3.578-3.87-.585-7.92-2.453-13.703-4.658-13.703-1.372 0-2.61 2.25-3.487 5.828 1.305 1.17 2.542 2.565 3.352 2.947-1.215-.585-2.43-1.012-3.69-1.372-.067.382-.157.787-.225 1.215.99 2.767 2.745 7.515 4.41 10.755 1.328 2.655 3.105 2.7 4.433 2.205z"
      ></path>
      <path
        fill="#AA3D24"
        d="M38.295 196.605c1.868.945 3.69 2.07 4.523 2.295-1.485-.383-2.97-.653-4.455-.9.157 2.655.472 5.062.9 7.155.967.54 1.912 1.012 2.767 1.102-.855-.067-1.71-.157-2.587-.225.877 3.87 2.182 6.323 3.622 6.323 1.733 0 3.24-3.533 4.095-8.865-1.282.315-2.565.652-3.825 1.012.855-.247 2.408-1.485 4.05-2.52.225-1.8.405-3.78.473-5.85-1.328.495-3.105.45-4.455-2.205-1.643-3.24-3.42-7.987-4.41-10.755-.495 2.948-.788 6.48-.788 10.26.023 1.103.045 2.16.09 3.173z"
      ></path>
      <path
        fill="#FBB25A"
        d="M61.335 182.97c-1.147-.743-3.33.292-5.917 2.542.472 1.598.81 3.308 1.26 4.05-.698-1.102-1.508-2.092-2.385-3.037-2.16 2.092-4.523 4.905-6.75 8.19-.563.81-1.08 1.642-1.575 2.452 1.08 1.733 2.025 3.578 2.587 4.185-1.035-1.08-2.137-2.092-3.262-3.082-1.215 2.092-2.205 4.117-2.925 5.917.517.923 1.08 1.8 1.732 2.318-.675-.518-1.35-1.035-2.025-1.575-.18.495-.337.945-.472 1.395-.81 2.722-.81 4.702.225 5.355 1.44.922 4.522-.945 7.987-4.523-.63-.225-1.282-.472-1.912-.697-.608-.203-1.193-.405-1.8-.608.495.158 1.372.18 2.385.203.72.022 1.507.022 2.295.067a56.712 56.712 0 004.815-6.21c.472-.72.945-1.417 1.372-2.137a67.198 67.198 0 01-1.102-.338c-1.193-.36-2.228-.652-2.745-.562.877-.18 2.812-.698 4.657-.968.113-.022.225-.022.338-.045.067-.112.135-.247.202-.36 3.488-6.142 4.815-11.362 3.015-12.532z"
      ></path>
      <path
        fill="#F28431"
        d="M35.348 196.29a44.777 44.777 0 00-3.915.112 80.754 80.754 0 002.16 5.828 3.528 3.528 0 001.395-1.598c.45-1.057.54-2.34.36-4.342zM33.368 183.172c-1.058-6.525-1.53-10.552-1.733-12.825-1.125-1.53-2.16-2.295-2.97-2.07-1.327.338-1.822 3.218-1.552 7.56 1.62 1.035 3.262 2.295 4.14 2.543-1.35-.383-2.678-.563-3.983-.653.383 3.803 1.26 8.46 2.61 13.433a117.53 117.53 0 001.058 3.622c1.687.495 3.375 1.125 4.432 1.373-.247-2.633-.967-6.57-2.002-12.983z"
      ></path>
      <path
        fill="#C64E28"
        d="M35.348 196.177v.09c.225 0 .427 0 .652.023-.157 0-.382-.045-.652-.113z"
      ></path>
      <path
        fill="#D86E27"
        d="M37.553 204.93c-.855.135-1.71.248-2.565.383 2.07 4.23 4.072 6.705 5.467 6.345 1.665-.428 2.025-4.883 1.193-11.183a106.585 106.585 0 00-3.398 2.115c.743-.495 1.868-2.317 3.128-3.915-.473-3.015-1.193-6.39-2.16-9.922a88.217 88.217 0 00-.923-3.15c-1.44.967-2.812 1.867-3.15 2.587.428-.99 1.26-3.397 2.25-5.355-1.867-5.535-3.96-10.012-5.805-12.487.225 2.25.698 6.3 1.733 12.825 1.035 6.412 1.732 10.35 1.98 13.005.27.067.495.112.675.112-.225 0-.45-.022-.653-.022.18 2.025.068 3.285-.382 4.342-.315.765-.833 1.26-1.395 1.598.315.742.63 1.462.945 2.137 1.147.405 2.205.72 3.06.585z"
      ></path>
      <path
        fill="#828AC4"
        d="M54.248 208.8l-.495 2.407-2.093 10.395H32.265l-2.093-10.395-.495-2.407h24.57z"
      ></path>
      <path
        fill="#52538C"
        d="M54.248 207.788v1.012l-.495 2.408h-23.58l-.495-2.408v-1.012h24.57z"
      ></path>
      <path
        fill="#828AC4"
        d="M55.733 205.627H27.428v4.163h28.305v-4.163z"
      ></path>
      <path
        fill="#3D3F70"
        d="M54.045 209.768h-8.752l-13.028 11.835H51.66l2.385-11.835z"
        opacity="0.68"
      ></path>
      <path
        fill="#4F81C2"
        d="M165.87 146.25c-.045.472-.247.878-.562 1.103l7.222.022-6.66-1.125z"
      ></path>
      <path
        fill="#203762"
        d="M163.508 146.115c0-.585.292-1.102.697-1.327H135s-.922 1.305 0 2.52l29.07.067c-.337-.27-.562-.742-.562-1.26z"
      ></path>
      <path
        fill="#A8BDE2"
        d="M165.87 146.25l6.66 1.125h.9v-1.057l-9.247-1.553c-.405.225-.698.743-.698 1.328 0 .517.225.99.563 1.237h1.26c.315-.202.54-.607.562-1.08zM135.225 145.215h20.835s-22.725 1.148-20.835 0z"
      ></path>
      <path
        fill="#203762"
        d="M159.097 138.038h-57.172a2.159 2.159 0 01-2.16-1.98l-3.15-35.438c-.158-1.755 1.237-3.285 2.992-3.285l56.115-.112c1.125 0 2.07.855 2.183 1.98l3.375 36.472c.09 1.26-.9 2.363-2.183 2.363z"
      ></path>
      <path
        fill="#4F81C2"
        d="M104.85 114.143c9.338 8.505 14.423 5.512 22.748 12.487 6.39 5.355 16.897 9.518 26.28 11.408h3.87a2.164 2.164 0 002.16-2.385l-3.375-36.473a2.197 2.197 0 00-2.183-1.98l-56.115.113a3.01 3.01 0 00-2.992 3.285l.112 1.237c1.53 3.105 4.365 7.628 9.495 12.308z"
      ></path>
      <path
        fill="#3C6C9F"
        d="M100.598 138.037H153.9c-9.382-1.89-19.89-6.075-26.28-11.407-8.347-6.975-13.41-3.983-22.747-12.488-5.153-4.68-7.965-9.202-9.45-12.307l3.037 34.222c.068 1.125 1.013 1.98 2.138 1.98z"
      ></path>
      <path
        fill="#BDD1ED"
        d="M126.157 106.943c0-.45.135-.878.36-1.238-.112-.022-.225-.022-.36-.022-1.485 0-2.677 1.395-2.677 3.105s1.192 3.105 2.677 3.105c1.305 0 2.408-1.103 2.633-2.565-.09 0-.158.022-.248.022a2.405 2.405 0 01-2.385-2.407z"
      ></path>
      <path
        fill="#4F81C2"
        d="M105.863 144c-1.44.495-2.678 1.44-3.533 2.183-1.215 1.035-.247 1.372-.247 1.372h5.985c1.552-1.867-.698-3.015-2.205-3.555z"
      ></path>
      <path
        fill="#203762"
        d="M107.775 143.663c-.675 0-1.305.135-1.912.338 1.507.54 3.757 1.687 2.205 3.555h3.555c4.432 0-1.575-3.893-3.848-3.893z"
      ></path>
      <path
        fill="#336BA8"
        d="M143.1 146.16h-16.875l6.098-30.757h-9.653l-10.687 32.175h11.677c.203-.833.63-2.228.968-1.62.495.832 8.73 1.305 10.552 1.102 1.823-.202 8.798.54 8.798.54v-.517a.864.864 0 00-.878-.923z"
      ></path>
      <path
        fill="#203762"
        d="M135.18 147.015c-1.665.203-8.707-.202-10.26-.9l6.098-30.735h-9.653l-10.687 32.175H144s-6.997-.742-8.82-.54z"
      ></path>
      <path
        fill="#336BA8"
        d="M121.365 116.708l-9.382 29.813h2.655l6.727-29.813z"
      ></path>
      <path
        fill="#203762"
        d="M187.47 122.445l-6.12 24.93h1.215l6.12-24.93h-1.215z"
      ></path>
      <path
        fill="#497BBE"
        d="M207.63 122.445h-18.72c.473.9 1.215 2.903 1.373 6.84.225 5.85.45 8.955 2.925 10.688 1.732 1.192 5.85 4.5 8.19 6.39.382.315.72.585.99.81l6.435-24.728h-1.193z"
      ></path>
      <path
        fill="#3C6C9F"
        d="M201.15 147.375h1.215l.045-.202c-.27-.225-.607-.495-.99-.81l-.27 1.012z"
      ></path>
      <path
        fill="#3C6C9F"
        d="M193.23 139.973c-2.475-1.733-2.7-4.815-2.925-10.688-.157-3.937-.9-5.94-1.372-6.84h-.225l-6.143 24.93h18.585l.27-1.012c-2.34-1.89-6.457-5.175-8.19-6.39z"
      ></path>
      <path
        fill="#203762"
        d="M204.21 147.308l-6.435-16.853h-1.012l.585 1.688-3.78 15.232 10.642-.067z"
      ></path>
      <path
        fill="#5D8CC9"
        d="M195.705 141.188c.608 2.295 1.598 5.062 2.003 6.165l5.512-.045-6.435-16.853c-.022 0-1.845 7.785-1.08 10.733z"
      ></path>
      <path
        fill="#3A6492"
        d="M197.685 147.353c-.405-1.103-1.395-3.87-2.002-6.165-.765-2.948 1.08-10.733 1.08-10.733l-4.208 16.92 5.13-.022z"
      ></path>
    </svg>
  );
};

export default IconGettingStarted;
