import {
  Box,
  Typography,
  Autocomplete,
  FormHelperText,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TreffasTextField from "src/components/TreffasTextField";
import TreffasStyle from "src/utils/TreffasStyle";
import { useIntegrationResource } from "src/context/IntegrationsContext";
import { Users } from "src/context";
import { Processor } from "./Processor";
import { Square } from "react-feather";

const SampleComponent = () => {
  const { integrationResource } = useIntegrationResource();
  const integration = integrationResource.useName("mailchimp");

  const { t } = useTranslation();
  const { user } = useContext(Users);
  const processor = new Processor(user);
  const config = integration.getConfig();
  const enabled = integration.IsEnabled();
  const packageForm = config.data?.package_form ?? null;
  const listRd = packageForm?.listRd ?? false;
  const hasError = packageForm?.hasError ?? false;

  const [mailchimpConfig, setMailchimpConfig] = useState({
    list: packageForm?.list ?? "",
    inputedTag: "",
    selectedTag: packageForm?.tags ?? [],
    isBoxChecked: packageForm?.isBoxChecked ?? false,
    hasError: hasError,
    errorMessage: packageForm?.errorMessage ?? "",
  });

  const [lists, setLists] = useState(packageForm?.lists ?? []);
  const [isLoading, setIsLoading] = useState(!listRd);

  const updateMailchimpConfig = (updatedData) => {
    setMailchimpConfig((mailchimpConfig) => {
      return {
        ...mailchimpConfig,
        ...updatedData,
      };
    });
  };

  const updateData = (updatedData) => {
    integration.updateData({
      ...config.data,
      package_form: {
        ...packageForm,
        ...updatedData,
      },
    });
  };

  const fetchLists = async () => {
    const listsData = await processor.getLists();

    updateData({
      lists: listsData,
      listRd: true,
    });

    setLists(listsData);
    setIsLoading(false);
  };

  useEffect(() => {
    /** render get list if not store in currentdata  */
    if (!listRd) {
      fetchLists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                const error = {
                  hasError: e.target.checked ? true : false,
                  errorMessage: e.target.checked
                    ? "package.error.required.mailchimp_list"
                    : "",
                };

                updateData({
                  isBoxChecked: e.target.checked,
                  ...error,
                });

                updateMailchimpConfig({
                  isBoxChecked: e.target.checked,
                  ...error,
                });
              }}
              checked={mailchimpConfig.isBoxChecked}
              disabled={!enabled || isLoading}
              icon={
                isLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  <Square size={21} />
                )
              }
            />
          }
          label={
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textTransform: "capitalize",
                color: !enabled ? "#8c8c8c" : "#000",
              }}
            >
              {config?.name}
            </Typography>
          }
        />
      </FormGroup>

      {Boolean(mailchimpConfig?.hasError) && (
        <FormHelperText error>
          {t(mailchimpConfig?.errorMessage)}
        </FormHelperText>
      )}

      {mailchimpConfig.isBoxChecked && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("package.label.mailchimp.select_list")}{" "}
              </Typography>

              <TreffasTextField
                fullWidth
                select
                SelectProps={{
                  native: true,
                }}
                sx={{
                  ...TreffasStyle.timepicker.field,
                  ...TreffasStyle.formInputLarge("input"),
                }}
                value={mailchimpConfig.list}
                onChange={(e) => {
                  const error = {
                    hasError: e.target.value === "" ? true : false,
                    errorMessage:
                      e.target.value === ""
                        ? "package.error.required.mailchimp_list"
                        : "",
                  };

                  updateMailchimpConfig({
                    list: e.target.value,
                    ...error,
                  });

                  updateData({
                    list: e.target.value,
                    ...error,
                    isBoxChecked: mailchimpConfig.isBoxChecked,
                  });
                }}
              >
                <option value="">Select</option>
                {isLoading ? (
                  <option value="" disabled>
                    loading...
                  </option>
                ) : (
                  lists.map((data, key) => (
                    <option value={data.id} key={key}>
                      {data.name}
                    </option>
                  ))
                )}
              </TreffasTextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            {" "}
            <Box>
              <Box>
                <Typography sx={TreffasStyle.formLabel}>
                  {t("package.label.mailchimp.add_tags")}
                </Typography>

                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={mailchimpConfig.selectedTag}
                  inputValue={mailchimpConfig.inputedTag}
                  onChange={(event, newValue) => {
                    updateMailchimpConfig({
                      selectedTag: newValue,
                    });

                    updateData({
                      tags: newValue.length > 0 ? newValue : [],
                    });
                  }}
                  onInputChange={(event, newInputValue) => {
                    const options = newInputValue.split(",");

                    updateMailchimpConfig(
                      options.length > 1
                        ? {
                            selectedTag: mailchimpConfig.selectedTag
                              .concat(options)
                              .map((x) => x.trim())
                              .filter((x) => x),
                            inputedTag: "",
                          }
                        : { inputedTag: newInputValue }
                    );

                    // Call updateData to update config.data
                    updateData({
                      tags: mailchimpConfig.selectedTag.concat(
                        mailchimpConfig.inputedTag
                      ),
                    });
                  }}
                  renderInput={(params) => (
                    <TreffasTextField
                      {...params}
                      placeholder={t("package.placeholder.separate_tags")}
                      sx={{
                        ...TreffasStyle.timepicker.field,
                        ...TreffasStyle.formInputLarge("autocomplete"),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SampleComponent;
