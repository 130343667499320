import {
  Box,
  Typography,
  Autocomplete,
  FormHelperText,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TreffasTextField from "src/components/TreffasTextField";
import TreffasStyle from "src/utils/TreffasStyle";
import { useIntegrationResource } from "src/context/IntegrationsContext";
import { Users } from "src/context";
import { Processor } from "./Processor";
import { Square } from "react-feather";

const SampleComponent = () => {
  const { integrationResource } = useIntegrationResource();
  const integration = integrationResource.useName("active_campaign");

  const { t } = useTranslation();
  const { user, userIntegrations } = useContext(Users);
  const processor = new Processor(user);
  const config = integration.getConfig();
  const enabled = integration.IsEnabled();
  const packageForm = config.data?.package_form ?? null;
  const listRd = packageForm?.listRd ?? false;
  const hasError = packageForm?.hasError ?? false;

  const [activeCamapignConfig, setActiveCamapignConfig] = React.useState({
    type: "tags",
    list: packageForm?.list ?? "",
    inputedTag: "",
    selectedTag: packageForm?.tags ?? [],
    isBoxChecked: packageForm?.isBoxChecked ?? false,
    hasError: hasError,
    errorMessage: packageForm?.errorMessage ?? "",
  });

  const [lists, setLists] = useState(packageForm?.lists ?? []);
  const [isLoading, setIsLoading] = useState(!listRd);

  const updateActveCampaignConfig = (updatedData) => {
    setActiveCamapignConfig((activeCamapignConfig) => {
      return {
        ...activeCamapignConfig,
        ...updatedData,
      };
    });
  };

  const updateData = (updatedData) => {
    integration.updateData({
      ...config.data,
      package_form: {
        ...packageForm,
        ...updatedData,
      },
    });
  };

  const fetchLists = async () => {
    const listsData = await processor.getLists();

    updateData({
      lists: listsData,
      listRd: true,
    });

    setLists(listsData);
    setIsLoading(false);
  };

  useEffect(() => {
    /** render get list if not store in currentdata  */
    if (!listRd) {
      fetchLists();
    }
  }, []);

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                const error = {
                  hasError: e.target.checked ? true : false,
                  errorMessage: e.target.checked
                    ? "package.error.required.active_campaign"
                    : "",
                };

                updateData({
                  isBoxChecked: e.target.checked,
                  ...error,
                });

                updateActveCampaignConfig({
                  isBoxChecked: e.target.checked,
                  ...error,
                });
              }}
              checked={activeCamapignConfig.isBoxChecked}
              disabled={!enabled || isLoading}
              icon={
                isLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  <Square size={21} />
                )
              }
            />
          }
          label={
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textTransform: "capitalize",
                color: !enabled ? "#8c8c8c" : "#000",
              }}
            >
              {config?.name}
            </Typography>
          }
        />
      </FormGroup>

      {Boolean(activeCamapignConfig?.hasError) && (
        <FormHelperText error>
          {t(activeCamapignConfig?.errorMessage)}
        </FormHelperText>
      )}

      {activeCamapignConfig.isBoxChecked && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel} htmlFor="payment_price">
                {t("package.label.active_capaign.select_list")}
              </Typography>

              <TreffasTextField
                fullWidth
                value={activeCamapignConfig.list}
                select
                SelectProps={{
                  native: true,
                }}
                sx={{
                  ...TreffasStyle.timepicker.field,
                  ...TreffasStyle.formInputLarge("input"),
                }}
                onChange={(e) => {
                  const error = {
                    hasError:
                      e.target.value === "" &&
                      activeCamapignConfig.selectedTag.length === 0
                        ? true
                        : false,
                    errorMessage:
                      e.target.value === "" &&
                      activeCamapignConfig.selectedTag.length === 0
                        ? "package.error.required.active_campaign"
                        : "",
                  };

                  updateActveCampaignConfig({
                    list: e.target.value,
                    ...error,
                  });

                  updateData({
                    list: e.target.value,
                    ...error,
                    isBoxChecked: activeCamapignConfig.isBoxChecked,
                  });
                }}
              >
                <option value="">Select</option>
                {isLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : lists?.length > 0 ? (
                  lists.map((_list, _key) => (
                    <option value={_list.id} key={_key}>
                      {_list.name}
                    </option>
                  ))
                ) : (
                  <option value="">No list found.</option>
                )}
              </TreffasTextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("package.label.active_capaign.add_tags")}{" "}
              </Typography>

              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={activeCamapignConfig.selectedTag}
                inputValue={activeCamapignConfig.inputedTag}
                onChange={(event, newValue) => {
                  let error = {
                    hasError: true,
                    errorMessage: "package.error.required.active_campaign",
                  };

                  updateActveCampaignConfig({
                    selectedTag: newValue,
                    ...(newValue.length === 0 &&
                      activeCamapignConfig.list === "" && {
                        ...error,
                      }),
                  });

                  updateData({
                    tags: newValue.length > 0 ? newValue : [],
                    ...(newValue.length === 0 &&
                      activeCamapignConfig.list === "" && {
                        ...error,
                      }),
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  const options = newInputValue.split(",");

                  updateActveCampaignConfig(
                    options.length > 1
                      ? {
                          selectedTag: activeCamapignConfig.selectedTag
                            .concat(options)
                            .map((x) => x.trim())
                            .filter((x) => x),
                          inputedTag: "",
                        }
                      : {
                          inputedTag: newInputValue,
                        }
                  );

                  // Call updateData to update config.data
                  updateData({
                    tags: activeCamapignConfig.selectedTag.concat(
                      activeCamapignConfig.inputedTag
                    ),
                    ...(options.length > 1 && {
                      hasError: false,
                      errorMessage: "",
                    }),
                  });
                }}
                renderInput={(params) => (
                  <TreffasTextField
                    {...params}
                    placeholder={t("package.placeholder.separate_tags")}
                    sx={{
                      ...TreffasStyle.timepicker.field,
                      ...TreffasStyle.formInputLarge("autocomplete"),
                    }}
                  />
                )}
              />

              <Box>
                <FormHelperText
                  sx={{
                    color: "#A3ABBF",
                  }}
                >
                  {t("form.input.hint")}
                </FormHelperText>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SampleComponent;
