import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Constant from "src/utils/Constant";
import jwtDecode from "jwt-decode";
import http from "src/utils/http";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const GoogleRegistration = () => {
  const { showSnackbar } = useSnackbarContext();
  const [isProcess, setIsProcess] = useState(false);
  const { t } = useTranslation();

  const handleSignUp = (response) => {
    const googleData = jwtDecode(response.credential);
    setIsProcess(true);
    http
      .post("/api/oauth/registration/create-account-and-direct-login", {
        organization: googleData.name?.trim()?.toLowerCase(),
        name: googleData.name,
        email: googleData.email,
        firstname: googleData.given_name,
        lastname: googleData.family_name,
        timezone: "China Standard Time",
        timezone_utc: "Asia/Shanghai",
        currency: "USD",
        thru: "google",
      })
      .then(({ data }) => {
        if (data.message === "email-exist") {
          showSnackbar(
            t("registration.form.validation.email_not_available"),
            "error"
          );
        }

        if (data.message === "success") {
          localStorage.setItem(`accessToken`, data.access_token);
          localStorage.setItem(`__loginType`, "coach");
          window.location.href = `/app/v2/calendar`;
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: Constant.googleLoginClientId,
      callback: handleSignUp,
      context: "signup",
    });

    google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
      theme: "outline",
      size: "large",
      text: "signup_with",
      width: 179,
      shape: "rectangular",
      logo_alignment: "center",
    });

    google.accounts.id.prompt();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box id="signUpDiv" />

      <Backdrop
        open={isProcess}
        sx={{
          backgroundColor: "#333970",
          opacity: "0.8 !important",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" size={25} />
      </Backdrop>
    </>
  );
};

export default GoogleRegistration;
