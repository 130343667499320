import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Error2 from "src/assets/images/error_2.png";
import TreffasStyle from "src/utils/TreffasStyle";
import Page from "./Page";

const PageNotFound = ({ orientation = "row" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Page title={t("page.not.found.page")}>
      <Box
        sx={{
          height: "calc(95vh - 130px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", md: orientation },
          mt: { xs: "50px" },
          mx: { xs: "20px" },
        }}
      >
        <Box>
          <img
            src={Error2}
            alt="Treffas AB"
            style={{
              width: "100%",
              maxHeight: "443.77px",
            }}
          />
        </Box>

        <Box
          sx={{
            maxWidth: "481px",
            ml: { xs: "0px", md: "89px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "42px",
                lineHeight: "54px",
              }}
            >
              {" "}
              {t("page.not.found.label.whoops")} <br />{" "}
              {t("page.not.found.label.something_wrong")}{" "}
            </Typography>
          </Box>

          <Box sx={{ mt: "24px", mb: "40px" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "26px",
                color: "#676767",
              }}
            >
              {t("page.not.found.description")}
            </Typography>
          </Box>

          <Box
            sx={{
              mb: { xs: "30px" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "48px",
                borderRadius: "12px",
                fontWeight: 400,
                ...TreffasStyle.buttonHover,
              }}
              onClick={() => navigate("/")}
            >
              {t("page.not.found.button")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default PageNotFound;
