import { Box } from "@mui/material";
import React from "react";
import Logo from "src/components/Logo";
import SideBg from "../SideBg";
import TreffasRegistration from "./treffas";
import Page from "src/components/Page";

const Register = () => {
  return (
    <Page title="Registrations">
      {/* show on small screen as logo */}
      <Box
        sx={(themes) => ({
          height: "80px",
          alignItems: "center",
          [themes.breakpoints.up("md")]: {
            alignItems: "center",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
            pl: "99px",
            display: "none",
          },
          [themes.breakpoints.down("md")]: {
            justifyContent: "flex-start",
            pl: "24px !important",
            display: "flex",
          },
        })}
      >
        <Logo />
      </Box>
      {/* show on small screen as logo end */}

      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            pt: {
              md: "120px",
            },
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TreffasRegistration />
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            width: "1100px",
          }}
        >
          <SideBg />
        </Box>
      </Box>
    </Page>
  );
};

export default Register;
